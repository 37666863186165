

export type KeysOf<O extends object> = KeyMap<O>

export function keysOf<O extends object>(): KeysOf<O> {
    return new Proxy({}, {
        get: (_, key) => key
    }) as KeysOf<O>
}


export type KeyMap<O extends object, V = undefined> = {
    [k in keyof O]: V extends undefined ? k : V
}