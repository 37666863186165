import {RowData, Table as TableData} from '@tanstack/table-core'
import {For, Show} from 'solid-js'
import {flexRender} from '@tanstack/solid-table'
import s from './Table.module.css'
import g from '../../global-style/peachy-library.module.css'
import {isFunction} from 'lodash-es'

export function Table<T extends RowData>(props: {table: TableData<T>, rowClass?: string | ((rowItem:T) => string)}) {
    return (
            <table class={s.table}>
                <thead>
                    <For each={props.table.getHeaderGroups()}>
                        {headerGroup => (
                            <tr>
                                <For each={headerGroup.headers}>
                                    {header => (
                                        <th colSpan={header.colSpan}>
                                            <Show when={!header.isPlaceholder}>
                                                <div
                                                    class={
                                                        header.column.getCanSort()
                                                            ? g.clickable + ' select-none'
                                                            : undefined
                                                    }
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: ' 🔼',
                                                        desc: ' 🔽',
                                                    }[header.column.getIsSorted() as string] ?? ''}
                                                </div>
                                            </Show>
                                        </th>
                                    )}
                                </For>
                            </tr>
                        )}
                    </For>
                </thead>
                <tbody>
                    <For each={props.table.getRowModel().rows}>
                        {row => (
                            <tr class={isFunction(props.rowClass) ? props.rowClass(row.original) : props.rowClass}>
                                <For each={row.getVisibleCells()}>
                                    {cell => (
                                        <td>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )}
                                </For>
                            </tr>
                        )}
                    </For>
                </tbody>

                <tfoot>
                    <For each={props.table.getFooterGroups()}>
                        {footerGroup => (
                        <tr>
                            <For each={footerGroup.headers}>
                            {header => (
                                <th>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                                </th>
                            )}
                            </For>
                        </tr>
                        )}
                    </For>
                </tfoot>

            </table>
    )
}
