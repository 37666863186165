import {DomainMappings, RepoPlan,} from '@peachy/repo-domain'
import {DateInterval} from '@peachy/utility-kit-pure'

export class ExcessService {

    constructor() {
    }

    getExcessesThatWereActiveDuring(interval: DateInterval, repoPlan: RepoPlan) {
        // todo handle excess changes: DEV-313 i.e. take into account the interval
        return repoPlan.excess ? DomainMappings.fromRepo.toExcess(repoPlan.excess) : undefined
    }
}
