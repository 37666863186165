import {buildMachine, ON, StateEvents, StatePaths, TAGS} from '../../to-abstract/solid-state'

const userAdminMachineDef = {
    'Idle': {
        [ON]: {
            requestNewDashUserPassword: () => userAdminMachineDef.RequestingNewDashUserPassword,
            requestNewCustomerPassword: () => userAdminMachineDef.RequestingNewCustomerPassword,
        }
    },
    'RequestingNewDashUserPassword': {
        [TAGS]: 'busy',
        [ON]: {
            success: () => userAdminMachineDef.Idle,
            failure: () => userAdminMachineDef.Idle,
        },
    },
    'RequestingNewCustomerPassword': {
        [TAGS]: 'busy',
        [ON]: {
            success: () => userAdminMachineDef.Idle,
            failure: () => userAdminMachineDef.Idle,
        },
    },
} as const



export type UserAdminMachineDef = typeof userAdminMachineDef
export type UserAdminStates = StatePaths<UserAdminMachineDef>
export type UserAdminEvents = StateEvents<UserAdminMachineDef>


export function createUserAdminMachine() {
    return buildMachine(userAdminMachineDef)
}


