import {RepoPlan} from '@peachy/repo-domain'
import {PeachyFlashRepo, PeachyRepoRootNode} from '../types'
import {PeachyArrayRepository} from './abstract/PeachyArrayRepository'

import {isNumber} from 'lodash-es'

export class PlanRepository extends PeachyArrayRepository<RepoPlan>{

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.plans)
    }

    async findByLifeId(lifeId: string, tx?: PeachyRepoRootNode) {
        return this.find(it => it.lifeId === lifeId, tx)
    }

    async setDateCancelledOnAllBenefitsOwnedBy(lifeId: string, dateCancelledIso?: string, tx?: PeachyRepoRootNode) {
        const root = tx ? tx : await this.repo.getContentRoot()
        return root.Δ(async root => {
            const planIndex = await this.findIndex(it => it.lifeId === lifeId, root)
            if (isNumber(planIndex)) {
                const benefits = await root.plans[planIndex].benefits()
                for (const benefitIndex in benefits) {
                    await root.plans[planIndex].benefits[benefitIndex].endDate(dateCancelledIso)
                }
            }
        })
    }
}
