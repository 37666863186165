import s from './Svg.module.css'
import {classList} from '@peachy/utility-kit-pure'

type SvgProps = {
    size?: number
    svgName: string
}

export function Svg(props: SvgProps) {
    const defaultSize = 50
    const size = (props.size ?? defaultSize) + 'px'
    return <div style={{width: size, height: size}} class={classList(s.svg, s[props.svgName])}/>
}