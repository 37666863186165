import {FlashRepo, RemoteRepoAdapter, SyncContext} from '@peachy/flash-repo-pure'
import {Logger, mapGroupDistinctBy, Optional} from '@peachy/utility-kit-pure'
import {isArray, isObject} from 'lodash-es'
import {InProgressService} from './InProgressService'

export class RepoManagementService {

    constructor(protected readonly logger: Logger,
                protected readonly flashRepo: FlashRepo,
                protected readonly remoteSyncAdapter: RemoteRepoAdapter,
                protected readonly inProgressService: InProgressService) {
    }

    async syncRepoWithRemoteIgnoreErrors(verboseDebug = false): Promise<Optional<SyncContext>> {
        return this.syncRepoWithRemote(verboseDebug).catch(e => {
            // ignore errors
            return undefined
        })
    }

    async syncRepoWithRemote(debug = false) {

        this.logger.debug('syncing flash repo with remote')

        const preSyncRoot = await this.getRootObject()
        debug && this.verboseDebug(preSyncRoot, 'before sync')
        try {
            const syncState = await this.flashRepo.sync(this.remoteSyncAdapter)
            this.logSyncState(syncState)
            return syncState
        } catch (e) {
            this.logger.error(e, {name: 'sync-error'})
            throw e
        } finally {
            this.logger.debug("make sure sync didn't break the repo")
            const postSyncRoot = await this.getRootObject()
            debug && this.verboseDebug(postSyncRoot, 'after sync')
            if (!postSyncRoot) {
                // this should never really happen
                this.logger.error('sync broke the repo - rolling back')
                const rootSetter = await this.flashRepo.getContentRoot()
                await rootSetter(preSyncRoot)
            }
            await this.checkInProgressThings()
        }
    }

    public didNotSync(syncResponse: SyncContext) {
        return syncResponse.f_postSyncState.thisRootHash !== syncResponse.f_postSyncState.thatRootHash
    }

    /**
     * This shouldn't be necessary but there's a bug (yet to be identified exactly where/how) where items can remain "inProgress" even after submitting.
     * There's a suspicion that sync may be the cause hence this very specific, but temp check on in progress after sync.
     */
    private async checkInProgressThings() {
        try {
            this.logger.debug('start checking in progress stuff after sync')
            // this causes checks to be done...
            await this.inProgressService.getAllThingsInProgress()
            this.logger.debug('done checking in progress stuff after sync')
        } catch(e) {
            this.logger.error(e, {name: 'post-sync-in-progress-check'})
        }
    }

    private verboseDebug(root: object,  outputPrefix = '') {
        try {
            const topLevelProps = mapGroupDistinctBy(Object.entries(root ?? {}), ([prop, value]) => [
                prop,
                isArray(value) ? `array of size ${value.length}` : isObject(value) ? `object with keys ${Object.keys(value)}` : typeof value
            ])

            this.logger.debug('\n\n', outputPrefix, 'repo root state', {
                isDefined: !!root,
                topLevelProps
            }, '\n\n')
        } catch (e) {
            this.logger.debug(e)
            //don't let this stop us
        }
    }

    private async getRootObject() {
        const root = await this.flashRepo.getContentRoot()
        return root()
    }

    private logSyncState(syncState: SyncContext) {
        if (syncState) {
            try {
                const {b_syncRequest, c_syncResponse, ...restOfTheState} = syncState
                const {flashlets: _hide, ...sanitisedSyncRequest} = b_syncRequest
                const {flashlets: _alsoHide, ...sanitisedSyncResponse} = c_syncResponse
                this.logger.debug('done sync', {sanitisedSyncRequest, sanitisedSyncResponse, ...(restOfTheState ?? {})})
            } catch (e) {
                // ignore
            }
        }
    }
}
