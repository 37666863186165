import styles from './RepoStatus.module.css'
import {createToggleSignal} from '@peachy/client-kit'
import {useRepoContext} from '../../RepoController'
import {Spinner} from '../../../../../components/Spinner/Spinner'
import {classList} from '@peachy/utility-kit-pure'

export function RepoStatus() {

    const repoContext = useRepoContext()

    const [showSpinner, toggleShowSpinner] = createToggleSignal(false)

    const onSync = async () => {
        toggleShowSpinner()
        await repoContext.sync()
        toggleShowSpinner()
    }

    const iconClass = () => classList(styles.status, repoContext.isSynchronised() ? styles.inSync : styles.notInSync)

    return (
        <div class={styles.RepoStatus}>
            <span class={iconClass()}>Not in Sync</span>
            <button onClick={onSync}>Sync</button>
            <Spinner isOpen={showSpinner()}/>
        </div>
    )
}
