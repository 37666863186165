import {ChangeLog} from '@peachy/flash-repo-pure'
import {PeachyRepoRoot} from '@peachy/repo-domain'

export const changeLog: ChangeLog<PeachyRepoRoot> = [
    ['2023-04-17-add-claim-media-state', import('./2023-04-17-add-claim-media-state')],
    ['2023-06-30-remove-refresh-date', import('./2023-06-30-remove-refresh-date')],
    ['2023-07-05-set-plan-year-on-approved-claim-activities', import('./2023-07-05-set-plan-year-on-approved-claim-activities')],
    ['2023-08-21-claim-line-items', import('./2023-08-21-claim-line-items')],
    ['2024-02-14-add-policy-account-type', import('./2024-02-14-add-policy-account-type')],
    ['2024-03-04-add-subscription-start-date', import('./2024-03-04-add-subscription-start-date')],
]