import {from, Observable} from 'rxjs'
import {REPO_HASH_NULL, RepoHash} from '../../primatives/hash-primatives'
import {IBranchIo} from '../../key-interfaces/IBranchIo'
import {MultiMap} from 'mnemonist'
import {
    asDoc,
    assertNotNullish,
    Bookmark,
    BookmarkOrTimestamp,
    compareBookmarks,
    getTimelineBookmarkProvider,
    last,
    resolveBookmark,
    ZERO_BOOKMARK
} from '@peachy/utility-kit-pure'
import {
    BranchInfo,
    BranchName,
    createUnmergedState,
    InstallationId,
    MergeState,
} from '../../primatives/repo-primatives'
import equal from 'fast-deep-equal/es6'


type BookmarkedMergeState = {
    bookmark: Bookmark,
    mergeState: MergeState
}


export class BranchIoMemory implements IBranchIo {

    private branches = new MultiMap<BranchName, BranchInfo>()

    private branchStream: BranchInfo[] = []

    private mergeStateStreams = new Map<InstallationId, MultiMap<BranchName, BookmarkedMergeState>>()

    private branchBookmarkProvider = getTimelineBookmarkProvider()
    private mergeStateBookmarkProvider = getTimelineBookmarkProvider()

    async fetchBranches(): Promise<BranchInfo[]> {
        return Promise.all(
            [...this.branches.keys()].map(
                b => this.fetchBranch(b)
            )
        )
    }

    async fetchBranch(branch: BranchName): Promise<BranchInfo> {
        assertNotNullish(branch)
        if (!await this.branchExists(branch)) {
            throw `Branch ${branch} does not exist`
        } else {
            return last(this.branches.get(branch))
        }
    }

    async fetchBranchHeadAtTimestamp(branch: BranchName, timestamp: number): Promise<BranchInfo> {
        const branchHistory = this.branches.get(branch)
        const bookmark = `${timestamp + 1}-0`
        return branchHistory.reverse().find(
            b => compareBookmarks(b.bookmark, bookmark) <= 0
        )
    }

    async createBranch(branch: BranchName, commitMessage: string, fromBranch?: BranchName): Promise<BranchInfo> {
        assertNotNullish(branch)
        if (await this.branchExists(branch)) {
            throw `Branch ${branch} already exists`
        }

        let branchHead = REPO_HASH_NULL

        if (fromBranch) {
            if (!await this.branchExists(fromBranch)) {
                throw `Branch ${fromBranch} does not exist`
            }
            const fromBranchInfo = await this.fetchBranch(fromBranch)
            branchHead = fromBranchInfo.branchHead
        }

        const newBranchInfo: BranchInfo = {
            name: branch,
            branchHead,
// todo fix commit messages
            commitMessage: '',
            bookmark: this.branchBookmarkProvider()
        }
        this.branches.set(branch, newBranchInfo)
        this.branchStream.push(newBranchInfo)
        return newBranchInfo
    }

    async deleteBranch(branchName: BranchName): Promise<BranchInfo> {
        if (!await this.branchExists(branchName)) {
            throw `Branch ${branchName} does not exist`
        } else {
            const branch = this.fetchBranch(branchName)
            this.branches.delete(branchName)
            return branch
        }
    }

    async branchExists(branchName: BranchName): Promise<boolean> {
        return this.branches.has(branchName)
    }


    async commitBranch(
        branch: BranchName,
        branchHead: RepoHash,
        commitMessage: string,
        expectedHead?: RepoHash
    ): Promise<BranchInfo> {
        assertNotNullish(branch)
        assertNotNullish(branchHead)

        if (!await this.branchExists(branch)) {
            throw `Branch ${branch} does not exist`
        }

        const branchHistory = this.branches.get(branch)
        const currentBranchState = last(branchHistory)

        if (expectedHead) {
            if (!currentBranchState.branchHead.equals(expectedHead)) {
                throw `Cannot store branch ${branch}. Expected rootKey ${expectedHead}, but got ${currentBranchState.branchHead}`
            }
        }
        if (currentBranchState.branchHead.equals(branchHead)) {
            return currentBranchState
        }
        const newBranchInfo: BranchInfo = {
            name: branch,
            branchHead,
// todo fix commit messages
            commitMessage: '',
            bookmark: this.branchBookmarkProvider()
        }

        this.branches.set(branch, newBranchInfo)
        this.branchStream.push(newBranchInfo)

        return newBranchInfo
    }


    fetchBranchUpdatesSince(branch: BranchName, bookmark: Bookmark = ZERO_BOOKMARK): Observable<BranchInfo> {
        return from(
            this.branchStream.filter(branchInfo =>
                branchInfo.name === branch
                && compareBookmarks(branchInfo.bookmark, bookmark) >= 0
            )
        )
    }

    fetchBranchUpdateSlice(
        branch: BranchName,
        since: BookmarkOrTimestamp,
        until: BookmarkOrTimestamp
    ): Observable<BranchInfo> {

        const sinceBookmark = resolveBookmark(since, ZERO_BOOKMARK)
        const untilBookmark = resolveBookmark(since, null)

        return from(
            this.branchStream.filter(branchInfo =>
                branchInfo.name === branch
                && compareBookmarks(branchInfo.bookmark, sinceBookmark) >= 0
                && compareBookmarks(branchInfo.bookmark, untilBookmark) <= 0
            )
        )
    }


    async commitMergeState(remoteInstallationId: InstallationId, branchName: BranchName, mergeState: MergeState, expectedMergeState?: MergeState): Promise<MergeState> {
        assertNotNullish(mergeState)

        let branchMergeHistory = this.mergeStateStreams.get(remoteInstallationId)
        if (!branchMergeHistory) {
            branchMergeHistory = new MultiMap()
            this.mergeStateStreams.set(remoteInstallationId, branchMergeHistory)
        }

        const currentMergeState = last(branchMergeHistory.get(branchName))?.mergeState ?? createUnmergedState()

        if (expectedMergeState && !equal(expectedMergeState, currentMergeState)) {
            throw `Cannot store merge state ${asDoc(mergeState)}. Expected merge state ${asDoc(expectedMergeState)}, but got ${asDoc(currentMergeState)}`
        }
        if (!equal(mergeState, currentMergeState)) {
            branchMergeHistory.set(branchName, {
                bookmark: this.mergeStateBookmarkProvider(),
                mergeState
            })
        }
        return mergeState
    }

    async fetchMergeState(remoteInstallationId: InstallationId, branch: BranchName): Promise<MergeState> {
        assertNotNullish(remoteInstallationId, 'no remoteRepoId')
        assertNotNullish(branch, 'no branchName')
        return last(this.mergeStateStreams.get(remoteInstallationId)?.get(branch))?.mergeState ?? createUnmergedState()
    }


    fetchMergeStatesSince(remoteInstallationId: InstallationId, branch: BranchName, bookmark: Bookmark = ZERO_BOOKMARK): Observable<MergeState> {
        const mergeStates = this.mergeStateStreams.get(remoteInstallationId).get(branch)
        return from(
            mergeStates
                .filter(mergeState =>
                    compareBookmarks(mergeState.bookmark, bookmark) > 0
                )
                .map(bms => bms.mergeState)
        )
    }
}
