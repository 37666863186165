import {Component, For} from 'solid-js'
import {useAccountSubscription} from './SubscriptionProvider'

import styles from './CancelSubscription.module.css'
import {A, useParams} from '@solidjs/router'
import {ukStyleDate, values} from '@peachy/utility-kit-pure'

import {MrLife, MrPolicy, toClass} from '@peachy/core-domain-pure'
import {getSubscriptionUrl} from './getSubscriptionUrl'


export const Members: Component = () => {
    const accountSub = useAccountSubscription()

    const lives = () => getMemberId()
        ? values(values(accountSub.subscription.policies).find(p => p.lives[getMemberId()]).lives)
        : values(accountSub.subscription.policies).flatMap(p => values(p.lives))


    const getPolicy = (life: MrLife) => {
        return values(accountSub.subscription.policies).find(p => p.lives[life.id])
    }

    return (
        <table class={styles.table}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Member type</th>
                    <th>Date added</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <For each={lives()}>
                    {(life) => <Member life={life} policy={getPolicy(life)} />}
                </For>
            </tbody>
        </table>
    )
}


const Member: Component<{ life: MrLife, policy: MrPolicy }> = (props) => {
    const accountSub = useAccountSubscription()
    const accountId = accountSub.account.id
    const subId = accountSub.subscription.id
    const life = toClass(props.life, MrLife)

    return (
        <tr>
            <td><A href={getSubscriptionUrl(accountId, subId, life.id)}>{life.fullName()}</A></td>
            <td>{life.type}</td>
            <td>{ukStyleDate(new Date(props.policy.startDate))}</td>
            {/* TODO Cancel status (at) time logic */}
            <td>{props.policy.status}</td>
        </tr>
    )
}

export const getMemberId = () => useParams().memberId
