import {Address as PeachyAddress} from '@peachy/repo-domain'
import {For} from 'solid-js'

export function Address(props: {address: PeachyAddress}) {
    return (
        <ul>
            <For each={props.address.asList()}>{line =>
                <li>{line}</li>
            }</For>
        </ul>
    )
}