import {Modal, Spinner} from '@peachy/client-kit'
import {Component, createSignal} from 'solid-js'
import {CancelButtons} from './CancelButtons'
import {CancelMembers} from './CancelMembers'
import {CancelModalContactDetails} from './CancelModalContactDetails'
import {CancelReason} from './selector/CancelReason'
import styles from './Modal.module.css'
import {CancelModalProps} from './CancelModalProps'
import {useSubscriptionService} from '../../../../controllers/DashboardServiceController'
import {useAccountSubscription} from '../SubscriptionProvider'

export type CancelPolicyModalProps = CancelModalProps & { policyId: string }


export const CancelPolicyModal: Component<CancelPolicyModalProps> = (props) => {

    const [isCancelling, setIsCancelling] = createSignal(false)
    const [enabled, setEnabled] = createSignal(false)
    const [reason, setReason] = createSignal('')

    const subscriptionService = useSubscriptionService()
    const accountSubscription = useAccountSubscription()
    const account = accountSubscription.account
    const subscription = accountSubscription.subscription

    const onDismiss = () => {
        setEnabled(false)
        props.onDismiss()
    }

    const onSelectReason = (reason: string) => {
        setEnabled(true)
        setReason(reason)
    }

    const onCancelPolicy = async () => {
        setIsCancelling(true)
        try {
            await subscriptionService.cancelPolicy({
                accountId: account.id,
                subscriptionId: subscription.id,
                policyId: props.policyId,
                cancellationReason: reason(),
            })

        } catch (e) {
            console.error(e)
        } finally {
            setIsCancelling(false)
            onDismiss()
        }
    }

    return (<>
        <Modal isOpen={props.isOpen} class={styles.modal}>
            <section>
                {/* TODO text later depending on member type */}
                <p>Are you sure you want to cancel this policy?</p>
                <CancelReason onSelect={onSelectReason} selectedReason={reason}/>
                <CancelModalContactDetails/>
                <CancelMembers/>
                <CancelButtons enabled={enabled()} onDismiss={onDismiss} onCancel={onCancelPolicy}/>
            </section>
        </Modal>
        <Spinner isShown={isCancelling()}/>
    </>)
}
