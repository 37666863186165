import {createSignal} from 'solid-js'
import styles from './AddressLooker.module.css'
import {AddressLookup} from '@peachy/client-kit'
import {Address} from '@peachy/core-domain-pure'
import {asDoc} from '@peachy/utility-kit-pure'

const [selection, setSelection] = createSignal<Address>()

export function AddressLooker() {
    return (
        <div class={styles.Lookup}>
            <AddressLookup appearance={{
                list: styles.ListSelector,
                listItem: '',
                selectedItem: '',
            }} onSelectAddress={setSelection}
                           initialSearchTerm={selection()?.display}/>

            {
                selection() ?
                    <code><pre>{asDoc(selection())}</pre></code>

                 : null
            }

        </div>
    )
}
