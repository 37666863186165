import {RepoEnquiry} from '@peachy/repo-domain'
import {PeachyFlashRepo, PeachyRepoRootNode} from '../types'
import {PeachyMapRepository} from './abstract/PeachyMapRepository'

//should work out why ts doesn't like this probably something to do with how RepoEnquiry is defined as a blueprint...
// @ts-ignore
export class EnquiryRepository extends PeachyMapRepository<RepoEnquiry> {

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.enquiries)
    }

    async debug(context?: PeachyRepoRootNode) {
        const root = context ? context : await this.repo.getContentRoot()
        const allEnquiries = await root.enquiries()
        console.log('\n\n\n\n********************* DEBUG ENQUIRIES')
        console.log('COUNT: ', Object.keys(allEnquiries ?? {}).length)
        console.log(Object.values(allEnquiries ?? {}).map(it => {
            return `${it.id} ${it.definition}`
        }))
    }
}


