import {PeachyFlashRepo} from '../types'

export class BenefitsRepository {

    constructor(protected readonly repo: PeachyFlashRepo) { }

    async get(id: string) {
        const allBenefits = await this.list()
        return allBenefits.find(it => it.id === id)
    }

    async list() {
        const root = await this.repo.getContentRoot()
        return (await root.plans()).flatMap(it => it.benefits)
    }

}


