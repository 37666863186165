import {TextBox, TextBoxProps} from './TextBox'
import styles from './PasswordBox.module.css'
import {Icon, Icons} from '../Icon'
import {createSignal} from 'solid-js'

export function PasswordBox(props: Omit<TextBoxProps, 'type'>) {

    const [secret, setSecret] = createSignal(true)

    const icon = () => secret() ? Icons.eyeShut : Icons.eyeOpen

    const mode = () => secret() ? 'password' : 'text'

    return <TextBox
        value={props.value}
        onChange={props.onChange}
        onEnter={props.onEnter}
        placeholder={props.placeholder ?? 'password'}
        type={mode()}
        class={styles.PasswordBox}
    >
        <Icon icon={icon()} onClick={() => {
            setSecret(s => !s)
        }}/>

    </TextBox>
}