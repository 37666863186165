import {Marginable} from '../Margin/Margin'
import {ParentProps} from 'solid-js'

type FlexProps = {
    row?: boolean
    rowReverse?: boolean
    column?: boolean
    columnReverse?: boolean

    alignItemsNormal?: boolean
    alignItemsStretch?: boolean
    alignItemsCenter?: boolean
    alignItemsStart?: boolean
    alignItemsEnd?: boolean
    alignItemsFlexStart?: boolean
    alignItemsFlexEnd?: boolean

    justifyContentStart?: boolean
    justifyContentEnd?: boolean
    justifyContentCenter?: boolean
    justifyContentSpaceAround?: boolean
    justifyContentSpaceBetween?: boolean
    justifyContentSpaceEvenly?: boolean

    wrap?: boolean

}
export const Flex = Marginable((props: ParentProps<FlexProps>) => {
    const direction = props.row ? 'row' :
        props.rowReverse ? 'row-reverse' :
            props.column ? 'column' :
                props.columnReverse ? 'column-reverse' : undefined

    const align = props.alignItemsNormal ? 'normal' :
        props.alignItemsStretch ? 'stretch' :
            props.alignItemsCenter ? 'center' :
                props.alignItemsStart ? 'start' :
                    props.alignItemsEnd ? 'end' :
                        props.alignItemsFlexStart ? 'flex-start' :
                            props.alignItemsFlexEnd ? 'flex-end' : undefined

    const justify = props.justifyContentStart ? 'start' :
        props.justifyContentEnd ? 'end' :
            props.justifyContentCenter ? 'center' :
                props.justifyContentSpaceAround ? 'space-around' :
                    props.justifyContentSpaceBetween ? 'space-between' :
                        props.justifyContentSpaceEvenly ? 'space-evenly' : undefined

    const wrap = props.wrap ? 'wrap' : undefined

    const style = {
        display: 'flex',
        'flex-wrap': wrap,
        'flex-direction': direction,
        'align-items': align,
        'justify-content': justify
    }

    return <div style={style}>{props.children}</div>
})