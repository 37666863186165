import {isArray, isNullish, isObject, Strinky} from '@peachy/utility-kit-pure'
import {lcsMerge} from '../merge/lcsMerge'

import equal from 'fast-deep-equal'
import {FlashObject, HardHash, Hash} from './flash-repo-domain'
import {FlashStore} from './FlashStore'


export async function mergeObjects(
        left: FlashObject,
        right: FlashObject,
        center: FlashObject,
        flashStore: FlashStore,
    ): Promise<Hash> {


    const merged: Strinky<any> = {}
    const keysMerged = new Set<string>()

    for (const lk of Object.keys(left)) {

        const mergedValue = await mergeProp(
            left[lk],
            right?.[lk],
            center?.[lk],
            flashStore
        )
        if (isNullish(mergedValue)) {
            delete merged[lk]
        } else {
            merged[lk] = mergedValue
        }

        keysMerged.add(lk)
    }

    for (const rk of Object.keys(right)) {
        if (!keysMerged.has(rk)) {
            const mergedValue = await mergeProp(null, right[rk], center?.[rk], flashStore)
            if (isNullish(mergedValue)) {
                delete merged[rk]
            } else {
                merged[rk] = mergedValue
            }
        }
    }
    return flashStore.put(merged)
}

// has a left hand bias
async function mergeProp(
    left: unknown,
    right: unknown,
    center: unknown,
    flashStore: FlashStore
): Promise<unknown> {

    if (areEqual(left, right) || areEqual(right, center)) {

        return left

    } else if (areEqual(left, center)) {

        return right

    } else {

        if (left instanceof HardHash) {
            left = await flashStore.get(left.toString())
        }

        if (right instanceof HardHash) {
            right = await flashStore.get(right.toString())
        }

        if (center instanceof HardHash) {
            center = await flashStore.get(center.toString())
        }

        if (isObject(left) && isObject(right)) {

            return new HardHash(await mergeObjects(
                left as FlashObject,
                right as FlashObject,
                center as FlashObject,
                flashStore
            ))

        } else if (isArray(left) && isArray(right)) {

            const idLookup = new Map<string, any>()
            await mapAnyIds(left, idLookup, flashStore)
            await mapAnyIds(right, idLookup, flashStore)
            if (isArray(center)) {
                await mapAnyIds(center, idLookup, flashStore)
            }

            const rawMerged = lcsMerge(
                left,
                right,
                center as any[],
                (x) => {
                    if (x instanceof HardHash) {
                        const h = x.toString()
                        return idLookup.get(h) ?? h
                    } else {
                        return x
                    }
                },
                (l, r, c) => {
                    return mergeProp(l, r, c, flashStore)
                }
            )

            return Promise.all(rawMerged)

        } else {

            // todo merge strategy
            return left
        }
    }
}

function areEqual(a: unknown, b: unknown) {
    if (a instanceof HardHash) {
        return b instanceof HardHash && a.toString() === b.toString()
    } else if (isNullish(a) && isNullish(b)) {
        return true
    } else {
        return equal(a, b)
    }
}


async function mapAnyIds(a: any[], m: Map<string, any>, flashStore: FlashStore) {
    const flashPromises = a.map(e => e instanceof HardHash ? flashStore.get(e.toString()) : e)
    const flashes = await Promise.all(flashPromises)

    a.forEach((x, i) => {
        if (x instanceof HardHash) {
            const flash = flashes[i]
            if (!isNullish(flash?.id)) {
                m.set(x.toString(), flash.id)
            }
        }
    })
}
