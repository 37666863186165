import 'reflect-metadata'
import {addWeeks, areIntervalsOverlapping, isBefore, isEqual, max} from 'date-fns'
import {BenefitType, BenefitTypes} from './types'
import {Type} from 'class-transformer'
import {DateInterval} from '@peachy/utility-kit-pure'

export class Benefit {

    @Type(() => Date)
    readonly startDate: Date
    @Type(() => Date)
    readonly endDate: Date

    constructor(readonly id: string,
                readonly type: BenefitType,
                startDate: Date,
                readonly coverAmountInPence: number,
                endDate?: Date) {
        this.startDate = startDate
        this.endDate = endDate
    }

    static CLAIM_WINDOW_WEEKS = 16

    startedOnOrBefore(givenDate: Date) {
        return isBefore(this.startDate, givenDate) || isEqual(this.startDate, givenDate)
    }

    wasCancelledBefore(dateTime: Date) {
        return this.endDate && isBefore(this.endDate, dateTime)
    }

    get lastAcceptableClaimSubmissionDate() {
        return this.endDate ? addWeeks(this.endDate, Benefit.CLAIM_WINDOW_WEEKS) : undefined
    }

    /**
     * @param proposedSubmissionDate the date on which to check if CLAIM submissions would have been accepted for on the benefit
     * @returns false if the benefit had not started, or was cancelled 16 weeks or more prior to the given date, true otherwise.
     * Further explanation: You have a 16 week window after treatment/payment in which to submit a claim. It's possible that someone could have just had some treatment and then immediately cancel ther cover.  They still have 16 weeks to submit that claim
     */
    wasAcceptingClaimSubmissionsOn(proposedSubmissionDate: Date) {
        return this.wasAcceptingClaimSubmissionsAtAnytimeDuring({start: proposedSubmissionDate, end: proposedSubmissionDate})
    }

    /**
     * @param proposedSubmissionDate the date on which to check if COVER CHECK submissions would have been accepted for on the benefit
     * @returns false if the benefit had not started, or was cancelled prior to the given date, true otherwise.
     * Further explanation: There is no point in checking cover for a benefit that you have cancelled, any treatment you have would inheriently be after the cancellation date so would definitely be declined
    */
    wasAcceptingCoverCheckSubmissionsOn(proposedSubmissionDate: Date) {
        return this.wasAcceptingCoverCheckSubmissionsAtAnytimeDuring({start: proposedSubmissionDate, end: proposedSubmissionDate})
    }

    wasAcceptingClaimSubmissionsAtAnytimeDuring(proposedInterval: DateInterval) {
        const claimableInterval = {start: this.startDate, end: this.lastAcceptableClaimSubmissionDate ?? max([proposedInterval.end, this.startDate])}
        return areIntervalsOverlapping(proposedInterval, claimableInterval, {inclusive: true})
    }

    wasAcceptingCoverCheckSubmissionsAtAnytimeDuring(proposedInterval: DateInterval) {
        const coverCheckableInterval = {start: this.startDate, end: this.endDate ?? max([proposedInterval.end, this.startDate])}
        return areIntervalsOverlapping(proposedInterval, coverCheckableInterval, {inclusive: true})
    }

    get hasBeenCancelled() {
        return this.wasCancelledBefore(new Date())
    }

    get isMentalHealth() {
        return [BenefitTypes.MENTAL_HEALTH_IN_PATIENT, BenefitTypes.MENTAL_HEALTH_OUT_PATIENT].includes(this.type)
    }

    isType(givenType: BenefitType) {
        return this.type === givenType
    }

    withUsage(planYearId: string, coverUsedInPence: number) {
        return new BenefitWithUsage(this, planYearId, coverUsedInPence)
    }

}

export class BenefitWithUsage extends Benefit {
    constructor(benefit: Benefit,
                readonly planYearId: string,
                readonly coverUsedInPence: number) {

        //todo get rid of all constructors and use static factory methods instead
        super (
            benefit?.id,
            benefit?.type,
            benefit?.startDate,
            benefit?.coverAmountInPence,
            benefit?.endDate
        )
    }

    get percentageCoverUsed() {
        return Math.min(this.coverUsedInPence / this.coverAmountInPence * 100, 100)
    }

    get percentageCoverRemaining() {
        return 100 - this.percentageCoverUsed
    }

    get coverRemainingInPence() {
        return Math.max(this.coverAmountInPence - this.coverUsedInPence, 0)
    }

    coverRemainingIsGreaterThanOrEqualTo(valueInPence: number) {
        return this.coverRemainingInPence >= valueInPence
    }
}
