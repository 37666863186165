import {createContext, createResource, createSignal, ParentProps, Show, useContext} from 'solid-js'

import {BeanFactory, BeanName} from '@peachy/service'
import {Spinner} from '../components/Spinner/Spinner'
import {WebCustomerApplicationContext} from '../service/customer-context/WebCustomerApplicationContext'

type ApplicationContext = BeanFactory & {
    changeCustomerContextTo(customerId: string): void,
    customerId: string
}

const Context = createContext<ApplicationContext>()

type ApplicationContextProviderInitProps = ParentProps<{
    customerId: string
}>

async function syncedAppContextForCustomer(id: string) {
    const context = await WebCustomerApplicationContext.for(id)
    await context.getBean('repoManagementService').syncRepoWithRemote(true)
    return context
}

export function CustomerApplicationContextProvider(props: ApplicationContextProviderInitProps) {

    const [customerId, setCustomerId] = createSignal(props.customerId)
    const [webContext] = createResource(customerId, id => syncedAppContextForCustomer(id))

    const context: ApplicationContext = {
        getBean<N extends BeanName>(beanName: N) {
            return webContext()?.getBean(beanName)
        },
        getBeans(...beanNames: BeanName[]) {
            return webContext()?.getBeans(...beanNames)
        },
        changeCustomerContextTo: setCustomerId,
        customerId: customerId()
    }

    return (
        <Show when={!webContext.loading} fallback={<Spinner isOpen/>}>
            <Context.Provider value={context}>
                {props.children}
            </Context.Provider>
        </Show>
    )
}

export function useApplicationContext() {
    return useContext(Context)
}

export function useBean<N extends BeanName>(beanName: N) {
    return useApplicationContext().getBean(beanName)
}

export function useBeans<N extends BeanName>(...beanNames: BeanName[]) {
    return useApplicationContext().getBeans(...beanNames)
}

export function useCurrentCustomerId() {
    return useApplicationContext().customerId
}
