import {
    QuestionId,
    QuestionIds,
    QuestionInitialisationData,
    QuestionInitialiser,
    QuestionInitialiserAndConfigTuple
} from '../../types'
import {
    bookNowOptions,
    doneOption,
    doneOptions,
    genderPreferenceOptions,
    gotItOptions,
    newOrExistingInjuryOptions,
    QuestionAnswer,
    whoOptions,
    yesNoOptions,
    yesOption
} from '@peachy/repo-domain'
import {EnquiryReader} from '../../EnquiryReader'
import {phoneLinkMarkdown} from '@peachy/utility-kit-pure'
import {LifeService} from '../../../LifeService'
import {EnquiryQuestionDataModelProviders} from '../../EnquiryQuestionDataModelProviders'
import {EnquiryDefinitionCommon} from '../EnquiryDefinitionCommon'
import {
    buttonQuestion,
    requiredWhenMoreThanOneOption,
    singleAnswerAppointmentPickerQuestion,
    singleAnswerCheckboxQuestion,
    singleAnswerFreetextQuestion,
    singleAnswerNumberQuestion,
    when
} from '../EnquiryDefinitionUtil'

export class BookPhysioEnquiryDefinition extends EnquiryDefinitionCommon {

    readonly id = 'book-physio'
    readonly questions: QuestionInitialiserAndConfigTuple[]

    constructor(protected readonly lifeService: LifeService,
                protected readonly dataModelProviders: EnquiryQuestionDataModelProviders) {

        super()
        this.questions = this.buildQuestions()
    }

    buildQuestions(): QuestionInitialiserAndConfigTuple[] {
        const {
            whoIsTheAppOwner,
            whatPhysioClinicId,
            whoAreYouBookingPhysioFor,
            chaperonePhysioInfo,
            newOrExistingInjuryPhysio,
            alreadyHadInitialAssessmentPhysio,
            phoneTheProviderExitPhysio,
            whatDateAndTimePhysio,
            whereIsTheInjuryPhysio,
            anythingElseWeNeedToKnowPhysio,
            reviewAndSubmitPhysio,
        } = this

        return [
            // unasked.. i.e expected to be initiated before chat
            [whoIsTheAppOwner],
            [whatPhysioClinicId],

            [whoAreYouBookingPhysioFor, requiredWhenMoreThanOneOption],
            [chaperonePhysioInfo, {
                required: when({
                    rawPredicate: async enquiry => {
                        const whoForId = EnquiryReader.forPhysioBooking().extractWhoForIdFrom(enquiry)
                        const whoFor = await this.lifeService.getLife(whoForId)
                        return whoFor.ageInWholeYears < 16
                    }
                })
            }],
            // [this.whatPhysioGenderPreference],
            [newOrExistingInjuryPhysio],
            [alreadyHadInitialAssessmentPhysio],
            [phoneTheProviderExitPhysio, {
                required: when({
                    answersOf: [[this.alreadyHadInitialAssessmentPhysio, yesOption]]
                })
            }],
            [whatDateAndTimePhysio, requiredWhenNoEarlyExit],
            [whereIsTheInjuryPhysio, requiredWhenNoEarlyExit],
            [anythingElseWeNeedToKnowPhysio, requiredWhenNoEarlyExit],
            [reviewAndSubmitPhysio, requiredWhenNoEarlyExit]
        ]
    }

    get whatPhysioClinicId (): QuestionInitialiser {
        const question = () => singleAnswerNumberQuestion({
            id: question.id,
            text: `What Clinic are you booking for?`,
            tags: EnquiryDefinitionCommon.hideQuestionInChat
        })
        question.id = QuestionIds['what-physio-clinic-id']
        return question
    }


    get whatPhysioTherapistId (): QuestionInitialiser {
        const question = () => singleAnswerNumberQuestion({
            id: question.id,
            text: `What Therapist are you booking for?`,
            tags: EnquiryDefinitionCommon.hideQuestionInChat
        })
        question.id = QuestionIds['what-physio-therapist_id']
        return question
    }


    get whoAreYouBookingPhysioFor (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Who is the appointment for?`,
            embeddedOptions: whoOptions(policy.activeManagedLives)
        })
        question.id = QuestionIds['who-are-you-booking-physio-for']
        return question
    }


    get chaperonePhysioInfo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "Please note that as ${whoFor ? whoFor + ' is' : 'you are'} under 16 ${whoFor ? 'they' : 'you'} will need a chaperone to accompany ${whoFor ? 'them' : 'you'} to the appointment",
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['chaperone-physio-info']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get whatPhysioGenderPreference (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Would you like to book with a male or female physiotherapist?',
            embeddedOptions: genderPreferenceOptions
        })
        question.id = QuestionIds['what-physio-gender-preference']
        return question
    }


    get whatDateAndTimePhysio (): QuestionInitialiser {
        const question = () => singleAnswerAppointmentPickerQuestion({
            id: question.id,
            text: 'Please pick an appointment time',
            optionsId: 'physio-appointment-times'
        })
        question.id = QuestionIds['what-date-and-time-physio']
        return question
    }


    get newOrExistingInjuryPhysio (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: "What kind of injury do${whoFor ? 'es ' + whoFor : ' you'} have?",
            embeddedOptions: newOrExistingInjuryOptions
        })
        question.id = QuestionIds['new-or-existing-injury-physio']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get alreadyHadInitialAssessmentPhysio (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: "Have ${whoFor ? 'they' : 'you'} already had an initial assessment with Ascenti for the injury?",
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['already-had-initial-assessment-physio']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get phoneTheProviderExitPhysio (): QuestionInitialiser {
        const question = ({config}: QuestionInitialisationData) => buttonQuestion({
            id: question.id,
            text: `Please contact Ascenti directly on ${phoneLinkMarkdown(config.ascenti.bookingPhoneNumber)} to arrange a follow up appointment`,
            embeddedOptions: doneOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['phone-the-provider-physio']
        return question
    }


    get whereIsTheInjuryPhysio (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'Which body part is affected?',
        })
        question.id = QuestionIds['injury-location-physio']
        return question
    }


    get anythingElseWeNeedToKnowPhysio (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'Anything else we need to know?',
        })
        question.id = QuestionIds['anything-else-physio']
        return question
    }


    get reviewAndSubmitPhysio (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'All done! Please check the information that you have provided to make sure you are happy and then click Book now',
            embeddedOptions: bookNowOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['submit-physio-booking']
        return question
    }

}

const requiredWhenNoEarlyExit = {
    required: when({
        noAnswersOf: [[QuestionIds['phone-the-provider-physio'], doneOption]] as [QuestionId, QuestionAnswer][]
    })
}
