import {changeSet} from './peachyChangeSet'
import {has, unset} from 'lodash-es'

export const changeSets = [
    changeSet({id: '001-remove-plan-refresh-date', author: 'dan.parker'}, async tx => {

        try {
            const plans = await tx.plans()

            let mutatedAny = false
            for (const plan of plans) {
                if (has(plan, 'refreshDate')) {
                    unset(plan, 'refreshDate')
                    mutatedAny = true
                }
            }

            if (mutatedAny) {
                await tx.plans(plans)
            }
        } catch (e) {
            // meh! it'd be nice to clean up this old field but I'm not about to clog up any other migrations if it fails
        }
    })

]
