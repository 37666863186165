import {MemoRepo} from '../implementations/repo/MemoRepo'
import {RepoId} from '../primatives/repo-primatives'
import {Observable} from 'rxjs'

export interface IRepoService {
    repoExists(repoId: string): Promise<boolean>
    createRepo<RepoRoot>(
        repoId: string,
        pageCacheSize?: number
    ): Promise<MemoRepo<RepoRoot>>
    openRepo<RepoRoot>(
        repoId: string,
        pageCacheSize?: number
    ): Promise<MemoRepo<RepoRoot>>
    getRepoIds(): AsyncIterable<RepoId>
    fetchRepoIdStream(): Observable<RepoId>
    findReposContainingId(containedId: string): Promise<string[]>
}
