import {AscentiAppointment, AscentiAppointmentBookOut} from '@peachy/ascenti-client'
import {
    Address,
    Appointment,
    AppointmentOrInProgressBooking,
    EnquiryDefinitionId,
    InProgressAppointmentBooking,
    Question
} from '@peachy/repo-domain'

import {EnquiryReader} from './enquiry/EnquiryReader'
import {EnquiryDefinitionService} from './enquiry/EnquiryDefinitionService'
import {EnquiryService} from './enquiry/EnquiryService'
import {LifeService} from './LifeService'
import {AscentiService} from './AscentiService'
import {AppointmentProviderService} from './AppointmentsService'
import {Logger} from '@peachy/utility-kit-pure'
import {BookPhysioEnquiryDefinition} from './enquiry/definition/book-physio/BookPhysioEnquiryDefinition'

export class PhysioService implements AppointmentProviderService {

    bookingEnquiryDefinitionId: EnquiryDefinitionId = 'book-physio'
    protected readonly enquiryReader = EnquiryReader.forPhysioBooking()

    constructor(protected readonly logger: Logger,
                protected readonly ascentiService: AscentiService,
                protected readonly lifeService: LifeService,
                protected readonly enquiryService: EnquiryService,
                protected readonly enquiryDefinitionService: EnquiryDefinitionService) {}

    metadataKeys = {
        appointmentId: 'appointmentId',
        bookingCaseId: 'bookingCaseId',
        bookingClinicId: 'bookingClinicId',
        bookingCancellationToken: 'bookingCancellationToken',
        bookingCaseReference: 'bookingCaseReference'
    }

    async lookupNextAvailableAppointments(clinicId: number, genderPreference?: 'MALE'|'FEMALE', therapistId?: number) {
        const allAppointments = await this.ascentiService.searchAppointments(clinicId, therapistId)
        return allAppointments.filter(appointment => {
            const therapistGender = appointment.Therapist.Gender.toUpperCase()
            return !genderPreference || therapistGender === genderPreference
        })
    }

    async putAppointmentOnHold<T extends AppointmentOrInProgressBooking>(appointment: T, ascentiAppointment: AscentiAppointment): Promise<T> {
        const hold = await this.ascentiService.holdAppointment(ascentiAppointment)
        appointment.setInMetadata(this.metadataKeys.appointmentId, hold.AppointmentID)
        return appointment
    }

    async confirmBooking(bookingInProgress: InProgressAppointmentBooking) {
        const enquiry = await this.getEnquiryFrom(bookingInProgress)
        const physioProviderAppointment = this.enquiryReader.extractChosenAppointmentFrom(enquiry)!
        let heldAscentiAppointmentId = bookingInProgress.getInMetadata<number>(this.metadataKeys.appointmentId)
        heldAscentiAppointmentId = heldAscentiAppointmentId ?? (await this.putAppointmentOnHold(bookingInProgress, physioProviderAppointment)).getInMetadata<number>(this.metadataKeys.appointmentId)
        const booking = await this.makeBookingWithServiceProvider(heldAscentiAppointmentId, bookingInProgress)
        return this.buildAppointment(bookingInProgress, booking, physioProviderAppointment, heldAscentiAppointmentId)
    }

    async cancelBooking(appointment: Appointment) {
        const cancellation = await this.ascentiService.cancelBooking(
            appointment.getInMetadata<number>(this.metadataKeys.appointmentId)!,
            appointment.getInMetadata<string>(this.metadataKeys.bookingCancellationToken)!,
            appointment.getInMetadata<string>(this.metadataKeys.bookingCaseReference)!,
            appointment.getInMetadata<number>(this.metadataKeys.bookingCaseId)!
        )
        if (cancellation.Success) {
            appointment.clearMetadata(
                this.metadataKeys.appointmentId,
                this.metadataKeys.bookingCaseId,
                this.metadataKeys.bookingClinicId,
                this.metadataKeys.bookingCancellationToken,
                this.metadataKeys.bookingCaseReference,
            )
        } else {
            this.logger.error(cancellation.Message)
            throw cancellation.Message
        }
    }

    async cancelBookingAndRebook(appointment: Appointment, rebookingDetails: Question) {
        await this.cancelBooking(appointment)
        const physioProviderAppointment = this.enquiryReader.extractChosenAppointmentFromQuestion(rebookingDetails)!
        const heldAscentiAppointmentId = (await this.putAppointmentOnHold(appointment, physioProviderAppointment)).getInMetadata<number>(this.metadataKeys.appointmentId)
        const newBooking = await this.makeBookingWithServiceProvider(heldAscentiAppointmentId, appointment)
        return this.buildAppointment(appointment, newBooking, physioProviderAppointment, heldAscentiAppointmentId)
    }

    getChooseAppointmentDateQuestion() {
        return this.enquiryDefinitionService.getDefinition<BookPhysioEnquiryDefinition>('book-physio').whatDateAndTimePhysio()
    }

    getBookingConfirmationMessage() {
        return `All done!

You'll receive a confirmation email with information from Ascenti`
    }

    protected async buildAppointment(appointmentOrInProgressBooking: AppointmentOrInProgressBooking, booking: AscentiAppointmentBookOut, physioProviderAppointment: AscentiAppointment, heldAppointmentId: number) {
        //should neaten up heldAppointmentId and whoFor with booking data model like vgp service
        const whoForId = this.enquiryReader.extractWhoForIdFrom(await this.getEnquiryFrom(appointmentOrInProgressBooking))
        const whoFor = await this.lifeService.getLife(whoForId)
        const {Clinic: clinic} = physioProviderAppointment
        const whoWith: string = undefined
        const takesPlaceInApp = false
        const appointment = new Appointment(
            appointmentOrInProgressBooking.id,
            appointmentOrInProgressBooking.type,
            appointmentOrInProgressBooking.dateCreated,
            appointmentOrInProgressBooking.enquiryId,
            physioProviderAppointment.DateTime,
            whoFor,
            whoWith,
            new Address(clinic.ClinicName, undefined, clinic.Address1, undefined, clinic.Postcode, undefined, undefined),
            takesPlaceInApp
        )
        appointment.setInMetadata(this.metadataKeys.appointmentId, heldAppointmentId)
        appointment.setInMetadata(this.metadataKeys.bookingCaseId, booking.CaseID)
        appointment.setInMetadata(this.metadataKeys.bookingClinicId, clinic.ClinicID)
        appointment.setInMetadata(this.metadataKeys.bookingCancellationToken, booking.CancellationToken)
        appointment.setInMetadata(this.metadataKeys.bookingCaseReference, booking.CaseReference)
        return appointment
    }

    protected async makeBookingWithServiceProvider(heldAscentiAppointmentId: number, appointment: AppointmentOrInProgressBooking) {
        const acceptTerms = true
        const enquiry = await this.getEnquiryFrom(appointment)
        const injury = this.enquiryReader.extractInjuryFrom(enquiry)
        const whoForId = this.enquiryReader.extractWhoForIdFrom(enquiry)!
        const booking = await this.ascentiService.bookHeldAppointment(heldAscentiAppointmentId, whoForId, acceptTerms, injury)
        if (booking.Success) {
            return booking
        } else {
            this.logger.error(booking.Message)
            throw booking.Message
        }
    }

    protected async getEnquiryFrom(appointmentOrBooking: Appointment | InProgressAppointmentBooking) {
        return (appointmentOrBooking as InProgressAppointmentBooking).enquiry ?? (await this.enquiryService.get(appointmentOrBooking.enquiryId))
    }
}
