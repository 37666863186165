import {
    AppointmentRepository,
    ClaimActivityRepository,
    InProgressRepository,
    PeachyFlashRepo,
    PeachyRepoRootNode
} from '@peachy/flash-repo-peachy-client'
import {
    InProgressAppointmentBooking,
    InProgressClaimActivity,
    RepoInProgress,
    RepoInProgressAppointmentBooking,
    RepoInProgressClaimActivity,
    RepoInProgressTypes
} from '@peachy/repo-domain'
import {EnquiryService} from './enquiry/EnquiryService'
import {Logger} from '@peachy/utility-kit-pure'


type InProgressThingWithEnquiry = InProgressAppointmentBooking | InProgressClaimActivity
type RepoInProgressThing = RepoInProgressAppointmentBooking | RepoInProgressClaimActivity

export class InProgressService {

    constructor(protected readonly logger: Logger,
                protected readonly repo: PeachyFlashRepo,
                protected readonly inProgressRepository: InProgressRepository,
                protected readonly claimActivityRepository: ClaimActivityRepository,
                protected readonly appointmentRepository: AppointmentRepository,
                protected readonly enquiryService: EnquiryService) {
    }

    async get<T extends RepoInProgressTypes>(nameOfThingInProgress: T): Promise<RepoInProgress[T]> {
        return this.ifNotDuplicated(await this.inProgressRepository.get(nameOfThingInProgress))
    }

    async save<T extends RepoInProgressTypes>(nameOfThingInProgress: T, thing: RepoInProgress[T], context?: PeachyRepoRootNode): Promise<RepoInProgress[T]> {
        if (!await this.aFinishedThingWithTheSameIdExists(thing)) {
            return this.inProgressRepository.save(nameOfThingInProgress, thing, context)
        }
    }

    async deleteInProgressThingAndEnquiry(thing: InProgressThingWithEnquiry) {
        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            await this.deleteInProgressThingButPreserveEnquiry(thing, rootTx)
            await this.enquiryService.delete(thing.enquiry, rootTx)
        })
    }

    async getAllThingsInProgress() {
        const all = await this.inProgressRepository.getAllThingsInProgress()
        if (all.CLAIM) {
            all.CLAIM = await this.ifNotDuplicated(all.CLAIM) as RepoInProgressClaimActivity
        }
        if (all.COVER_CHECK) {
            all.COVER_CHECK = await this.ifNotDuplicated(all.COVER_CHECK) as RepoInProgressClaimActivity
        }
        if (all.PHYSIO) {
            all.PHYSIO = await this.ifNotDuplicated(all.PHYSIO) as RepoInProgressAppointmentBooking
        }
        if (all.VIRTUAL_GP) {
            all.VIRTUAL_GP = await this.ifNotDuplicated(all.VIRTUAL_GP) as RepoInProgressAppointmentBooking
        }
        return all
    }

    async deleteInProgressThingButPreserveEnquiry(thing: InProgressThingWithEnquiry, context?: PeachyRepoRootNode) {
        return this.inProgressRepository.delete(this.getKeyFor(thing), context)
    }

    protected getKeyFor<T extends RepoInProgressTypes>(thing: RepoInProgressThing | InProgressThingWithEnquiry): T {
        // @ts-ignore
        return thing.type ?? thing.stage
    }

    private async ifNotDuplicated<T extends RepoInProgressTypes>(thing?: RepoInProgress[T]) {
        // this shouldn't be necessary but there's a bug (yet to be identified exactly where/how) where items can remain "inProgress" even after submitting
        if (await this.aFinishedThingWithTheSameIdExists(thing)) {
            this.logger.error(`duplicated inProgress ${this.getKeyFor(thing)} detected on load`, {name: 'on-load-in-progress'})
            await this.inProgressRepository.delete(this.getKeyFor(thing))
            return undefined
        } else {
            return thing
        }
    }

    private async aFinishedThingWithTheSameIdExists<T extends RepoInProgressTypes>(thing?: RepoInProgress[T]) {
        let dupeExists = false
        if (thing) {
            const finishedThingIds = [await this.claimActivityRepository.listIds(), await this.appointmentRepository.listIds()].flat()
            dupeExists = finishedThingIds.includes(thing.id)
        }
        return dupeExists
    }
}
