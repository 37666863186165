import {MrLife, MrPolicy, MrSubscription, nonPricedBenefits} from '@peachy/core-domain-pure'
import {omit, values} from '@peachy/utility-kit-pure'
import {BENEFITS_WITH_LIMITS, QuoteLife, QuoteLifeResponse, toCoreBenefitType} from '@peachy/quote-pure'


export function applyQuoteToSubscription(subscription: MrSubscription, quoteResponse: QuoteLifeResponse) {
    values(subscription.policies).forEach(policy => {
        applyQuoteToPolicy(policy, quoteResponse)
    })
    subscription.totalMonthlyPremium = values(subscription.policies)
        .filter(p => p.status === 'ACTIVE')
        .map(p => p.totalMonthlyPremium).reduce((s, i) => s + i, 0)
}


export function applyQuoteToPolicy(policy: MrPolicy, quoteResponse: QuoteLifeResponse) {
    values(policy.lives).forEach(life => {
        if (life.id in quoteResponse.lives) {
            const quoteLife = quoteResponse.lives[life.id]
            applyQuoteToLife(quoteLife, life)
        }
    })
    policy.totalMonthlyPremium = values(policy.lives)
        .filter(l => l.status === 'ACTIVE')
        .map(l => l.totalMonthlyPremium).reduce((s, i) => s + i, 0)
}


function applyQuoteToLife(quotedLife: QuoteLife, life: MrLife) {

    values(quotedLife.benefits).forEach(quotedBenefit => {
        const coreBenefitIds = toCoreBenefitType(quotedBenefit.id)
        coreBenefitIds.forEach(coreBenefitId => {
            if (coreBenefitId in life.benefits) {
                const lifeBenefit = {
                    ...life.benefits[coreBenefitId],
                    ...omit(quotedBenefit, 'id', 'limit'),
                }

                if (nonPricedBenefits.includes(coreBenefitId)) {
                    delete lifeBenefit.premium
                }
                if (BENEFITS_WITH_LIMITS.includes(quotedBenefit.id)) {
                    lifeBenefit.limit = quotedBenefit.limit
                }

                life.benefits[coreBenefitId] = lifeBenefit
            }
        })
    })
    life.totalMonthlyPremium = values(life.benefits)
        .filter(b => b.status === 'ACTIVE')
        .filter(b => b.premium)
        .map(b => b.premium.total).reduce((s, i) => s + i, 0)
}
