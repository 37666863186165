import {newUUID} from '@peachy/utility-kit-pure'
import {from, Subject} from 'rxjs'
import {concatMap} from 'rxjs/operators'

export class SequentialExecutor {

    private workQueue$ = new Subject<Work>()
    private results$ = this.workQueue$.pipe(
        concatMap(work => from(work.fn()).pipe(
            concatMap(result => from(this.asResult(work.id, result)))
        ))
    )
    private promiseResolvers: Map<string, PromiseResolver> = new Map()

    private subscription

    constructor() {
        this.subscription = this.results$.subscribe({
            next: it => {
                const {resolve} = this.promiseResolvers.get(it.id) ?? {}
                this.promiseResolvers.delete(it.id)
                resolve?.(it.result)
            },
            error: reason => {
                [...this.promiseResolvers.values()].forEach(it => it.reject(reason))
            }
        })
    }

    public async execute<T = any>(fn: Work<T>['fn']) {

        this.throwErrorIfDisposed()

        const work = {fn, id: newUUID()}
        const promisedResult = new Promise<T>((resolve, reject) =>
            this.promiseResolvers.set(work.id, {resolve, reject})
        )
        this.workQueue$.next(work)
        return promisedResult
    }

    public async dispose() {
        // wait for a nominal fn to pass through the execution queue so that everything currently queued up will be notified before unsubscribing
        await this.execute(async () => '')
        this.subscription.unsubscribe()
    }

    private throwErrorIfDisposed() {
        if (this.subscription.closed) {
            const error = 'Cannot add new executions after disposal.  You must create a new instance'
            console.error(error)
            throw new Error(error)
        }
    }

    private async asResult<T>(id: string, result: T) {
        return {id, result}
    }
}

type Work<T = any> = {
    fn: () => Promise<T>
    id: string
}

type PromiseResolver<T = any> = {
    resolve: (value: T) => void
    reject: (reason: string) => void
}