import {createSignal, Match, Switch} from 'solid-js'
import {TextBox} from '../../components/TextBox/TextBox'
import {PasswordBox} from '../../components/TextBox/PasswordBox'
import {IamError} from '../components/IamError'
import {IamProps} from '../IamModal'
import {PasswordPolicy} from '../components/PasswordPolicy'
import {isValidEmail, isValidPassword} from '../../to-abstract/validate'
import {useIamService} from '../../controllers/DashboardServiceController'


export function ResetPassword(props: IamProps) {

    const iamService = useIamService()

    const [email, setEmail] = props.email

    const [password, setPassword] = createSignal('')
    const [passwordCode, setPasswordCode] = createSignal('')

    const hasCredentials = () => isValidEmail(email()) && isValidPassword(password()) && !!passwordCode()

    const resetPassword = () => {
        if (hasCredentials()) iamService.resetPassword(email(), password(), passwordCode())
    }

    const sendCode = () => {
        if (isValidEmail(email())) iamService.requestPasswordCode(email())
    }

    return <>
        <h3>Reset password</h3>
        <IamError/>
        <TextBox value={email()} onChange={setEmail} onEnter={resetPassword} placeholder={'email address'}/>
        <Switch>
            <Match when={iamService.isInState('PasswordResetRequested.Idle')}>
                <button onClick={sendCode} disabled={!isValidEmail(email())}>Send code</button>
            </Match>
            <Match when={iamService.isInState('PasswordResetRequested.PasswordCodeRequired')}>
                <TextBox value={passwordCode()} onChange={setPasswordCode} onEnter={resetPassword} placeholder={'code'}/>
                <a onClick={sendCode}>Resend code &rarr; </a>
                <PasswordBox placeholder={'new password'} value={password()} onChange={setPassword} onEnter={resetPassword}/>
                <PasswordPolicy/>

                <button onClick={resetPassword} disabled={!hasCredentials()}>Send code</button>
            </Match>
        </Switch>
        <a onClick={iamService.cancelPasswordReset}>Cancel &rarr; </a>
    </>
}
