import {IdentityFunction, lcs, lcs3d} from './longest-common-subsequence'
import {groupBy} from '@peachy/utility-kit-pure'

type ElementMerge = [unknown, unknown, unknown]

export type Intent = {
    identity: unknown
    elements: ElementMerge
    intentType: 'None' | 'AddCom' | 'RemCom' | 'AddL' | 'AddR' | 'RemL' | 'RemR'
}

export type ElementMergeFunction = (left: unknown, right: unknown, center?: unknown) => unknown

export function lcsMerge(
    left: unknown[],
    right: unknown[],
    center: unknown[],
    getIdentity: IdentityFunction,
    mergeElement: ElementMergeFunction
): unknown[] {
    const [_, commonIndexes] = lcs3d(left, right, center, getIdentity)

    console.log({commonIndexes})

    const intents: Intent[] = []

    let leftI = 0
    let rightI = 0
    let centerI = 0

    commonIndexes.push([left.length, right.length, center.length])
    commonIndexes.forEach(commonI => {
        const [li, ri, ci] = commonI

        const leftSlice = left.slice(leftI, li)
        const rightSlice = right.slice(rightI, ri)
        const centerSlice = center.slice(centerI, ci)

        if (leftSlice.length || rightSlice.length || centerSlice.length) {
            mergeSlice(
                intents,
                leftSlice,
                rightSlice,
                centerSlice,
                [leftI, rightI, centerI],
                getIdentity
            )
        }

        if (li < left.length) {
            [leftI, rightI, centerI] = [li + 1, ri + 1, ci + 1]

            const intent: Intent = {
                intentType: 'None',
                identity: getIdentity(left[li]),
                elements: [left[li], right[ri], center[ci]],
            }
            intents.push(intent)
        }
    })
    return reconcile(intents, mergeElement)
}

function mergeSlice(
    merged: Intent[],
    leftSlice: unknown[],
    rightSlice: unknown[],
    centerSlice: unknown[],
    sliceIndexes: number[],
    getIdentity: IdentityFunction
) {
    const [, lrCommonIndexes] = lcs(leftSlice, rightSlice, getIdentity)
    const [_lcCommon, lcCommonIndexes] = lcs(leftSlice, centerSlice, getIdentity)
    const [_rcCommon, rcCommonIndexes] = lcs(rightSlice, centerSlice, getIdentity)

    let leftI = 0
    let rightI = 0

    lrCommonIndexes.push([leftSlice.length, rightSlice.length])
    lrCommonIndexes.forEach((commonI) => {
        const [li, ri] = commonI

        const leftSubSlice = leftSlice.slice(leftI, li)
        const rightSubSlice = rightSlice.slice(rightI, ri)

        const centerCommon: number[] = []

        leftSubSlice.forEach((e, i) => {
            const eI = leftI + i
            const isCommon = lcCommonIndexes.find(([c, ,]) => c === eI)
            if (isCommon) {
                centerCommon.push(isCommon[1])
            }
            const intent: Intent = {
                intentType: isCommon ? 'RemR' : 'AddL',
                identity: getIdentity(e),
                elements: [e, null, isCommon ? centerSlice[isCommon[1]] : null],
            }
            merged.push(intent)
        })

        rightSubSlice.forEach((e, i) => {
            const eI = rightI + i
            const isCommon = rcCommonIndexes.find(([c, ,]) => c === eI)
            if (isCommon) {
                centerCommon.push(isCommon[1])
            }
            const intent: Intent = {
                intentType: isCommon ? 'RemL' : 'AddR',
                identity: getIdentity(e),
                elements: [null, e, isCommon ? centerSlice[isCommon[1]] : null],
            }
            merged.push(intent)
        })

        centerSlice.forEach((e, i) => {
            if (!centerCommon.includes(i)) {
                const intent: Intent = {
                    intentType: 'RemCom',
                    identity: getIdentity(e),
                    elements: [null, null, e],
                }
                merged.push(intent)
            }
        })

        if (li < leftSlice.length) {
            [leftI, rightI] = [li + 1, ri + 1]
            const intent: Intent = {
                intentType: 'AddCom',
                identity: getIdentity(leftSlice[li]),
                elements: [leftSlice[li], rightSlice[ri], null],
            }
            merged.push(intent)
        }
    })
}

export function reconcile(
    intents: Intent[],
    mergeElement: ElementMergeFunction
) {
    const grouped = groupBy(intents, (i) => i.identity)

    const badIntents = new Set<Intent>()

    grouped.forEach((eIntents, _) => {
        let movements = 0

        const lAdds: Intent[] = []
        let rAdds: Intent[] = []

        const lRems: Intent[] = []
        let rRems: Intent[] = []

        eIntents.forEach((i) => {
            if (i.intentType === 'RemCom') {
                movements--
            } else if (i.intentType === 'AddCom') {
                movements++
            } else if (i.intentType === 'AddL') {
                lAdds.push(i)
            } else if (i.intentType === 'AddR') {
                rAdds.push(i)
            } else if (i.intentType === 'RemL') {
                lRems.push(i)
            } else if (i.intentType === 'RemR') {
                rRems.push(i)
            }
        })

        if (movements < 0) {
            const bad = Math.min(-movements, lAdds.length)
            rAdds = rAdds.slice(0, bad)
            rAdds.forEach((i) => badIntents.add(i))
        } else if (movements > 0) {
            const bad = Math.min(movements, lRems.length)
            rRems = rRems.slice(0, bad)
            rRems.forEach((i) => badIntents.add(i))
        }

        const leftMovements = Math.min(lAdds.length, lRems.length)
        const rightMovements = Math.min(rAdds.length, rRems.length)

        lAdds.slice(0, leftMovements).forEach((lAdd, i) => {
            lAdd.elements = [
                lAdd.elements[0],
                lRems[i].elements[1],
                lRems[i].elements[2],
            ]
        })

        rAdds.slice(0, rightMovements).forEach((rAdd, i) => {
            rAdd.elements = [
                rRems[i].elements[0],
                rAdd.elements[1],
                rRems[i].elements[2],
            ]
        })
    })

    const merged: unknown[] = []

    intents.forEach((i) => {
        if (badIntents.has(i)) {
            if (i.intentType === 'RemR') {
                merged.push(i.elements[0])
            }
        } else {
            if (['None', 'AddCom'].includes(i.intentType)) {
                merged.push(mergeElement(...i.elements))
            } else if (i.intentType === 'AddL') {
                if (i.elements[1]) {
                    merged.push(mergeElement(...i.elements))
                } else {
                    merged.push(i.elements[0])
                }
            } else if (i.intentType === 'AddR') {
                if (i.elements[0]) {
                    merged.push(mergeElement(...i.elements))
                } else {
                    merged.push(i.elements[1])
                }
            }
        }
    })
    return merged
}
