import {MergeBias, MergeState} from '../../primatives/repo-primatives'
import {isArray, isNullish, isObject, isPrimitive, Keyed} from '@peachy/utility-kit-pure'
import {Page} from '../../primatives/page-primatives'
import equal from 'fast-deep-equal/es6'
import {isRepoHash, RepoHash, StringHash} from '../../primatives/hash-primatives'
import setOps from 'mnemonist/set'
import {longestCommonSubsequenceMerge} from './longestCommonSubsequenceMerge'
import {PageStore} from '../page/PageStore'
import {areCompatible, resolveChildPages} from '../diff/diff-kit'


export async function mergeState(
    preMergeState: MergeState,
    pageStore: PageStore,
    mergeBias: MergeBias,
): Promise<MergeState> {

    const postMergeState = {...preMergeState}

    if (preMergeState.localHead.equals(preMergeState.remoteHead)) {
        // no change
        postMergeState.commonHead = preMergeState.localHead
        postMergeState.mergeStatus = preMergeState.mergeStatus

   } else if (preMergeState.localHead.equals(preMergeState.commonHead)) {
        // pulled
        postMergeState.localHead = preMergeState.remoteHead
        postMergeState.commonHead = preMergeState.remoteHead
        postMergeState.mergeStatus = 'PULLED'

    } else if (preMergeState.remoteHead.equals(preMergeState.commonHead)) {
        // pushed
        postMergeState.remoteHead = preMergeState.localHead
        postMergeState.commonHead = preMergeState.localHead
        postMergeState.mergeStatus = 'PUSHED'

    } else {
        // merged
        const mergedResult = await merge(
            preMergeState.localHead,
            preMergeState.remoteHead,
            preMergeState.commonHead,
            pageStore,
            mergeBias
        )
        const mergedHash = await pageStore.store(mergedResult)

        postMergeState.localHead = mergedHash
        postMergeState.remoteHead = preMergeState.remoteHead
        postMergeState.commonHead = preMergeState.remoteHead
        postMergeState.mergeStatus = 'MERGED'
    }
    return postMergeState
}


export async function merge(
    left: unknown,
    right: unknown,
    center: unknown,
    pageStore: PageStore,
    mergeBias: MergeBias
): Promise<unknown> {

    let biased = mergeBias === 'RIGHT' ? right : left
    switch (true) {
        case equal(left, center):
            return right
        case equal(right, center):
            return left
        case equal(left, right):
            return biased
        case !areCompatible(left, right):
            return biased
        case isPrimitive(biased) || isNullish(biased):
            return biased
    }

    left = isRepoHash(left) ? await pageStore.fetchPage(left) : left
    right = isRepoHash(right) ? await pageStore.fetchPage(right) : right
    center = isRepoHash(center) ? await pageStore.fetchPage(center) : center


    biased = mergeBias === 'RIGHT' ? right : left

    if (isArray(left) && isArray(right)) {
        return mergeArrays(left as unknown[], right as unknown[], center as unknown[], pageStore, mergeBias)
    }
    else if (isObject(left) && isObject(right)) {
        return mergeObjects(left as Keyed, right as Keyed, center as Keyed, pageStore, mergeBias)
    }
    return biased
}

export async function mergeObjects(
    left: Keyed,
    right: Keyed,
    center: Keyed,
    pageStore: PageStore,
    mergeBias: MergeBias
) {

    const leftKeys = new Set(Object.keys(left))
    const rightKeys = new Set(Object.keys(right))
    const centerKeys = new Set(Object.keys(center))

    const allKeys = setOps.union(leftKeys, centerKeys, rightKeys)

    const merged: Keyed = {}

    for (const key of allKeys.values()) {
        const mergedValue = await merge(left[key], right[key], center[key], pageStore, mergeBias)
        if (!isNullish(mergedValue)) {
            merged[key] = mergedValue
        }
    }
    return merged
}


export async function mergeArrays(
    left: unknown[],
    right: unknown[],
    center: unknown[],
    pageStore: PageStore,
    mergeBias: MergeBias
) {

    const mappedChildPages = new Map<StringHash, Page>()
    await resolveChildPages(left, mappedChildPages, pageStore)
    await resolveChildPages(right, mappedChildPages, pageStore)
    await resolveChildPages(center, mappedChildPages, pageStore)
    const rawMerged = longestCommonSubsequenceMerge(
        left,
        right,
        center,
        {
            mergeElementFunction: (l, r, c) => merge(l, r, c, pageStore, mergeBias),
            identityFunction: (x) => {
                let element = x
                if (x instanceof RepoHash) {
                    element = mappedChildPages.get(x.toString())
                }
                if (isObject(element)) {
                    return element?.id ?? x
                } else {
                    return x
                }
            },
            equalityFunction: (a, b) => {
                a = a instanceof RepoHash ? a.toString() : a
                b = b instanceof RepoHash ? b.toString() : b
                return a === b
            },
            mergeBias
        },
    )
    return Promise.all(rawMerged.values())
}
