import {PeachyFlashRepo} from '../types'

export class PolicyRepository {

    constructor(protected readonly repo: PeachyFlashRepo) { }

    async getPolicy() {
        const root = await this.repo.getContentRoot()
        return (root.policy())
    }

}
