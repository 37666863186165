import {Auth} from '@aws-amplify/auth'
import {dump} from '@peachy/utility-kit-pure'

type AuthType = typeof Auth

export type IamApi = {
    extractError: (e: any) => string
} & Pick<AuthType,
    'currentAuthenticatedUser' |
    'changePassword' |
    'completeNewPassword' |
    'confirmSignIn' |
    'confirmSignUp' |
    'verifyUserAttribute' |
    'verifyUserAttributeSubmit' |
    'updateUserAttributes' |
    'forgotPassword' |
    'forgotPasswordSubmit' |
    'resendSignUp' |
    'signIn' |
    'signOut' |
    'signUp'>


export function createIamApi(): IamApi {
    const aug = Auth as any
    aug.extractError = extractError
    return aug
}


function extractError(e: any) {
    console.log('Received raw error', e.toString())
    dump(e)

    const code = e.code
    const message = e.toString()

    switch (code) {
        case 'InvalidPasswordException':
            return 'Password does not comply with policy'
        case 'NotAuthorizedException':
            return 'Invalid credentials'
        case 'LimitExceededException':
            return 'Woah there! Too many attempts! Please try again later...'
        default:
            return message
    }
}



