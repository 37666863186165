import {BenefitsRepository, PeachyFlashRepo, PlanRepository} from '@peachy/flash-repo-peachy-client'
import {Benefit, BenefitType, DomainMappings, RepoPlan} from '@peachy/repo-domain'
import {DateInterval} from '@peachy/utility-kit-pure'
import {isBefore} from 'date-fns'
import {sortBy} from 'lodash-es'

export class BenefitsService {

    constructor(protected readonly repo: PeachyFlashRepo,
                protected readonly benefitsRepository: BenefitsRepository,
                protected readonly planRepository: PlanRepository) {
    }

    async getBenefit(id: string) {
        const benefit = await this.benefitsRepository.get(id)
        return DomainMappings.fromRepo.toBenefit(benefit)
    }

    async getBenefitOfTypeForLife(type: BenefitType, lifeId: string) {
        const benefit = (await this.planRepository.findByLifeId(lifeId)).benefits.find(it => it.type === type)
        return DomainMappings.fromRepo.toBenefit(benefit)
    }

    async listBenefits() {
        const benefits = await this.benefitsRepository.list()
        return benefits.map(it => DomainMappings.fromRepo.toBenefit(it))
    }

    getBenefitsThatWereActiveDuring(interval: DateInterval, repoPlan: RepoPlan) {
        const benefitHistory = repoPlan.benefits.map(it => DomainMappings.fromRepo.toBenefit(it))
        const benefitsActiveInInterval = benefitHistory.filter(it =>
            it.wasAcceptingClaimSubmissionsAtAnytimeDuring(interval) ||
            it.wasAcceptingCoverCheckSubmissionsAtAnytimeDuring(interval)
        )

        // there may be a couple of cancelled then re-added benefits within the interval.  for now we only care about the latest but later we may care about the history within the interval.  if so this logic needs to change
        const latestActiveBenefitsInInterval = this.mostRecentlyStartedBenefitOfEachTypeFrom(benefitsActiveInInterval)
        return sortBy(latestActiveBenefitsInInterval, it => it.type)
    }

    private mostRecentlyStartedBenefitOfEachTypeFrom(benefits: Benefit[]) {
        const mostRecentlyStartedOf = (a: Benefit, b?: Benefit) => b ? isBefore(b.startDate, a.startDate) ? a : b : a

        const latestBenefitByType = benefits.reduce((collector, it) => {
            collector[it.type] = mostRecentlyStartedOf(it, collector[it.type])
            return collector
        }, {} as Record<BenefitType, Benefit>)

        return Object.values(latestBenefitByType)
    }

}
