import {TypeEditorProps} from './RepoNodeEditor'
import {FlashNode, FlashValue} from '@peachy/flash-repo-pure'
import {createEffect, createResource, createSignal, For, Show} from 'solid-js'
import {
    createToggleSignal,
    DragNode,
    DragScope,
    Modal,
    Tag,
    useDragHook,
    useOnKeyDown,
    useOnKeyUp
} from '@peachy/client-kit'
import {Value} from '../RepoView/RepoView'
import {isArray} from '@peachy/utility-kit-pure'

import styles from './ElementEditor.module.css'

export function ElementEditor(props: TypeEditorProps) {

    const [tags, setTags] = createSignal<Tag[]>([])
    const [deletedTags, setDeletedTags] = createSignal<Tag[]>([])
    function removeDeletedTags<T extends FlashNode>(item: T): T {
        if (isArray(item)) {
            const newArray = [...item]
            deletedTags().forEach(tag => {
                // @ts-ignore
                delete newArray[tag]
            })
            return newArray.filter(e => !!e) as T
        } else {
            const newObject = {...item}
            deletedTags().forEach(tag => {
                // @ts-ignore
                delete newObject[tag]
            })
            return newObject
        }
    }

    const [resolved, trigger] = createResource(async () => {
        const resolvedChildren = await props.treeResolver.resolveChildren(props.value)
        setTags(Object.keys(resolvedChildren))
        return resolvedChildren as FlashNode
    })

    createEffect(() => {
        props.value
        trigger.refetch()
    })


    const [dragEnabled, setDragEnabled] = createSignal(false)

    useOnKeyDown(() => {
        setDragEnabled(true)
    }, ' ')
    useOnKeyUp(() => {
        setDragEnabled(false)
    }, ' ')


    const onDrop = (newOrder: DragNode<string>[]) => {
        const tags = newOrder.map(e => e.dataItem)
        setTags(tags)
        const node = resolved()
        const newItem = isArray(node)? [] : {}
        tags.forEach(tag => {
            // @ts-ignore
            newItem[tag] = node[tag]
        })
        props.onChange(removeDeletedTags(newItem))
    }

    const toggleDeleted = (tag: Tag) => {
        if (deletedTags().includes(tag)) {
            setDeletedTags(deletedTags().filter(t => t !== tag))
        } else {
            setDeletedTags([...deletedTags(), tag])
        }
        props.onChange(removeDeletedTags(resolved()))
    }

    const addElement = () => {
        const item = resolved()
        if (isArray(item)) {
            const newItem = [...removeDeletedTags(item), null]
            setTags(Object.keys(newItem))
            props.onChange(newItem)
            setTags(tags)
        } else {
            toggleShowTagModal()
        }
    }

    const [showTagModal, toggleShowTagModal] = createToggleSignal(false)
    const [tagForEdit, setTagForEdit] = createSignal('')

    const addTag = (oldTag: string, newTag: string) => {
        toggleShowTagModal()

        if (!newTag) {
            return
        }

        const item = resolved()
        let newItem: FlashNode

        if (oldTag) {
            // @ts-ignore
            newItem = {...removeDeletedTags(item), [newTag]: item[oldTag]}
            // @ts-ignore
            delete newItem[oldTag]

        } else {
            newItem = {...removeDeletedTags(item), [newTag]: null as FlashValue}
        }
        setTags(Object.keys(newItem))
        props.onChange(newItem)
    }

    const editTag = (tag: Tag) => {
        if (isArray(resolved())) {

        } else {
            setTagForEdit(tag as string)
            toggleShowTagModal()
        }
    }

    return (
        <Show when={resolved.latest}>
            <div class={styles.ElementEditor}>
                <DragScope onDrop={onDrop} isDraggable={() => dragEnabled()}>
                        <ul>
                            <For each={tags()}>{(tag) =>
                                <li ref={useDragHook(tag)} data-item={tag} class={deletedTags().includes(tag) ? styles.deletedEntry : ''}>
                                    <button onClick={() => toggleDeleted(tag)} class={styles.deleteEntry}><i class={'fa-solid fa-trash'}/></button>
                                    <dl>
                                        <dt>{tag}: </dt>
                                        {/*<dt ondblclick={() => editTag(tag)}>{tag}: </dt>*/}
                                        <dd><Value value={resolveValue(tag, resolved())} showChildren={false} tag={tag} node={props.value}/></dd>
                                    </dl>
                                </li>
                            }</For>
                        </ul>
                        <button onClick={addElement} class={styles.addEntry}><i class={'fa-solid fa-plus'}/></button>
                </DragScope>
                <Modal isOpen={showTagModal()} onDismiss={toggleShowTagModal}>
                    <TagModal tag={tagForEdit()} onUpdate={addTag}/>
                </Modal>
            </div>
        </Show>
    )
}


type TagModalProps = {
    tag: string,
    onUpdate: (oldTag: string, newTag: string) => void
}

function TagModal(props: TagModalProps) {
    const [newTag, setNewTag] = createSignal<string>()
    return (
        <aside class={styles.tagModal}>
            <input type={'text'} value={props.tag} oninput={e => setNewTag(e.currentTarget.value)}/>
            <button onClick={() => props.onUpdate(props.tag, newTag())}>Add</button>
        </aside>
    )
}

function resolveValue(tag: Tag, item: FlashNode) {
    if (isArray(item)) {
        return item[+tag]
    } else {
        return item[tag as string]
    }
}
