import {isCancelled, MrLife, MrLifeBenefit, MrPolicy, MrSubscription, TransferDetails} from '@peachy/core-domain-pure'
import {mapById, values} from '@peachy/utility-kit-pure'
import {ensureCancellationStatus} from './cancellation'

export function transferInSubscription(
    subscription: MrSubscription,
    from: string,
    effectiveDate: number,
    reason: string,
) {
    const transferredSubscription = {...subscription}
    delete transferredSubscription.totalMonthlyPremium
    transferredSubscription.transfer = transferInStatus(from, effectiveDate, reason)

    const transferredPolicies = values(transferredSubscription.policies)
        .filter(p => !isCancelled(p))
        .map(p => transferInPolicy(p, from, effectiveDate, reason))

    transferredSubscription.policies = mapById(transferredPolicies)

    return transferredSubscription
}


export function transferOutSubscription(
    subscription: MrSubscription,
    from: string,
    effectiveDate: number,
    reason: string,
) {
    const transferredSubscription = {...subscription}
    transferredSubscription.transfer = transferOutStatus(from, effectiveDate, reason)
    transferredSubscription.transfer.in = subscription.transfer?.in

    const transferredPolicies = values(transferredSubscription.policies)
        .filter(p => !isCancelled(p))
        .map(p => transferOutPolicy(p, from, effectiveDate, reason))

    transferredSubscription.policies = {
        ...subscription.policies,
        ...mapById(transferredPolicies)
    }
    ensureCancellationStatus(transferredSubscription, effectiveDate, reason)

    return transferredSubscription
}


export function transferInPolicy(
    policy: MrPolicy,
    from: string,
    effectiveDate: number,
    reason: string,
): MrPolicy {

    const transferredPolicy = {...policy}
    delete transferredPolicy.totalMonthlyPremium
    transferredPolicy.transfer = transferInStatus(from, effectiveDate, reason)

    const transferredLives = values(transferredPolicy.lives)
        .filter(l => !isCancelled(l))
        .map(l => transferInLife(l, from, effectiveDate, reason))

    transferredPolicy.lives = mapById(transferredLives)
    return transferredPolicy
}


export function transferOutPolicy(
    policy: MrPolicy,
    from: string,
    effectiveDate: number,
    reason: string,
): MrPolicy {

    const transferredPolicy = {...policy}
    transferredPolicy.transfer = transferOutStatus(from, effectiveDate, reason)
    transferredPolicy.transfer.in = policy.transfer?.in

    const transferredLives = values(transferredPolicy.lives)
        .filter(l => !isCancelled(l))
        .map(l => transferOutLife(l, from, effectiveDate, reason))

    transferredPolicy.lives = {
        ...policy.lives,
        ...mapById(transferredLives)
    }
    ensureCancellationStatus(transferredPolicy, effectiveDate, reason)


    return transferredPolicy
}


export function transferInLife(
    life: MrLife,
    from: string,
    effectiveDate: number,
    reason: string,
): MrLife {

    const transferredLife = {...life}
    delete transferredLife.totalMonthlyPremium

    transferredLife.transfer = transferInStatus(from, effectiveDate, reason)

    const transferredBenefits = values(transferredLife.benefits)
        .filter(b => !isCancelled(b))
        .map(b => transferInBenefit(b, from, effectiveDate, reason))

    transferredLife.benefits = mapById(transferredBenefits)

    return transferredLife
}

export function transferOutLife(
    life: MrLife,
    from: string,
    effectiveDate: number,
    reason: string,
): MrLife {

    const transferredLife = {...life}

    transferredLife.transfer = transferOutStatus(from, effectiveDate, reason)
    transferredLife.transfer.in = life.transfer?.in

    const transferredBenefits = values(transferredLife.benefits)
        .filter(b => !isCancelled(b))
        .map(b => transferOutBenefit(b, from, effectiveDate, reason))

    transferredLife.benefits = {
        ...life.benefits,
        ...mapById(transferredBenefits)
    }

    ensureCancellationStatus(transferredLife, effectiveDate, reason)

    return transferredLife
}


export function transferInBenefit(
    benefit: MrLifeBenefit,
    from: string,
    effectiveDate: number,
    reason: string,
): MrLifeBenefit {
    const transferredBenefit = {...benefit}
    delete transferredBenefit.premium
    transferredBenefit.effectiveDate = effectiveDate
    transferredBenefit.transfer = transferInStatus(from, effectiveDate, reason)
    return transferredBenefit
}


export function transferOutBenefit(
    benefit: MrLifeBenefit,
    to: string,
    effectiveDate: number,
    reason: string,
): MrLifeBenefit {
    const transferredBenefit = {...benefit}
    transferredBenefit.transfer = transferOutStatus(to, effectiveDate, reason)
    transferredBenefit.transfer.in = benefit.transfer?.in
    ensureCancellationStatus(transferredBenefit, effectiveDate, reason)
    return transferredBenefit
}


export function transferInStatus(
    from: string,
    date: number,
    reason: string,
): TransferDetails {
    return {in: {from, date, reason}}
}

export function transferOutStatus(
    to: string,
    date: number,
    reason: string,
): TransferDetails {
    return {out: {to, date, reason}}
}
