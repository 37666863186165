import {QuestionOption} from '@peachy/repo-domain'

export class SearchService {

    basicOptionsSearch(options: QuestionOption[], stem: string) {
        return options.filter(option =>
            option.text.toLowerCase().includes(stem.toLowerCase())
        ).sort(this.byTextStartsWithStemThenAlphabetically.bind(null, stem))
    }

    byTextStartsWithStemThenAlphabetically = (stem: string, a: QuestionOption, b: QuestionOption) => byStartsWithStemThenAlphabetically(stem, a.text, b.text)

    basicTextSearch(searchableItems: string[], stem: string) {
        return searchableItems.filter(it =>
            it.toLowerCase().includes(stem.toLowerCase())
        ).sort(byStartsWithStemThenAlphabetically.bind(null, stem))
    }

}

const byStartsWithStemThenAlphabetically = (stem: string, a: string, b: string) => {
    if (a.startsWith(stem) && !b.startsWith(stem)) {
        return -1
    }
    if (b.startsWith(stem) && !a.startsWith(stem)) {
        return 1
    }
    if (a > b) {
        return 1
    }
    if (a < b) {
        return -1
    }
    return 0
}

