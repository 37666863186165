import {isArray, isBoolean, isNullish, isNumber, isObject, isString} from '@peachy/utility-kit-pure'
import {isRepoHash, RepoHash, StringHash} from '../../primatives/hash-primatives'
import {Page} from '../../primatives/page-primatives'
import {PageStore} from '../page/PageStore'

export type EqualityFunction = (a: unknown, b: unknown) => boolean;
export type IdentityFunction = (x: unknown) => unknown;
export type ResolverFunction = (x: unknown) => Promise<unknown>


export const defaultIdentityFunction: IdentityFunction = (x: unknown) => x
export const defaultEqualityFunction: EqualityFunction = (a: unknown, b: unknown) => a === b


export const idBasedIdentityFunction: IdentityFunction = (x) => {
    const element = x
    if (isObject(element)) {
        return element?.id ?? x
    } else {
        return x
    }
}


export function areCompatible(left: unknown, right: unknown): right is typeof left {
    switch (true) {
        case isNullish(left):
        case isNullish(right):
        case isString(left) && isString(right):
        case isNumber(left) && isNumber(right):
        case isBoolean(left) && isBoolean(right):
        case isRepoHash(left) && isRepoHash(right):
        case isArray(left) && isArray(right):
        case isObject(left) && isObject(right):
            return true
        default:
            return false
    }
}


export async function resolveChildPages(values: unknown[], mappedPages: Map<StringHash, Page>, pageStore: PageStore) {
    await Promise.all((values.filter(v => v instanceof RepoHash) as RepoHash[]).map(async k => {
        if (!mappedPages.has(k.toString())) {
            const page = await pageStore.fetchPage(k)
            mappedPages.set(k.toString(), page)
        }
    }))
}
