import {CustomerApplicationContextProvider} from '../../controllers/CustomerApplicationContextProvider'
import {useParams} from '@solidjs/router'
import {ParentProps} from 'solid-js'

export default function CustomerAppContextAwareRoutes(props: ParentProps) {

    const {customerId} = useParams()


    return (
        <CustomerApplicationContextProvider customerId={customerId}>
            {props.children}
        </CustomerApplicationContextProvider>
    )

}
