import styles from './MainNav.module.css'
import {UserAdminActions} from '@peachy/web-dashboard-pure'
import {A} from '@solidjs/router'

export function MainNav() {
    return (
        <nav class={styles.MainNav}>
            <ul>
                {/*<li><IamLink path={"/premium-bdx"} groups={[AdminUserGroup, InsurerUserGroup]} >Premium Bordereaux</IamLink></li>*/}
                {/*<li><IamLink path={"/claim-bdx"} groups={[AdminUserGroup, InsurerUserGroup]} >Claim Bordereaux</IamLink></li>*/}
                <li><A href={`/${UserAdminActions.newCustomerPassword}`}>New Customer Password</A></li>
                <li><A href={`/${UserAdminActions.newDashUserPassword}`} >New Dash User Password</A></li>
                {/*<li><IamLink path={`/payments`} groups={AdminUserGroup}>Payments</IamLink></li>*/}
                <li><A href={`/group-subscription`}>Group Subscription</A></li>
                {/*<li><IamLink path={`/scratch`} groups={AdminUserGroup}>Scratch</IamLink></li>*/}
            </ul>
        </nav>
    )
}
