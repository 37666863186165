import {
    ApprovedClaimCosts,
    BankDetails,
    ClaimActivity,
    ClaimActivitySubmissionReason,
    ClaimAssessment,
    ClaimInvoiceLineItem,
    ClaimStage,
    ClaimStages,
    ClaimSubmissionIdentifier,
    Decision,
    DecisionTypes,
    DomainMappings,
    Enquiry,
    InProgressClaimActivity,
    Life,
    prettyPrintBenefit,
    RepoClaimAssessment,
    whoOptions
} from '@peachy/repo-domain'
import {ClaimActivityRepository, PeachyFlashRepo, PeachyRepoRootNode} from '@peachy/flash-repo-peachy-client'
import {concurrentlyIgnoringErrors, Logger, newUUID, Optional, PropertiesOnly} from '@peachy/utility-kit-pure'
import {PolicyService} from './PolicyService'
import {QuestionIds} from './enquiry/types'
import {EnquiryReader, MakeClaimEnquiryReader} from './enquiry/EnquiryReader'
import {EnquiryService} from './enquiry/EnquiryService'
import {LifeService} from './LifeService'
import {BenefitsService} from './BenefitsService'
import {InProgressService} from './InProgressService'
import {IPeachyClient} from './IPeachyClient'
import {RepoManagementService} from './RepoManagementService'
import {SequentialExecutor} from '@peachy/flash-repo-pure'
import {EnquiryDefinitionCommon} from './enquiry/definition/EnquiryDefinitionCommon'
import {isEmpty} from 'lodash-es'

export class ClaimsService {

    constructor(protected readonly logger: Logger,
                protected readonly peachyClient: IPeachyClient,
                protected readonly repo: PeachyFlashRepo,
                protected readonly claimActivityRepository: ClaimActivityRepository,
                protected readonly benefitsService: BenefitsService,
                protected readonly inProgressService: InProgressService,
                protected readonly enquiryService: EnquiryService,
                protected readonly lifeService: LifeService,
                protected readonly policyService: PolicyService,
                protected readonly repoManagementService: RepoManagementService) {
    }

    async getInProgressOrInitiateNew(claimStage: ClaimStage) {
        const inProgress = await this.getInProgress(claimStage)
        if (inProgress) {
            this.logger.debug('loading an in progress', claimStage, 'id:', inProgress.id, 'enquiryId:', inProgress?.enquiry?.id)
            return inProgress
        } else {
            this.logger.debug('creating new', claimStage)
            return this.initiateNew(claimStage)
        }
    }

    async getInProgress(claimStage: ClaimStage) {
        const inProgress = await this.inProgressService.get(claimStage)
        if (inProgress) {
            const enquiry = await this.enquiryService.get(inProgress.enquiryId)
            return DomainMappings.fromRepo.toInProgressClaimActivity(inProgress, enquiry)
        }
    }

    async deleteInProgressClaimActivityAndEnquiry(claimActivity: InProgressClaimActivity) {
        return this.inProgressService.deleteInProgressThingAndEnquiry(claimActivity)
    }

    async deleteInProgressClaimActivityButPreserveEnquiry(claimActivity: InProgressClaimActivity, context?: PeachyRepoRootNode) {
        await this.inProgressService.deleteInProgressThingButPreserveEnquiry(claimActivity, context)
    }

    async raiseTreatmentProviderInvoicedClaim(claimId: string, whoFor: Life, submissionDate: Date, submissionId: ClaimSubmissionIdentifier, invoiceLineItem: ClaimInvoiceLineItem, reason: ClaimActivitySubmissionReason,  enquiryId: string) {
        const amountReimbursed: Optional<number> = undefined
        const decision: Decision = undefined
        const customerDeclaredTreatment: string = undefined
        const media = {}

        const dateCreated = submissionDate
        const benefit = await this.benefitsService.getBenefitOfTypeForLife(invoiceLineItem.benefitType, whoFor.id)
        const assessment = new ClaimAssessment({invoiceLineItems: [invoiceLineItem], submissionReasons: [reason]})

        const declaredCost = invoiceLineItem.invoiceAmountInPence

        const claimActivity = new ClaimActivity(
            claimId,
            ClaimStages.CLAIM,
            dateCreated,
            enquiryId,
            submissionId,
            submissionDate,
            benefit,
            prettyPrintBenefit(benefit),
            customerDeclaredTreatment,
            whoFor,
            declaredCost,
            invoiceLineItem.treatmentDate,
            decision,
            amountReimbursed,
            media,
            assessment
        )

        await this.save(claimActivity)

        return claimActivity
    }

    async submit(inProgress: InProgressClaimActivity) {
        const submitQuestionId = this.getSubmissionQuestionIdFor(inProgress)
        // cover checks for dental and optical don't need to actually raise a ticket, they won't have got to the end of the enquiry...
        const shouldRaiseTicket = inProgress.enquiry.getQuestion(submitQuestionId)?.satisfied
        const claimActivity = await this.convertToSubmittedActivity(inProgress, new Date())

        const bankDetailsToPersist = this.getBankDetailsToPersistIfAnyIn(inProgress)
        if (bankDetailsToPersist) {
            await this.lifeService.updateBankDetailsOfAppOwner(bankDetailsToPersist)
        }

        await this.repoManagementService.syncRepoWithRemoteIgnoreErrors()

        if (shouldRaiseTicket) {
            this.logger.debug('raising a claim activity ticket')
            const submissionId = await this.peachyClient.raiseClaimsActivityTicket({
                id: inProgress.id,
                stage: inProgress.stage,
                reference: inProgress.referenceNumber
            })
            this.logger.debug('response submission id is', 'conversation:', submissionId?.conversation?.id, 'message:', submissionId?.message?.id)
            if (!submissionId) {
                this.logger.error('no submission id returned when raising claim activity', {name: 'claim-missing-submission-id'})
            }
            await this.claimActivityRepository.patch({id: claimActivity.id, submissionId})
            await this.repoManagementService.syncRepoWithRemoteIgnoreErrors()
        }

        try {
            await this.uploadMediaWhereNotAlreadyDoneFrom(claimActivity, inProgress.enquiry)
        } catch (e) {
            // log but ignore because it should get resolved on next restart of the app anyway
            this.logger.error(e, {name: `${inProgress.stage}-media-upload-on-submit`})
        }

    }

    async rollbackEnquirySubmission(inProgress: InProgressClaimActivity) {
        this.logger.debug('rolling back enquiry submission')
        const lastSubmittedQuestion = inProgress.enquiry.getLastSatisfiedQuestion()
        return this.enquiryService.unsatisfyAllQuestionsFromAndAfter(inProgress.enquiry, lastSubmittedQuestion.id)
    }

    private getSubmissionQuestionIdFor(claimActivity: InProgressClaimActivity) {
        const enquiryReader = EnquiryReader.forClaimActivity(claimActivity.enquiry)
        return enquiryReader.getSubmitQuestionId()
    }

    private getBankDetailsToPersistIfAnyIn(claimActivity: InProgressClaimActivity) {
        if (claimActivity.isClaim()) {
            const enquiryReader = EnquiryReader.forClaimActivity(claimActivity.enquiry) as MakeClaimEnquiryReader
            const shouldPersist = enquiryReader.extractShouldPersistBankDetails(claimActivity.enquiry)
            console.log('REALLY? ', shouldPersist)
            return shouldPersist ? enquiryReader.extractBankDetailsFrom(claimActivity.enquiry) : undefined
        }
    }

    public async resumeUploadOfMissingMediaFrom(claimActivities: ClaimActivity[]) {
        this.logger.debug('resuming upload of missing media for claim activities: ', claimActivities.map(it => it.id))
        for (const claimActivity of claimActivities) {
            const enquiry = await this.enquiryService.get(claimActivity.enquiryId)
            await this.uploadMediaWhereNotAlreadyDoneFrom(claimActivity, enquiry)
        }
    }

    // should upload in background and look into resumable https://trello.com/c/ZHIvDSkr/24-bug-app-is-sometimes-not-fully-uploading-video-selfies-and-receipts-to-s3
    private async uploadMediaWhereNotAlreadyDoneFrom(claimActivity: ClaimActivity, enquiry: Enquiry) {
        this.logger.debug(`uploading media for ${claimActivity?.id}`)
        const toUpload = this.getUploadableMediaContentFrom(claimActivity, enquiry)
        const path = `/${claimActivity.stage}/${claimActivity.id}`
        const sequentially = new SequentialExecutor()

        await concurrentlyIgnoringErrors(toUpload.map(async ({fileName, contentType, dataUri, processingRequired}) => {
            try {
                await sequentially.execute(async () => this.claimActivityRepository.updateMediaUploadState(claimActivity.id, fileName, 'INITIATED'))
                const mediaBlob = await this.safelyLoadFileBlob(dataUri)
                if (mediaBlob) {
                    this.logger.debug('uploading', fileName)
                    const processingTags = !isEmpty(processingRequired) ? `processing_required=${processingRequired.join(' ')}` : undefined

                    await this.peachyClient.uploadCustomerContent(
                        claimActivity.treatmentReceiver?.id,
                        path,
                        fileName,
                        mediaBlob,
                        contentType,
                        processingTags
                    )
                    await sequentially.execute(async () => this.claimActivityRepository.updateMediaUploadState(claimActivity.id, fileName, 'COMPLETE'))
                } else {
                    // not much we can do if the file is missing from the device
                    await sequentially.execute(async () => this.claimActivityRepository.updateMediaUploadState(claimActivity.id, fileName, 'PERMANENTLY_FAILED'))
                }
            } catch (e) {
                this.logger.error(e, {name: 'claim-media-upload', message: `failed to upload ${fileName}`})
                await sequentially.execute(async () => this.claimActivityRepository.updateMediaUploadState(claimActivity.id, fileName, 'FAILED'))
            }
        }))

        this.logger.debug(`done uploading media for ${claimActivity?.id}`)
        await sequentially.dispose()
    }

    private async safelyLoadFileBlob(uri: string) {
        try {
            this.logger.debug('loading', uri)
            const file = await fetch(uri)
            return await file.blob()
        } catch (e) {
            this.logger.error(e, {name: 'missing-device-media'})
            // do nothing
        }
    }

    private getUploadableMediaContentFrom(claimActivity: ClaimActivity, enquiry: Enquiry) {
        const mediaQuestions = enquiry.getQuestionsWithMediaAnswers()
        const filesToIgnore = claimActivity.uploadedOrPermanentlyFailedMediaFileNames
        const uploadable = mediaQuestions.flatMap(mediaQuestion => mediaQuestion.getMediaAnswers().map((media, index) => {
            const fileName = `${mediaQuestion.id}_${index+1}${media.extension}`
            return {
                fileName,
                contentType: media.type,
                dataUri: media.uri,
                processingRequired: EnquiryDefinitionCommon.getProcessingTags(mediaQuestion.tags)
            }
        })).filter(it => !filesToIgnore.includes(it.fileName))
        this.logger.debug('uploadable media for claim activity', claimActivity.id, uploadable.map(it => it.fileName))
        return uploadable
    }

    async saveInProgress(inProgress: InProgressClaimActivity) {
        const repoInProgress = DomainMappings.fromDomain.toRepoInProgressClaimActivity(inProgress)
        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            await this.enquiryService.save(inProgress.enquiry, rootTx)
            await this.inProgressService.save(repoInProgress.stage, repoInProgress, rootTx)
        })
    }

    async save(claimActivity: ClaimActivity, context?: PeachyRepoRootNode) {
        const repoClaimActivity = DomainMappings.fromDomain.toRepoClaimActivity(claimActivity)
        return this.claimActivityRepository.save(repoClaimActivity, context)
    }

    async sendMessageAboutClaim(claim: ClaimActivity, message: string) {
        return this.peachyClient.commentOnClaimsActivity(claim.id, message)
    }

    async delete(claimActivityId: string) {
        return this.claimActivityRepository.delete(claimActivityId)
    }

    async decline(claimActivityId: string, reason: string, date = new Date()) {
        return this.applyDecision(claimActivityId, {
            date,
            type: DecisionTypes.DECLINE,
            notes: reason
        })
    }

    async approve(claimActivityId: string, approvedCosts: Optional<ApprovedClaimCosts>, date = new Date()) {
        // todo we're always marking a claim as reimbursed if there are any approved costs.  we need better integration with payments systems to set this correctly and automatically
        const markAsReimbursed = (approvedCosts?.postExcessOverallTotalApprovedInPence ?? 0) > 0
        return this.applyDecision(claimActivityId, {
            date,
            type: DecisionTypes.APPROVE,
            approvedCosts
        }, markAsReimbursed)
    }

    private async applyDecision(claimActivityId: string, decisionProps: Pick<Decision, 'type'|'notes'|'date'|'approvedCosts'>, markAsReimbursed?: boolean) {
        const decision = new Decision(
            decisionProps.date,
            decisionProps.type,
            decisionProps.notes,
            decisionProps.approvedCosts
        )
        return this.claimActivityRepository.patch({
            id: claimActivityId,
            decision: DomainMappings.fromDomain.toRepoDecision(decision),
            amountReimbursedInPence: markAsReimbursed && decision.isApprove() ? decisionProps.approvedCosts?.postExcessOverallTotalApprovedInPence : undefined
        })
    }

    async refer(claimActivityId: string, referralDate: Date) {
        const assessment = await this.claimActivityRepository.getExistingOrNewBlankAssessmentFor(claimActivityId)
        assessment.referralDate = DomainMappings.fromDomain.toRepoDate(referralDate)
        return this.claimActivityRepository.patch({id: claimActivityId, assessment})
    }

    async saveAssessment(claimActivityId: string, partialUpdates: Omit<PropertiesOnly<ClaimAssessment>, 'referralDate'>) {
        const repoOriginal = await this.claimActivityRepository.getExistingOrNewBlankAssessmentFor(claimActivityId)
        const repoUpdates = DomainMappings.fromDomain.toRepoClaimAssessment(partialUpdates)
        const repoNew: RepoClaimAssessment = {...repoOriginal, ...repoUpdates, referralDate: repoOriginal.referralDate}
        return this.claimActivityRepository.patch({id: claimActivityId, assessment: repoNew})
    }

    private async convertToSubmittedActivity(inProgress: InProgressClaimActivity, dateSubmitted: Date) {
        this.logger.debug(`convert in progress to submitted claims activity ${inProgress.id}`)
        const enquiryReader = EnquiryReader.forClaimActivity(inProgress.enquiry)

        const enquiry = inProgress.enquiry
        const treatmentReceiverLifeId = enquiryReader.extractTreatmentReceiverLifeIdFrom(enquiry)
        const benefitId = enquiryReader.extractBenefitIdFrom(enquiry)

        const amountReimbursed: Optional<number> = undefined
        const decision: Optional<Decision> = undefined
        const assessment: Optional<ClaimAssessment> = undefined
        const submissionId: Optional<ClaimSubmissionIdentifier> = undefined
        const media = {}

        const claimActivity = new ClaimActivity(
            inProgress.id,
            inProgress.stage,
            inProgress.dateCreated,
            inProgress.enquiry.id,
            submissionId,
            dateSubmitted,
            await this.benefitsService.getBenefit(benefitId),
            enquiryReader.extractBenefitOptionTextFrom(enquiry),
            enquiryReader.extractTreatmentFrom(enquiry),
            await this.lifeService.getLife(treatmentReceiverLifeId),
            enquiryReader.extractCostInPenceFrom(enquiry),
            enquiryReader.extractTreatmentDateFrom(enquiry),
            decision,
            amountReimbursed,
            media,
            assessment
        )

        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            await this.save(claimActivity, rootTx)
            await this.enquiryService.save(enquiry, rootTx)
            await this.deleteInProgressClaimActivityButPreserveEnquiry(inProgress, rootTx)
        })

        return claimActivity
    }

    private async initiateNew(claimStage: ClaimStage) {
        const enquiry = await this.initiateNewEnquiryFor(claimStage)
        const inProgress = new InProgressClaimActivity(newUUID(), claimStage, new Date(), enquiry)
        this.logger.debug('created new', claimStage, 'id: ', inProgress.id, 'enquiryId:', enquiry.id)
        return inProgress
    }

    protected async initiateNewEnquiryFor(stage: ClaimStage) {
        const isClaim = stage === ClaimStages.CLAIM
        const policy = await this.policyService.getPolicy()
        const appOwnerOption = whoOptions([policy.primaryLife])[0]

        const bankAcc = policy.primaryLife.bankAccount
        const savedBankDetails = bankAcc ? new BankDetails(bankAcc.accountNumber, bankAcc.sortCode) : undefined

        const init = {
            [QuestionIds['who-is-the-app-owner']]: [appOwnerOption],
            ...(isClaim ? {
                [QuestionIds['saved-bank-account-details']]: [savedBankDetails]
            } : {})
        }
        return this.enquiryService.initiateNewEnquiry(isClaim ? 'make-claim' : 'cover-check', policy, init)
    }
}
