import {isCancelled, MrLife, MrPlan} from '@peachy/core-domain-pure'
import {dump, keys, mapById, readableDate, unique, values} from '@peachy/utility-kit-pure'
import {reconcileBenefitModelAlteration} from './reconcileBenefitModelAlteration'
import {cancelLife, ensureCancellationStatus} from './valid-alterations/cancellation'
import {reactivateLife} from './valid-alterations/reactivation'
import {latestOf, pruneUndefined} from '../alteration-kit/loose-end-kit'
import {transferInLife, transferOutLife} from './valid-alterations/transfer'


export function reconcileLifeModelAlteration(
    currentLife: MrLife,
    alteredLife: MrLife,
    alteredLifePlan: MrPlan,
    effectiveDate: number
): MrLife {

    assertAreCompatible(currentLife, alteredLife, alteredLifePlan)

    if (!currentLife) {
        if (alteredLife.transfer?.in) {
            // life transferred in
            alteredLife = transferInLife(
                alteredLife,
                alteredLife.transfer.in.from,
                alteredLife.transfer.in.date ?? effectiveDate,
                alteredLife.transfer.in.reason ?? 'Life transferred in - no reason available'
            )
        } else {
            // life added
            return reconcileNewLife(alteredLife, alteredLifePlan, effectiveDate)
        }
    } else {
        if (alteredLife?.transfer?.out && !currentLife.transfer?.out) {
            // life transferred out
            return transferOutLife(
                currentLife,
                currentLife.transfer.out.to,
                currentLife.transfer.out.date ?? effectiveDate,
                currentLife.transfer.out.reason ?? 'Life transferred out - no reason available',
            )
        } else {
            // life removed or cancelled?
            if (!alteredLife || (isCancelled(alteredLife) && !alteredLife.transfer?.out)) {
                const endDate = alteredLife?.endDate ?? effectiveDate
                return cancelLife(currentLife, endDate, alteredLife?.cancellationReason ?? 'Life removed')
            }

            // life reactivated?
            if ((alteredLife && currentLife && isCancelled(currentLife) && !isCancelled(alteredLife))) {
                alteredLife = reactivateLife(alteredLife, effectiveDate)
            }
        }
    }

    // reconcile benefits
    const reconciledBenefits = reconcileLifeBenefits(currentLife, alteredLife, alteredLifePlan, effectiveDate)
    alteredLife.benefits = mapById(reconciledBenefits)

    if (currentLife) {
        // reconcile life properties
        reconcileLifeModelBirthdate(currentLife, alteredLife, effectiveDate)
        reconcileLifeModelAddress(currentLife, alteredLife, effectiveDate)
    }


    // clear premium if any benefits need quoting
    if (!reconciledBenefits.every(b => !!b.premium)) {
        delete alteredLife.totalMonthlyPremium
    }

    // cancel life if all benefits are cancelled
    if (reconciledBenefits.every(isCancelled)) {
        ensureCancellationStatus(alteredLife, effectiveDate, 'Automatic cancellation due to all benefits being cancelled')
    }

    // return reconciled life
    pruneUndefined(alteredLife)
    alteredLife.benefits = mapById(reconciledBenefits)
    return alteredLife
}


export function reconcileNewLife(
    newLife: MrLife,
    plan: MrPlan,
    effectiveDate: number
): MrLife {
    const lifeEffectiveDate = latestOf(newLife.startDate, effectiveDate)
    const reconciledBenefits = reconcileLifeBenefits(null, newLife, plan, lifeEffectiveDate)

    return {
        ...newLife,
        status: 'ACTIVE',
        startDate: lifeEffectiveDate,
        benefits: mapById(reconciledBenefits)
    }
}


function assertAreCompatible(currentLife: MrLife, alteredLife: MrLife, plan: MrPlan) {

    // if life is altered, the life ids must match
    if (currentLife && alteredLife) {
        if (currentLife.id !== alteredLife.id) {
            throw `Cannot alter life id from ${currentLife.id} to ${alteredLife.id}`
        }
    }
    // if life is altered or new, new life version plan id must match supplied plan
    if (alteredLife && alteredLife.planId !== plan?.id) {
        throw `mismatched plans ${alteredLife.planId} to ${plan?.id}`
    }
}


function reconcileLifeBenefits(currentLife: MrLife, alteredLife: MrLife, alteredPlan: MrPlan, effectiveDate: number) {
    const currentLifeBenefits = currentLife?.benefits ?? {}

    const allBenefitIds = unique([...keys(currentLifeBenefits), ...keys(alteredLife.benefits), ...keys(alteredPlan.benefits)])

    return allBenefitIds.map(benefitId => {

        const currentBenefit = currentLife?.benefits?.[benefitId]
        const alteredBenefit = alteredLife?.benefits?.[benefitId]
        const planBenefit = alteredPlan?.benefits?.[benefitId]
        return reconcileBenefitModelAlteration(currentBenefit, alteredBenefit, planBenefit, effectiveDate)
    })
}


function reconcileLifeModelBirthdate(currentLife: MrLife, alteredLife: MrLife, effectiveDate: number) {

    if (alteredLife.dateOfBirth < currentLife.dateOfBirth) {
        // They are older than we thought
        values(alteredLife.benefits).forEach(benefit => {
            delete benefit.premium
            benefit.effectiveDate = latestOf(effectiveDate, benefit.effectiveDate)
        })
    } else if (alteredLife.dateOfBirth > currentLife.dateOfBirth) {
        // They are younger than we thought
        values(alteredLife.benefits).forEach(benefit => {
            delete benefit.premium
        })
    }
}


function reconcileLifeModelAddress(currentLife: MrLife, alteredLife: MrLife, effectiveDate: number) {
    const currentAddress = currentLife.address
    const alteredAddress = alteredLife.address
    if (currentAddress.region !== alteredAddress.region || currentAddress.postcode !== alteredAddress.postcode) {
        values(alteredLife.benefits).forEach(benefit => {
            delete benefit.premium
            benefit.effectiveDate = latestOf(effectiveDate, benefit.effectiveDate)
        })
    }
}
