import {PeachyLogo} from '../../components/Logo/Logo'
import styles from './Header.module.css'

import {classList} from '@peachy/utility-kit-pure'
import {useIamService} from '../../controllers/DashboardServiceController'

type HeaderProps = {
}

export function Header(props: HeaderProps) {

    const iam = useIamService()
    const signOutClasses = classList('fa fa-sign-out', styles.signOut)

    return (
        <header class={styles.Header}>
            <a href="/">
                <PeachyLogo class={styles.Logo} />
            </a>
            <i class={signOutClasses} onClick={() => iam.signOut()}/>
        </header>
    )
}
