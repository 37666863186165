import {RepoNode} from '../primatives/graph-primitives'
import {RepoHash} from '../primatives/hash-primatives'
import {BranchInfo, BranchName, InstallationId, MergeBias} from '../primatives/repo-primatives'
import {IRepoSyncApi} from '../sync-api-definition/sync-api-definition'
import {BookmarkOrTimestamp} from '@peachy/utility-kit-pure'
import {Observable} from 'rxjs'

export interface IMemoRepo<RootType = object> {

    fetchRootNode<T = RootType, ReadOnly extends boolean = false>(branch?: BranchName, readOnly?: ReadOnly): Promise<RepoNode<T, ReadOnly>>

    fetchRootNodeVersionAtTimestamp<T = RootType>(timestamp: number, branch: BranchName): Promise<RepoNode<T, true>>

    fetchNode<T = RootType>(fromKey: RepoHash): Promise<RepoNode<T, true>>

    fetchBranchHistorySlice(
        since: BookmarkOrTimestamp,
        until: BookmarkOrTimestamp,
        branch?: BranchName
    ): Observable<BranchInfo>


    fetchBranchHistory(
        branch?: BranchName
    ): Observable<BranchInfo>

    createBranch<T = RootType>(branch: BranchName, commitMessage: string): Promise<RepoNode<T, false>>
    createDefaultBranch<T = RootType>(commitMessage?: string): Promise<RepoNode<T, false>>
    deleteBranch(branch: BranchName): Promise<void>
    branchExists(branch: BranchName): Promise<boolean>

    sync(
        remoteSyncAgent: IRepoSyncApi,
        remoteInstallationId: InstallationId,
        branchName: BranchName,
        mergeBias: MergeBias
    ): Promise<void>

}
