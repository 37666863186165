import {
    GetCareFilters,
    getCareFiltersToMarketplaceQuery,
    GetCareSearchResults,
    MarketplaceResult,
    PracticeResult,
    PracticeResultWithId
} from '@peachy/repo-domain'
import {isEmpty, sortBy} from 'lodash-es'
import {Dictionary, isAscenti, regionEncompassing} from '@peachy/utility-kit-pure'
import {IPeachyClient} from './IPeachyClient'
import {SearchService} from './SearchService'

const MAX_SEARCH_RADIUS_IN_METERS = 20000
const INITIAL_SEARCH_RADIUS_IN_METERS = 6000

export class GetCareService {

    constructor(protected readonly peachyClient: IPeachyClient,
                protected readonly searchService: SearchService) {
    }

    async queryAutocomplete(stem: string) {
        const suggestions = this.searchService.basicTextSearch(getCareAutocomplete, stem)
        return {suggestions, stem}
    }

    async search(filters: GetCareFilters): Promise<GetCareSearchResults> {
        const query = getCareFiltersToMarketplaceQuery(applyLimitsTo(filters))
        let practitioners = (await this.peachyClient.searchMarketplace(query)) as MarketplaceResult[]

        // should stop filtering virtual Ascenti once functionality implemented
        practitioners = practitioners.filter(it => !(isAscenti(it) && it.externalId === '32663'))

        practitioners = sortBy(practitioners, it => isAscenti(it) ? 0 : 1)

        const practices = extractDistinctPracticesFrom(practitioners)
        return {
            practitioners,
            practices
        }
    }

    getCommonSearches() {
        return [
            {text: 'Physiotherapy', searchTerms: ['Physiotherapy']},
            {text: 'Orthopaedic surgery', searchTerms: ['Orthopaedic surgery']},
            {text: 'General surgery', searchTerms: ['General surgery']},
            {text: 'Dermatology', searchTerms: ['Dermatology']},
            {text: 'ENT surgery', searchTerms: ['ENT surgery']},
        ]
    }

    getMaxSearchRadiusInMeters() {
        return MAX_SEARCH_RADIUS_IN_METERS
    }

    static getDefaultSearchRadiusInMeters() {
        return INITIAL_SEARCH_RADIUS_IN_METERS
    }

    practitionerWorksAtPractice(practitioner?: MarketplaceResult, givenPractice?: PracticeResult) {
        return givenPractice && practitioner?.practices?.some(practitionerPractice => areSame(practitionerPractice, givenPractice)) || false
    }

    regionEncompassing(practices: PracticeResult[]) {
        return regionEncompassing(practices.map(it => ({
            longitude: it.location.lng,
            latitude: it.location.lat
        })))
    }
}

function extractDistinctPracticesFrom(results: MarketplaceResult[]) {
    const practicesByKey = !isEmpty(results) ? results.reduce((collector, result) => {
        result.practices.forEach(practice => {
            const id = uniqueKeyFor(practice)
            if (id) {
                collector[id] = {id, ...practice}
            }
        })
        return collector
    }, {} as Dictionary<PracticeResultWithId>) : {}
    return Object.values(practicesByKey)
}

function applyLimitsTo(filters: GetCareFilters): GetCareFilters {
    return {...filters, distance: Math.min(filters.distance ?? MAX_SEARCH_RADIUS_IN_METERS, MAX_SEARCH_RADIUS_IN_METERS)}
}

function areSame(practice: PracticeResult, otherPractice: PracticeResult) {
    return uniqueKeyFor(practice) === uniqueKeyFor(otherPractice)
}

function uniqueKeyFor(practice: PracticeResult) {
    return practice?.location?.lat && [practice.location.lat, practice.location.lng, practice.name].join('|')
}

const specialisms = [
    "Breast surgery",
    "Care of the elderly medicine",
    "Child and adolescent psychiatry",
    "Endocrine surgery",
    "Forensic psychiatry",
    "Gastroenterology",
    "Haematology",
    "Infectious diseases",
    "Lower GI surgery",
    "Medical psychotherapy",
    "Nephrology (Renal medicine)",
    "Neurology",
    "Old age psychiatry",
    "Oncology",
    "Paediatric cardiology",
    "Paediatric endocrinology",
    "Paediatric gastroenterology",
    "Paediatric haematology",
    "Paediatric nephrology",
    "Paediatric neurology",
    "Paediatric oncology",
    "Paediatric surgery",
    "Psychiatry of learning disability",
    "Respiratory medicine",
    "Rheumatology",
    "Sexual health",
    "Transplant surgery",
    "Upper GI surgery",
    "Urology",
    "Vascular surgery",
]

const specialities = [
    "Cardiology",
    "Cardiothoracic surgery",
    "Dermatology",
    "ENT surgery",
    "Endocrinology",
    "General medicine",
    "General surgery",
    "Haematology",
    "Nephrology (Renal medicine)",
    "Neurology",
    "Neurosurgery",
    "Obstetrics & gynaecology",
    "Oncology",
    "Ophthalmology",
    "Oral & maxillofacial surgery",
    "Orthopaedic surgery",
    "Paediatric surgery",
    "Paediatrics",
    "Psychiatry",
    "Rheumatology",
    "Sexual health",
    "Urology",
    "Physiotherapy"
]

const getCareAutocomplete = [...new Set([...specialities, ...specialisms])]
