import {isCancelled, MrLifeBenefit, MrPlanBenefit} from '@peachy/core-domain-pure'

import {unique} from '@peachy/utility-kit-pure'
import {cancelLifeBenefit} from './valid-alterations/cancellation'
import {reactivateLifeBenefit} from './valid-alterations/reactivation'
import {latestOf} from '../alteration-kit/loose-end-kit'
import {transferInBenefit, transferOutBenefit} from './valid-alterations/transfer'

export function reconcileBenefitModelAlteration(
    currentBenefit: MrLifeBenefit,
    alteredBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
    effectiveDate: number,
): MrLifeBenefit {

    if (!currentBenefit) {
        if (alteredBenefit?.transfer?.in) {
            alteredBenefit = transferInBenefit(
                alteredBenefit,
                alteredBenefit.transfer.in.from,
                alteredBenefit.transfer.in.date ?? effectiveDate,
                alteredBenefit.transfer.in.reason ?? 'Benefit transferred in - no reason available'
            )
        } else {
            return reconcileNewBenefit(alteredBenefit, planBenefit, effectiveDate)
        }
    } else {
        if (alteredBenefit?.transfer?.out && !currentBenefit.transfer?.out) {
            return transferOutBenefit(
                currentBenefit,
                currentBenefit.transfer.out.to,
                currentBenefit.transfer.out.date ?? effectiveDate,
                currentBenefit.transfer.out.reason ?? 'Benefit transferred out - no reason available',
            )
        }
        // benefit removed?
        if (!planBenefit) {
            const endDate = alteredBenefit?.endDate ?? effectiveDate
            return cancelLifeBenefit(currentBenefit, endDate,
                alteredBenefit.cancellationReason ?? 'Benefit removed from plan - no reason available')
        }

        // benefit reactivated?
        if (isCancelled(currentBenefit) && alteredBenefit && !isCancelled(alteredBenefit)) {
            alteredBenefit = reactivateLifeBenefit(alteredBenefit, effectiveDate)
        }

        // Limits changed?
        if (hasLimitChange(currentBenefit, planBenefit)) {
            alteredBenefit = reconcileBenefitLimit(currentBenefit, alteredBenefit, planBenefit, effectiveDate)
        }

        // Excess changed?
        if (hasExcessChange(currentBenefit, planBenefit)) {
            alteredBenefit = reconcileBenefitExcess(currentBenefit, alteredBenefit, planBenefit, effectiveDate)
        }
        // return reconciled benefit after reconciling mori dates etc
        if (alteredBenefit) {
            return reconcileBenefitDates(currentBenefit, alteredBenefit)
        }
    }

    if (!alteredBenefit) {
        alteredBenefit = {
            ...currentBenefit
        }
    }
    assertCompatibleBenefits(currentBenefit, alteredBenefit, planBenefit)
    return alteredBenefit
}


export function reconcileNewBenefit(
    newBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
    effectiveDate: number,
): MrLifeBenefit {

    if (newBenefit) {
        assertCompatibleBenefits(null, newBenefit, planBenefit)
    }

    // effective date or some future date
    const startDate = latestOf(effectiveDate, newBenefit?.startDate ?? effectiveDate)

    return {
        ...newBenefit ?? {},
        ...planBenefit,
        status: 'ACTIVE',
        startDate: startDate,
        moriDate: startDate, // cannot supply directly
        effectiveDate: startDate // cannot supply directly
    }
}


function assertCompatibleBenefits(
    currentBenefit: MrLifeBenefit,
    alteredBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
) {

    const benefitIds =
        unique([currentBenefit?.id, planBenefit?.id, alteredBenefit?.id].filter(id => !!id))

    // all benefits have same id
    if (benefitIds.length !== 1) {
        throw `mismatched benefits: ${benefitIds}`
    }
}


function reconcileBenefitDates(currentBenefit: MrLifeBenefit, alteredBenefit: MrLifeBenefit) {
    alteredBenefit.startDate = currentBenefit.startDate
    alteredBenefit.moriDate = latestOf(currentBenefit.moriDate, alteredBenefit.moriDate)
    alteredBenefit.effectiveDate = latestOf(currentBenefit.effectiveDate, alteredBenefit.effectiveDate)
    return alteredBenefit
}


function reconcileBenefitLimit(
    currentBenefit: MrLifeBenefit,
    alteredBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
    effectiveDate: number
) {
    if (planBenefit.limit && currentBenefit.limit && planBenefit.limit < currentBenefit.limit) {
        return decreaseBenefitLimit(currentBenefit, planBenefit)
    }
    return increaseBenefitLimit(currentBenefit, planBenefit, effectiveDate)
}

function increaseBenefitLimit(
    alteredBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
    effectiveDate: number
): MrLifeBenefit {

    const increasedBenefit = structuredClone(alteredBenefit)
    const increaseEffectiveDate = latestOf(effectiveDate, increasedBenefit.effectiveDate)
    increasedBenefit.limit = planBenefit.limit
    increasedBenefit.moriDate = increaseEffectiveDate
    increasedBenefit.effectiveDate = increaseEffectiveDate
    delete increasedBenefit.premium
    return increasedBenefit
}

function decreaseBenefitLimit(
    lifeBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
) {
    const decreasedBenefit = structuredClone(lifeBenefit)
    decreasedBenefit.limit = planBenefit.limit
    delete decreasedBenefit.premium
    return decreasedBenefit
}

function hasLimitChange(
    lifeBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit
) {
    return lifeBenefit?.limit !== planBenefit?.limit
}


function hasExcessChange(
    lifeBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit
) {
    return lifeBenefit?.excess !== planBenefit?.excess
}

function reconcileBenefitExcess(
    currentBenefit: MrLifeBenefit,
    alteredBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
    effectiveDate: number
) {

    if (planBenefit.excess && currentBenefit.excess && planBenefit.excess < currentBenefit.excess) {
        return decreaseBenefitExcess(currentBenefit, planBenefit, effectiveDate)
    }
    return increaseBenefitExcess(currentBenefit, planBenefit)
}


function decreaseBenefitExcess(
    lifeBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
    effectiveDate: number
) {
    const decreasedBenefit = structuredClone(lifeBenefit)
    const decreaseEffectiveDate = latestOf(effectiveDate, decreasedBenefit.effectiveDate)

    decreasedBenefit.excess = planBenefit.excess
    decreasedBenefit.moriDate = decreaseEffectiveDate
    decreasedBenefit.effectiveDate = decreaseEffectiveDate
    delete decreasedBenefit.premium
    return decreasedBenefit
}

function increaseBenefitExcess(
    lifeBenefit: MrLifeBenefit,
    planBenefit: MrPlanBenefit,
) {
    const increasedBenefit = structuredClone(lifeBenefit)
    increasedBenefit.excess = planBenefit.excess
    delete increasedBenefit.premium
    return increasedBenefit
}
