
export * from './src/quote/select-for-quote'
export * from './src/quote/apply-quotes'
export * from './src/quote/map-to-quote'
export * from './src/reconciliation/reconcileSubscriptionModelAlteration'

export * from './test/TestData'
export * from './test/Assertions'

export * from './src/alteration/SubscriptionAlterationAgent'
export * from './src/alteration/diff/AlterationDiff'

export * from './src/alteration-kit/alteration-types'
export * from './src/reconciliation/gather-alterations/gatherSubscriptionAlterations'
export * from './src/reconciliation/valid-alterations/cancellation'
export * from './src/reconciliation/valid-alterations/reactivation'
export * from './src/alteration-kit/alteration-lookup'
export * from './src/alteration-kit/alteration-predicates'
export * from './src/alteration-kit/loose-end-kit'

        import * as me from '@peachy/alterations-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/alterations/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/alterations-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    