import {ParentProps} from 'solid-js'
import {Dictionary} from '@peachy/utility-kit-pure'

type Propagate = boolean | void
type OnKeyHandler = () => Propagate

export type TextBoxProps = ParentProps<{
    value: string
    placeholder? : string
    onChange: (value: string) => void
    onEnter?: OnKeyHandler
    onArrowDown?: OnKeyHandler
    onArrowUp?: OnKeyHandler
    type?: string
    class?: string
    disabled?: boolean
}>

export function TextBox(props: TextBoxProps) {

    const onKeyPropsFns: Dictionary<OnKeyHandler> = {
        'Enter': props.onEnter,
        'ArrowUp': props.onArrowUp,
        'ArrowDown': props.onArrowDown
    }

    const onKey = (event: KeyboardEvent) => {
        const onKeyHandler = onKeyPropsFns[event.key]
        const propagate = !onKeyHandler || onKeyHandler()
        if (propagate) {
            const input = event.currentTarget as HTMLInputElement
            props.onChange(input.value)
        }
    }

    return (
        <label class={props.class}>
            <input disabled={props.disabled} spellcheck={false} type={props.type ?? 'text'} value={props.value} placeholder={props.placeholder} onKeyUp={onKey}/>
            {props.children}
        </label>
    )
}