import {groupDistinctBy, Optional} from '@peachy/utility-kit-pure'
import {PNode, PNodeTransaction} from '../flash-repo/path-builder/path-builder-domain'
import {RepoArrayPNode, RepoEntityPredicate, RepoEntityWithId, RepoMapPNode} from './types'
import {FlashRepo} from '../flash-repo/FlashRepo'

import _ from 'lodash-es'

const defaultLogger = {debug: console.log, error: console.error}

export type RepoEntityPatch<RepoEntity extends RepoEntityWithId> = Pick<RepoEntity, 'id'> & Partial<RepoEntity>

// should look into efficiency / helper methods in FlashRepo code to make it easier to update arrays by index / append
// should write some tests for all of this
abstract class AbstractRepository<RepoEntity extends RepoEntityWithId, RepoRootDefinition extends object, RepoRootPNode extends PNode<RepoRootDefinition>> {

    protected constructor(protected repo: FlashRepo<RepoRootDefinition>,
                          protected crudHelper: RepoCrudHelper<RepoEntity, RepoRootDefinition, RepoRootPNode>,
                          protected logger = defaultLogger) {
    }

    async get(entityId: string, context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot()
        // @ts-ignore todo why doesn't ts like this
        return this.crudHelper.getEntityById(root, entityId)
    }

    async list(context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // @ts-ignore todo why doesn't ts like this
        return this.crudHelper.listEntities(root)
    }

    async listIds(context?: RepoRootPNode) {
        return (await this.list()).map(it => it.id)
    }

    async getAllMappedById(context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // @ts-ignore todo why doesn't ts like this
        return this.crudHelper.getAllEntitiesMappedById(root)
    }

    async find(query: RepoEntityPredicate<RepoEntity>, context?: RepoRootPNode) {
        const all = await this.list(context)
        return _.find(all, query) as Optional<RepoEntity>
    }

    abstract findIndex(query: RepoEntityPredicate<RepoEntity>, context?: RepoRootPNode): Promise<Optional<number | RepoEntity['id']>>

    async filter(query: RepoEntityPredicate<RepoEntity>, context?: RepoRootPNode) {
        const all = await this.list(context)
        return _.filter(all, query) as RepoEntity[]
    }

    async save(entity: RepoEntity, context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // should really validate the complete entity
        if (entity.id) {
            // @ts-ignore todo why doesn't ts like this
            return this.crudHelper.saveOrUpdateEntityById(root, entity)
        } else {
            // should get hold of the entity container name somehow (I tried and got some odd symbol js error)
            this.logger.debug('tried to save an entity with no id')
        }
    }

    async patch(entityPatch: Pick<RepoEntity, 'id'> & Partial<RepoEntity>, context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // @ts-ignore todo why doesn't ts like this
        return this.crudHelper.patchEntityById(root, entityPatch)
    }

    async delete(entityId: string, context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // @ts-ignore todo why doesn't ts like this
        return this.crudHelper.deleteEntityById(root, entityId)
    }

    async withRootTransaction(doThis: PNodeTransaction<RepoRootDefinition>) {
        const root = await this.repo.getContentRoot<RepoRootDefinition>()
        return root.Δ(doThis)
    }
}

export abstract class AbstractArrayRepository <RepoEntity extends RepoEntityWithId, RepoRootDefinition extends object, RepoRootPNode extends PNode<RepoRootDefinition>> extends AbstractRepository<RepoEntity, RepoRootDefinition, RepoRootPNode> {

    protected constructor(protected readonly repo: FlashRepo<RepoRootDefinition>,
                          protected readonly repoEntityContainerNodeProvider: (root: RepoRootPNode) => RepoArrayPNode<RepoEntity>,
                          protected readonly logger = defaultLogger) {

        super(repo, new RepoArrayPNodeCrudHelper<RepoEntity, RepoRootDefinition, RepoRootPNode>(repoEntityContainerNodeProvider), logger)
    }

    async findIndex(query: RepoEntityPredicate<RepoEntity>, context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // @ts-ignore todo why doesn't ts like this
        return this.crudHelper.findIndex(root, query)
    }
}


export abstract class AbstractMapRepository <RepoEntity extends RepoEntityWithId, RepoRootDefinition extends object, RepoRootPNode extends PNode<RepoRootDefinition>> extends AbstractRepository<RepoEntity, RepoRootDefinition, RepoRootPNode> {

    protected constructor(protected readonly repo: FlashRepo<RepoRootDefinition>,
                          protected readonly repoEntityContainerNodeProvider: (root: RepoRootPNode) => RepoMapPNode<RepoEntity>,
                          protected readonly logger = defaultLogger) {

        super(repo, new RepoMapPNodeCrudHelper<RepoEntity, RepoRootDefinition, RepoRootPNode>(repoEntityContainerNodeProvider), logger)
    }

    async findIndex(query: RepoEntityPredicate<RepoEntity>, context?: RepoRootPNode) {
        const root = context ? context : await this.repo.getContentRoot<RepoRootDefinition>()
        // @ts-ignore todo why doesn't ts like this
        return this.find(query, root)?.id
    }
}

interface RepoCrudHelper<RepoEntity extends RepoEntityWithId, RepoRootDefinition extends object, RepoRootPNode extends PNode<RepoRootDefinition>> {
    getAllEntitiesMappedById (root: RepoRootPNode): Promise<Record<RepoEntity['id'], RepoEntity>>
    listEntities(root: RepoRootPNode): Promise<RepoEntity[]>
    getEntityById(root: RepoRootPNode, entityId: RepoEntity['id']): Promise<Optional<RepoEntity>>
    saveOrUpdateEntityById(root: RepoRootPNode, entityToSave: RepoEntity): Promise<Optional<RepoEntity>>
    deleteEntityById(root: RepoRootPNode, entityId: RepoEntity['id']): Promise<boolean>
    patchEntityById(root: RepoRootPNode, patch: RepoEntityPatch<RepoEntity>): Promise<Optional<RepoEntity>>
}

class RepoMapPNodeCrudHelper <RepoEntity extends RepoEntityWithId, RepoRootDefinition extends object, RepoRootPNode extends PNode<RepoRootDefinition>> implements RepoCrudHelper<RepoEntity, RepoRootDefinition, RepoRootPNode> {

    constructor(protected entityContainerNodeProvider: (root: RepoRootPNode) => RepoMapPNode<RepoEntity>) { }

    async getAllEntitiesMappedById (root: RepoRootPNode) {
        const entityContainer = this.entityContainerNodeProvider(root)
        return entityContainer()
    }

    async listEntities(root: RepoRootPNode) {
        const entityContainer = this.entityContainerNodeProvider(root)
        const allById = await entityContainer() ?? {}
        return Object.values(allById)
    }

    async saveOrUpdateEntityById(root: RepoRootPNode, entityToSave: RepoEntity) {
        const entityContainer = this.entityContainerNodeProvider(root)
        const updated = await entityContainer[entityToSave.id](entityToSave)
        return updated ? entityToSave : undefined
    }

    async deleteEntityById(root: RepoRootPNode, entityId: RepoEntity['id']) {
        const entityContainer = this.entityContainerNodeProvider(root)
        const existingEntities = await entityContainer()
        const existingEntitiesWithoutOneToDelete = _.omit(existingEntities, entityId)
        return entityContainer(existingEntitiesWithoutOneToDelete)
    }

    async patchEntityById(root: RepoRootPNode, patch: RepoEntityPatch<RepoEntity>) {
        const existing = await this.getEntityById(root, patch.id)
        if (existing) {
            return this.saveOrUpdateEntityById(root, {...existing, ...patch})
        }
    }

    async getEntityById(root: RepoRootPNode, entityId: RepoEntity['id']) {
        const entityContainer = this.entityContainerNodeProvider(root)
        return entityContainer[entityId]()
    }

}

class RepoArrayPNodeCrudHelper <RepoEntity extends RepoEntityWithId, RepoRootDefinition extends object, RepoRootPNode extends PNode<RepoRootDefinition>> implements RepoCrudHelper<RepoEntity, RepoRootDefinition, RepoRootPNode> {

    constructor(protected entityContainerNodeProvider: (root: RepoRootPNode) => RepoArrayPNode<RepoEntity>) { }

    async getAllEntitiesMappedById (root: RepoRootPNode) {
        const allEntities = await this.listEntities(root)
        return groupDistinctBy(allEntities, it => it.id)
    }

    async listEntities(root: RepoRootPNode) {
        const entityContainer = this.entityContainerNodeProvider(root)
        const all = await entityContainer()
        return all ?? []
    }

    async getEntityById(root: RepoRootPNode, entityId: RepoEntity['id']) {
        const [entity] = await this.findEntityAndAll(root, it => it.id === entityId)
        return entity
    }

    async saveOrUpdateEntityById(root: RepoRootPNode, entityToSave: RepoEntity) {
        const entityContainer = this.entityContainerNodeProvider(root)
        const [_, index, existingEntities] = await this.findEntityAndIndexAndAll(root, it => it.id === entityToSave.id)
        let saved
        if (index || index === 0) {
            saved = await entityContainer[index](entityToSave)
        } else {
            saved = await entityContainer([...existingEntities, entityToSave])
        }
        return saved ? entityToSave : undefined
    }

    async deleteEntityById(root: RepoRootPNode, entityId: RepoEntity['id']) {
        const entityContainer = this.entityContainerNodeProvider(root)
        const entities = await entityContainer()
        return entityContainer(entities.filter(it => it.id !== entityId))
    }

    async patchEntityById(root: RepoRootPNode, patch: RepoEntityPatch<RepoEntity>) {
        const [existing, index] = await this.findEntityAndIndexAndAll(root, {id: patch.id} as RepoEntityPredicate<RepoEntity>)
        if (existing) {
            return this.saveEntityAtIndex(root, {...existing, ...patch}, index)
        }
    }

    async findIndex(root: RepoRootPNode, queryOrFilterFn: RepoEntityPredicate<RepoEntity>): Promise<Optional<number>> {
        const [_, index] = await this.findEntityAndIndexAndAll(root, queryOrFilterFn)
        return index
    }

    private async findEntityAndAll(root: RepoRootPNode, queryOrFilterFn: RepoEntityPredicate<RepoEntity>): Promise<[Optional<RepoEntity>, RepoEntity[]]> {
        const all = await this.listEntities(root)
        const entity = _.find(all, queryOrFilterFn) as RepoEntity
        return entity ? [entity, all] : [undefined, all]
    }

    private async findEntityAndIndexAndAll(root: RepoRootPNode, queryOrFilterFn: RepoEntityPredicate<RepoEntity>): Promise<[Optional<RepoEntity>, Optional<number>, RepoEntity[]]> {
        const [entity, all] = await this.findEntityAndAll(root, queryOrFilterFn)
        return entity ? [entity, all.findIndex(it => _.isEqual(it, entity)), all] : [undefined, undefined, all]
    }

    private async saveEntityAtIndex(root: RepoRootPNode, entityToSave: RepoEntity, index: number): Promise<Optional<RepoEntity>> {
        const entityContainer = this.entityContainerNodeProvider(root)
        if (index || index === 0) {
            const updated = await entityContainer[index](entityToSave)
            return updated ? entityToSave : undefined
        }
    }
}
