import {PNode, PNodeTransaction} from '../flash-repo/path-builder/path-builder-domain'
import {FlashRepo} from '../flash-repo/FlashRepo'
import {formatISO} from 'date-fns'
import {Logger} from '@peachy/utility-kit-pure'

export type ChangeSet<RootNodeType> = {
    id: string
    author: string
    transaction: PNodeTransaction<RootNodeType>
}
export type ChangeSetFileImport<RootNodeType> = Promise<{changeSets: ChangeSet<RootNodeType>[]}>
type ChangeSetFileId = string
export type ChangeLogEntry<RootNodeType> = [ChangeSetFileId, ChangeSetFileImport<RootNodeType>]
export type ChangeLog<RootNodeType> = ChangeLogEntry<RootNodeType>[]

type RepoChangeLog = {
    changeSetFileId: string
    changeSetId: string
    author: string
    dateExecuted: string
}[]

type FileQualifiedChangeSet<RootNodeType> = { changeSetFileId: string } & ChangeSet<RootNodeType>

export function changeSet<RootNodeType>({id, author}: {id: string, author: string}, transaction: PNodeTransaction<RootNodeType>): ChangeSet<RootNodeType> {
    return {id, author, transaction}
}

export class FlashRepoMigrationRunner<RootNodeType extends { repoChangeLog?: RepoChangeLog }> {

    constructor(readonly repo: FlashRepo<RootNodeType>,
                readonly changeLog: ChangeLog<any>,
                private logger: Logger,
                private stopProcessingOnError = true) {
    }

    async run() {
        const ran = []
        try {
            for (const logEntry of this.changeLog) {
                ran.push(...await this.runMigration(logEntry))
            }
        } catch (e) {
            this.logger.error(e)
        }
        return ran
    }

    private async runMigration([changeSetFileId, changeSetFileImport]: ChangeLogEntry<RootNodeType>) {

        const changeSets = (await changeSetFileImport).changeSets
        const ran = []

        for (const changeSet of changeSets) {
            const fileQualifiedChangeSet = {changeSetFileId, ...changeSet}

            const alreadyRun = await this.isAlreadyRun(fileQualifiedChangeSet)
            this.logMigrateOrSkipInfo(fileQualifiedChangeSet, alreadyRun)

            if (!alreadyRun) {
                const root = await this.repo.getContentRoot()
                try {
                    await root.Δ(async tx => {
                        await changeSet.transaction(tx)
                        await this.markAsRun(fileQualifiedChangeSet, tx)
                    })
                    this.logChangeSetTransactionSuccess(fileQualifiedChangeSet)
                    ran.push(fileQualifiedChangeSet.changeSetFileId)
                } catch (e) {
                    this.logChangeSetTransactionError(e, fileQualifiedChangeSet)
                    if (this.stopProcessingOnError) {
                        throw(new Error('Terminate flash repo migrations early due to error'))
                    }
                }
            }
        }

        return ran
    }

    private async isAlreadyRun({id, changeSetFileId}: FileQualifiedChangeSet<RootNodeType>) {
        const root = await this.repo.getContentRoot<{repoChangeLog: RepoChangeLog}>()
        return !!(await root.repoChangeLog() ?? []).find(it => it.changeSetFileId === changeSetFileId && it.changeSetId === id)
    }


    private async markAsRun(changeSet: FileQualifiedChangeSet<RootNodeType>, tx: PNode<RootNodeType>) {
        const prev = await tx.repoChangeLog() ?? []
        await tx.repoChangeLog([...prev, {
            changeSetFileId: changeSet.changeSetFileId,
            changeSetId: changeSet.id,
            author: changeSet.author,
            dateExecuted: now()
        }])
    }

    private logChangeSetTransactionError(error: any, changeSet: FileQualifiedChangeSet<RootNodeType>) {
        this.logger.error(`Migration change set FAILED! ${this.changeSetToString(changeSet)}`, error)
    }

    private logChangeSetTransactionSuccess(changeSet: FileQualifiedChangeSet<RootNodeType>) {
        this.logger.debug(`Migration changeset SUCCESS! ${this.changeSetToString(changeSet)}`)
    }

    private logMigrateOrSkipInfo(changeSet: FileQualifiedChangeSet<RootNodeType>, alreadyRun: boolean) {
        this.logger.debug(`${alreadyRun ? 'SKIP' : 'RUN'} Migration change set: ${this.changeSetToString(changeSet)}`)
    }

    private changeSetToString({changeSetFileId, id, author}: FileQualifiedChangeSet<RootNodeType>) {
        return `file: ${changeSetFileId}, id: ${id}, author: ${author}`
    }
}

function now() {
    return formatISO(new Date())
}