import {changeSet} from './peachyChangeSet'

export const changeSets = [
    changeSet({id: '001-add-subscription-start-date', author: 'dan.parker'}, async tx => {
        
        const existingSubscription = await tx.subscription()
        
        if (!existingSubscription) {
            // repo must have been created before we started mapping the subscription on repo creation
            // all these repos would have had a subscription start date on the same date as their policy, so...
            
            const policyStartDate = await tx.policy.startDate()
            await tx.subscription({startDate: policyStartDate})
        }
    })
]
