import {PlanService} from './PlanService'
import {IPeachyClient} from './IPeachyClient'
import {AccountTypes, DomainMappings, Policy} from '@peachy/repo-domain'
import {
    ManagedLifeRepository,
    NonManagedLifeRepository,
    PlanRepository,
    PolicyRepository
} from '@peachy/flash-repo-peachy-client'
import {isAfter, startOfDay} from 'date-fns'

// should rethink how much we're rebuilding everytime we call these methods.  probably want it to listen for changes at the repo root and only rebuild on change
// then all consuming components can just subscribe to the main policy?
export class PolicyService {

    constructor(protected readonly policyRepository: PolicyRepository,
                protected readonly planService: PlanService,
                protected readonly nonManagedLifeRepository: NonManagedLifeRepository,
                protected readonly planRepository: PlanRepository,
                protected readonly managedLifeRepository: ManagedLifeRepository,
                protected readonly peachyClient: IPeachyClient) { }

    async getPolicy() {
        const repoPolicy = await this.policyRepository.getPolicy()
        const nonManagedLives = await this.nonManagedLifeRepository.list()
        return new Policy(
            repoPolicy.id,
            repoPolicy.accountType ?? AccountTypes.INDIVIDUAL,
            DomainMappings.fromRepo.toDate(repoPolicy.startDate),
            repoPolicy.status,
            DomainMappings.fromRepo.toLives(nonManagedLives),
            await this.planService.getPlans(),
            repoPolicy.owner
        )
    }

    async getPolicyDocUrl() {
        const {ticketId} = await this.peachyClient.stagePolicyDocGen()
        return this.peachyClient.buildFetchStagedDocUrl(ticketId)
    }

    async getCoverStartedDetails() {
        const policy = await this.policyRepository.getPolicy()
        const startDate = startOfDay(DomainMappings.fromRepo.toDate(policy.startDate))
        const now = new Date()
        const started = isAfter(now, startDate)

        return {
            started,
            startDate
        }
    }

}
