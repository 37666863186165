import LRUMap from 'mnemonist/lru-map'
import {IPeachyClient} from './IPeachyClient'
import {NhsOrganisation, NhsOrganisationLookupResult} from '@peachy/repo-domain'

const cache = new LRUMap<string, any[]>(4)

export class NhsService {

    constructor(protected readonly peachyClient: IPeachyClient) {

    }

    async lookupGps(searchTerm: string, postcode: string): Promise<NhsOrganisationLookupResult> {
        try {
            const cacheKey = searchTerm + '|' + postcode
            const response = cache.get(cacheKey) || await this.peachyClient.searchGps(searchTerm, postcode)
            cache.set(cacheKey, response)
            return new NhsOrganisationLookupResult(searchTerm, response.value?.map(toNhsOrganisation) ?? [])
        } catch (error) {
            console.error(error)
            return new NhsOrganisationLookupResult(searchTerm, [])
        }
    }

}

const toNhsOrganisation = (searchResult: any) => new NhsOrganisation(
    searchResult.OrganisationID,
    searchResult.OrganisationName,
    searchResult.Address1,
    searchResult.Address2,
    searchResult.Address3,
    searchResult.City,
    searchResult.County,
    searchResult.Postcode,
    searchResult.Latitude,
    searchResult.Longitude,
)

