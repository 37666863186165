// noinspection JSNonASCIINames

import {Flash, Flashable, FlashPath, FlashProp, FlashValue, HardHash, Hash, isHardHash} from './flash-repo-domain'

import {isArray, isDateObject, isObject, isString, last} from '@peachy/utility-kit-pure'
import {IFlashStore} from './FlashStore'
import {PNodePath} from './path-builder/path-builder-domain'
import equal from 'fast-deep-equal'
import {PNodeHandler} from './path-builder/PNodeProxy'
import {FlashStoreScope} from './FlashStoreScope'

const DEBUG = false

export default class FlashPathHandler implements PNodeHandler {

    private debug = false
    constructor(private flashStore: IFlashStore, private rootHash: Hash, private commitNewRootHash?: (newRootHash: Hash, oldRootHash: Hash) => Promise<void>) {
    }

    public getRootHash() {
        return this.rootHash
    }

    setRootHash(newRootHash: Hash): boolean {
        if (this.rootHash === newRootHash) {
            return false
        } else {
            this.rootHash = newRootHash
            return true
        }
    }

    public getName() {
        return this.flashStore.getDbName()
    }

    async hardGet(path: PNodePath): Promise<any> {
        const flashValue: FlashValue = await this.flashStore.getPropValue(path, this.rootHash)
        return this.flashStore.build(flashValue)
    }

    async softGet(path: PNodePath): Promise<unknown> {
        const flashValue: FlashValue = await this.flashStore.getPropValue(path, this.rootHash)
        if (isHardHash(flashValue)) {
            return this.flashStore.get(flashValue.toString())
        } else {
            return flashValue
        }
    }

    async set(path: PNodePath, newValue: any): Promise<boolean> {

        debug('[handler-set start]')

        const flashPath: FlashPath = [...path]

        const flashValuePath: FlashValue[] = []

        const thisRootHash = this.rootHash

        let currentValue: FlashValue = new HardHash(thisRootHash)
        let replacementValue: Flashable = newValue

        await this.flashStore.followPath(flashPath, thisRootHash, (
                prop,
                value) => {
                flashValuePath.push(currentValue)
                currentValue = value
            }
        )

        if (flashValuePath.length != flashPath.length) {

            console.error('VETO invalid path', {path, flashValuePath, flashPath})


            throw ('VETO invalid path')

        } else {

            if (equal(currentValue, newValue)) {
                return false
            }

            if (flashValuePath.length) {
                const hashesToReplace: HardHash[] = flashValuePath.reverse() as HardHash[]
                for (const hashToReplace of hashesToReplace) {

                    const flashToReplace = await this.flashStore.get(hashToReplace.toString())

                    const propToUpdate = flashPath.pop()
                    replacementValue = getReplacementFlash(flashToReplace, propToUpdate, replacementValue)

                }
            }

            const replacementRootHash = await this.flashStore.put(replacementValue)

            if (thisRootHash != this.rootHash) {

                console.error('VETO - stale update')
                throw 'VETO - stale update'
            }

            if (replacementRootHash !== thisRootHash) {
                this.rootHash = replacementRootHash

                await this.commitNewRootHash?.(replacementRootHash, thisRootHash)

                debug(`[handler-set end] (contentRootHash: ${this.rootHash}) --> handler`)

                return true
            } else {
                debug('[handler-set end] ()')

                return false
            }
        }
    }


    async transact(path: PNodePath, transaction: (h: any) => Promise<void>): Promise<boolean> {

        const scope = new FlashStoreScope(this.flashStore)

        const propPath = await this.flashStore.gatherPropPath(path, this.rootHash)
        const nodeHash = last(propPath)
        const scopedPathHandler = new FlashPathHandler(scope, nodeHash.toString())

        debug('run tranx')

        await transaction(scopedPathHandler)

        debug('ran tranx')


        const newHash = await scope.pushScope(scopedPathHandler.rootHash)
        const newValue = await scope.get(newHash)

        debug('commited tranx?')

        return this.set(path, newValue)
    }

}

function debug(message?: any, ...optionalParams: any[]) {
    if (DEBUG) {
        console.log(message, ...optionalParams)
    }
}


function getReplacementFlash(flash: Flash, prop: FlashProp, newValue: any): Flash {
    let updated: any

    if (isString(flash) || isDateObject(flash)) {
        updated = newValue
    } else if (isArray(flash)) {
        if (flash[prop as number] !== newValue) {
            updated = [...flash]
            updated[prop] = newValue
        } else {
            updated = flash
        }
    } else if (isObject(flash)) {
        if (flash[prop] !== newValue) {
            updated = {...flash}
            updated[prop] = newValue
        } else {
            updated = flash
        }
    }

    return updated
}



//
// type Batch = (FlashPath | PNodeQueryImpl<any>)
//
// function extractBatches(path: PNodePath) {
//     const batches: Batch[]  = []
//
//     let currentBatch: FlashPath = []
//
//     for (let prop of path) {
//         if (isQuery(prop)) {
//             if (currentBatch.length) {
//                 batches.push(currentBatch)
//                 currentBatch = []
//             }
//             batches.push(prop)
//         } else {
//             currentBatch.push(prop)
//         }
//     }
//
//     if (currentBatch.length) {
//         batches.push(currentBatch)
//     }
//     return batches
// }
//
// function isQuery(x: any): x is PNodeQuery<any> {
//     return x instanceof PNodeQueryImpl
// }
//
