import {Bookmark, BranchId, Flashlet, Hash} from './flash-repo-domain'


export interface IFlashDb {

    getName(): string

    dispose(): Promise<void>

    hasHash(hash: Hash): Promise<boolean>

    getBookmark(): Promise<Bookmark>;

    getFlashlets(hash: Hash): Promise<Flashlet[]>

    getSlice(since: Bookmark): Promise<Flashlet[]>;

    putSlice(flashes: Flashlet[]): Promise<Bookmark>;

    getBranchHistory(branchId: BranchId): Promise<Hash[]>;

    getBranchRootHash(branchId: BranchId): Promise<Hash>;

    getBranchIds(): Promise<BranchId[]>

    pushBranchRootHash(branchId: BranchId, newRoot: Hash, oldRoot?: Hash): Promise<boolean>
}
