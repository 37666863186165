import {FlashRepo, PNode, SyncContext} from '@peachy/flash-repo-pure'
import {useApplicationContext} from '../../../controllers/CustomerApplicationContextProvider'
import {createContext, createResource, createSignal, ParentProps, Show, useContext} from 'solid-js'

type RepoContext = {

    root: PNode<any>
    repo: FlashRepo
    sync: () => Promise<SyncContext>

    isSynchronised: () => boolean
}


const RepoContextKey = createContext<RepoContext>()


export function RepoController(props: ParentProps) {

    const [isSynchronised, setIsSynchronised] = createSignal(false)

    const [repoContext, trigger] = createResource<RepoContext>(async () => {

        const appContext = useApplicationContext()

        const repo = appContext.getBean('peachyFlashRepo')
        const remote = appContext.getBean('remoteRepoAdapter')

        const root = await repo.getContentRoot()
        root.$(v => {
            setIsSynchronised(repo.isSynchronised())
        })

        const sync = async () => {
            const result = await repo.sync(remote)
            setIsSynchronised(repo.isSynchronised())
            return result
        }
        return {
            root, repo, sync, isSynchronised
        }
    })

    return (
        <Show when={repoContext.latest}>
            <RepoContextKey.Provider value={repoContext()}>
                {props.children}
            </RepoContextKey.Provider>
        </Show>
    )
}

export function useRepoContext() {
    return useContext(RepoContextKey)
}
