import {Page} from '../primatives/page-primatives'
import {RepoHash} from '../primatives/hash-primatives'
import {Observable} from 'rxjs'
import {InstallationId} from '../primatives/repo-primatives'
import {Bookmark} from '@peachy/utility-kit-pure'

export type PageInfo = {
    repoHash: RepoHash
    page: Page | null
}

export type PageReceipt = PageInfo & {
    bookmark: Bookmark | null
}


export type PageHashStream = Observable<RepoHash>
export type PageInfoStream = Observable<PageInfo>
export type PageReceiptStream = Observable<PageReceipt>


export type BookmarkedPage = {
    bookmark: Bookmark,
    repoHash: RepoHash
}



export type PageSyncState = {
    localBookmark: Bookmark,
    remoteBookmark: Bookmark,
}


export interface IPageIo {

    commitPageInfoStream(
        pageInfo$: Observable<PageInfo>,
        skipExistenceCheck?: boolean
    ): Observable<PageReceipt>

    filterForUnknownPages(pageInfo$: Observable<PageInfo>): Observable<PageInfo>
    filterForUnknownPageHashes(repoHash$: Observable<RepoHash>): Observable<RepoHash>

    fetchPageInfoStream(
        repoHash$: Observable<RepoHash>,
    ): Observable<PageInfo>

    fetchPageKeysSince(bookmark?: Bookmark): Observable<BookmarkedPage>
    fetchBookmark(): Promise<Bookmark>
    fetchPage(pageHash: RepoHash): Promise<PageInfo>
    commitPage(page: Page): Promise<PageReceipt>

    fetchPageSyncState(remoteInstallationId: InstallationId): Promise<PageSyncState>
    commitPageSyncState(remoteInstallationId: InstallationId, pageSyncState: PageSyncState, expectedState?: PageSyncState): Promise<PageSyncState>
}
