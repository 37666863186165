import {isValid} from 'date-fns'
import {v105_ota_6_aware_RepoClaimActivity} from './2023-07-05-set-plan-year-on-approved-claim-activities'
import {changeSet} from './peachyChangeSet'
import {
    BenefitType,
    ClaimStages,
    RepoClaimActivitySubmissionReason,
    RepoClaimInvoiceLineItem,
    RepoCoverCheckRequestedTreatment,
    RepoHospitalAdmission,
    RepoTerminologyItem
} from '@peachy/repo-domain'
import {newUUID} from '@peachy/utility-kit-pure'
import {isFinite} from 'lodash-es'

type OldDecision = v105_ota_6_aware_RepoClaimActivity['decision']
type OldAssessment = OldDecision['assessment']

export const changeSets = [
    changeSet({id: '001-migrate-decision-assessment', author: 'dan.parker'}, async tx => {

        const claimActivities = await tx.claimActivities() as unknown as v105_ota_6_aware_RepoClaimActivity[]

        const allBenefits = (await tx.plans()).flatMap(it => it.benefits)
        const benefitTypeOf = (benefitId: string) => allBenefits.find(it => it.id === benefitId)?.type

        let mutatedAny = false
        for (const activity of claimActivities) {

            const oldDecision = activity.decision
            const oldAssessment = oldDecision?.assessment
            const newAssessment = activity.assessment

            if (!!oldAssessment && !newAssessment) {

                const isClaim = activity.stage === ClaimStages.CLAIM
                const isCovercheck = activity.stage === ClaimStages.COVER_CHECK
                const benefitType = benefitTypeOf(activity.benefitId)

                const submissionReasons = migrateSubmissionReasonsFrom(oldAssessment)
                const hospitalAdmissions = isClaim ? migrateHospitalAdmissionsFrom(oldAssessment) : []
                const invoiceLineItems = isClaim ? migrateInvoiceLineItemsFrom(oldDecision, benefitType, submissionReasons[0], hospitalAdmissions[0]) : []
                const requestedTreatments = isCovercheck ? migrateRequestedTreatmentsFrom(oldDecision, benefitType, submissionReasons[0]) : []

                activity.assessment = {
                    referralDate: oldAssessment?.referalDate,
                    submissionReasons,
                    hospitalAdmissions,
                    invoiceLineItems,
                    linkedClaimActivityIds: [],
                    requestedTreatments
                }

                if (!activity.decision.approvedCosts) {
                    activity.decision.approvedCosts = isFinite(oldDecision.amountInPence) ? [{
                        planYearId: oldAssessment.planYearId,
                        benefitType,
                        amountInPence: oldDecision.amountInPence
                    }] : []
                }

                mutatedAny = true
            }
        }

        if (mutatedAny) {
            await tx.claimActivities(claimActivities)
        }

    })

]

function migrateSubmissionReasonsFrom(oldAssessment: OldAssessment) {
    const reason: RepoClaimActivitySubmissionReason = {
        id: newUUID(),
        symptoms: [missingSymptom()],
        disorder: undefined,
        onsetDate: oldAssessment.symptomsOnsetDate
    }
    return [reason]
}


function migrateHospitalAdmissionsFrom(oldAssessment: OldAssessment) {
    const candidate: RepoHospitalAdmission = {
        id: newUUID(),
        admissionDate: isValid(oldAssessment.admissionDate) ? oldAssessment.admissionDate : undefined,
        dischargeDate: isValid(oldAssessment.dischargeDate) ? oldAssessment.dischargeDate : undefined
    }
    return candidate.admissionDate || candidate.dischargeDate ? [candidate] : []
}

function migrateInvoiceLineItemsFrom(oldDecision: OldDecision, benefitType: BenefitType, submissionReason: RepoClaimActivitySubmissionReason, hospitalAdmission?: RepoHospitalAdmission) {
    const oldAssessment = oldDecision.assessment
    const item: RepoClaimInvoiceLineItem = {
        id: newUUID(),
        procedure: missingProcedure(),
        treatmentDate: oldAssessment.treatmentDate,
        treatmentPaymentDate: oldAssessment.treatmentPaymentDate,
        benefitType,
        planYearId: oldAssessment.planYearId,
        hospitalAdmissionId: hospitalAdmission?.id,
        invoiceAmountInPence: undefined,
        eligibleAmountInPence: oldDecision.amountInPence,
        reasonId: submissionReason.id
    }
    return [item]
}

function migrateRequestedTreatmentsFrom(oldDecision: OldDecision, benefitType: BenefitType, submissionReason: RepoClaimActivitySubmissionReason) {
    const item: RepoCoverCheckRequestedTreatment = {
        id: newUUID(),
        procedure: missingProcedure(),
        reasonId: submissionReason.id,
        benefitType,
        approved: oldDecision.type === 'APPROVE'
    }
    return [item]
}

function missingSymptom(): RepoTerminologyItem  {
    return {
        code: 'missing_symptom',
        display: 'Missing Symptom',
        system: 'https://peachy.health/temp_terminology'
    }
}

function missingProcedure(): RepoTerminologyItem  {
    return {
        code: 'missing_procedure',
        display: 'Missing Procedure',
        system: 'https://peachy.health/temp_terminology'
    }
}
