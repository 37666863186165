import {ParentProps, Show} from 'solid-js'
import {MainNav} from './MainNav/MainNav'
import {useNav} from './Dashboard/Dashboard'
import styles from './MainPane/MainPane.module.css'

export function Main(props: ParentProps) {
    const nav = useNav()

    return (<>
        <Show when={nav.shown()}>
            <MainNav/>
        </Show>
        <main class={styles.MainPane}>
            {props.children}
        </main>
    </>)

}
