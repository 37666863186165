import {changeSet} from './peachyChangeSet'
import {Media, QuestionAnswer} from '@peachy/repo-domain'
import {Draft} from '@peachy/utility-kit-pure'

export const changeSets = [
    changeSet({id: '001-add-assumed-complete-claim-activity-media-state', author: 'dan.parker'}, async tx => {

        const activities = await tx.claimActivities()
        const enquiries = await tx.enquiries()
        let mutatedAny = false
        for (const activity of activities) {
            if (!activity.media) {
                const enquiry = enquiries[activity.enquiryId]
                activity.media = {}
                enquiry.questions.forEach(q => {
                    if (q.required) {
                        q.answers.forEach((a, i) => {
                            if (isMedia(a)) {
                                mutatedAny = true
                                activity.media[`${q.id}_${i + 1}${a.extension}`] = {uploadState: 'COMPLETE'}
                            }
                        })
                    }
                })
            }
        }

        if (mutatedAny) {
            await tx.claimActivities(activities)
        }
    })
]

function isMedia(answer: Draft<QuestionAnswer>): answer is Draft<Media> {
    return !!(answer as Draft<Media>).uri
}