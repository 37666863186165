import 'reflect-metadata'
import {Plan} from './Plan'
import {Life} from './Life'
import {DomainMappings} from '../mappings/DomainMappings'
import {AccountType, AccountTypes, BenefitType, PolicyStatus, PolicyStatuses} from './types'
import {groupDistinctBy} from '@peachy/utility-kit-pure'
import {Type} from 'class-transformer'

export class Policy {

    @Type(() => Plan)
    readonly plans: Plan[]
    @Type(() => Date)
    readonly startDate: Date
    @Type(() => Life)
    readonly nonManagedLives: Life[]

    constructor(
        readonly id: string,
        readonly accountType: AccountType,
        startDate: Date,
        readonly status: PolicyStatus,
        nonManagedLives: Life[],
        plans: Plan[],
        readonly owner: {id: string, awsSub: string}
    ) {
        this.plans = plans
        this.startDate = startDate
        this.nonManagedLives = nonManagedLives
    }

    get plansByLifeId() {
        return groupDistinctBy(this.plans, it => it.life.id)
    }

    get managedLives() {
        return this.plans.map(it => it.life) as Life[]
    }
    
    get activeManagedLives() {
        return this.managedLives.filter(it => !it.isCancelled)
    }

    getManagedLivesThatHaveActiveBenefit(benefitType: BenefitType) {
        return this.plans.filter(it => it.currentPlanYear?.hasActiveBenefit(benefitType)).map(it => it.life)
    }

    get managedLivesThatAreAllowedToCheckCover() {
        return this.plans.filter(it => it.currentPlanYear?.hasAnyCoverCheckableBenefits()).map(it => it.life)
    }
    
    get managedLivesThatAreAllowedToMakeClaims() {
        return this.plans.filter(it => it.currentPlanYear?.hasAnyClaimableBenefits()).map(it => it.life)
    }

    get dependentLives() {
        return this.managedLives.filter(it => !it.isPrimary)
    }

    getPlanByLifeId(lifeId: string) {
        return this.plansByLifeId[lifeId]
    }

    getPlanByLife(life: Life) {
        return this.getPlanByLifeId(life.id)
    }

    get primaryLife() {
        return this.managedLives.find(it => it.isPrimary)
    }

    getManagedLife(lifeId: string) {
        return this.managedLives.find(it => it.id === lifeId)
    }

    get primaryLifePlan() {
        return this.getPlanByLife(this.primaryLife)
    }

    get allLives() {
        return [...this.managedLives, ...this.nonManagedLives]
    }

    get allNonPrimaryLives() {
        return this.allLives.filter(it => !it.isPrimary)
    }

    get referenceNumber() {
        return DomainMappings.peachifyUuid(this.id)
    }
    
    get longReferenceNumber() {
        return DomainMappings.peachifyUuid(this.id, 7)
    }

    get isActive() {
        return this.status === PolicyStatuses.ACTIVE
    }

    get isIndividual() {
        return this.accountType === AccountTypes.INDIVIDUAL
    }
    
    get isSme() {
        return this.accountType === AccountTypes.COMPANY
    }
}