import {RemoteRepoAdapter, SyncRequest, SyncResponse} from '@peachy/flash-repo-pure'
import {IPeachyClient} from '@peachy/service'

export class AdminAuthenticatedRemoteFlashRepoAdapter implements RemoteRepoAdapter {

    constructor(readonly peachyClient: IPeachyClient, readonly remoteRepoId: string, readonly sub: string) {
    }

    async syncRepo(syncRequest: SyncRequest): Promise<SyncResponse> {
        return this.peachyClient.adminSyncRemoteRepo(this.sub, syncRequest)
    }
}
