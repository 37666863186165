import {PeachyMapRepository} from './abstract/PeachyMapRepository'
import {RepoLife} from '@peachy/repo-domain'
import {PeachyFlashRepo} from '../types'

export class NonManagedLifeRepository extends PeachyMapRepository<RepoLife>{

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.nonManagedLives)
    }

}