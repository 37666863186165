import {PreferencesRepository} from '@peachy/flash-repo-peachy-client'
import {RepoPreferences} from '@peachy/repo-domain'

export class PreferencesService {

    constructor(protected readonly preferencesRepository: PreferencesRepository) { }

    async getPreferences() {
        return this.preferencesRepository.getPreferences()
    }

    async updatePreferences(preferences: RepoPreferences) {
        return this.preferencesRepository.updatePreferences(preferences)
    }
}