import {Card} from '../components/Card/Card'
import {createSignal, Match, Signal, Switch} from 'solid-js'
import {SignIn} from './steps/SignIn'
import {Spinner} from '../components/Spinner/Spinner'
import {CompleteNewPassword} from './steps/CompleteNewPassword'
import styles from './IamModal.module.css'
import {ResetPassword} from './steps/ResetPassword'
import {useIamService} from '../controllers/DashboardServiceController'
import {NOOP} from '@peachy/utility-kit-pure'
import {Modal} from '@peachy/client-kit'


export type IamProps = {
    email: Signal<string>
}


export function IamModal() {

    const iamService = useIamService()

    const emailSignal = createSignal('')

    return (<>
        <Modal isOpen={!iamService.isInState('SignedIn')} onDismiss={NOOP}>
            <Card class={styles.IamModal}>
                <Switch>
                    <Match when={iamService.isInState('Anon')}>
                        <SignIn email={emailSignal}/>
                    </Match>
                    <Match when={iamService.isInState('NewPasswordRequired')}>
                        <CompleteNewPassword/>
                    </Match>
                    <Match when={iamService.isInState('PasswordResetRequested')}>
                        <ResetPassword email={emailSignal}/>
                    </Match>
                </Switch>
            </Card>
        </Modal>
        <Spinner isOpen={iamService.isBusy()} onDismiss={NOOP}/>
    </>
    )
}