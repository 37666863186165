import isEmail from 'validator/es/lib/isEmail'

export function isValidEmail(email: string): boolean {
    return isEmail(email)
}

export function isValidPassword(password: string): boolean {
    return !!password
}


