import 'reflect-metadata'
import {Type} from 'class-transformer'
import {Address} from './Address'
import {RegisteredGp} from './RegisteredGp'
import {Gender, LifeType} from './types'
import {IsoDatePartOnly, joinWhereDefined, Optional} from '@peachy/utility-kit-pure'
import {differenceInYears, parseISO} from 'date-fns'
import {BankDetails} from './BankDetails'

export type LifeId = string

export class Life {

    @Type(() => Address)
    readonly address: Address | undefined
    @Type(() => RegisteredGp)
    readonly registeredGp: RegisteredGp
    @Type(() => BankDetails)
    readonly bankAccount: BankDetails

    constructor(
        readonly id: LifeId,
        readonly awsSub: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: Optional<string>,
        readonly phoneNumber: Optional<string>,
        readonly dateOfBirth: IsoDatePartOnly,
        readonly gender: Optional<Gender>,
        readonly onboarded: boolean,
        readonly type: LifeType,
        readonly isPolicyOwner: boolean,
        readonly isPrimary: boolean,
        readonly dateCancelled: Optional<Date>,
        address: Optional<Address>,
        registeredGp: RegisteredGp,
        readonly vgpUserId: Optional<string>,
        bankAccount: Optional<BankDetails>) {

            this.address = address
            this.registeredGp = registeredGp
            this.bankAccount = bankAccount
    }

    get fullName() {
        return joinWhereDefined(' ', this.firstName, this.lastName)
    }

    get initials() {
        return this.firstName[0] + this.lastName[0]
    }

    get ageInWholeYears() {
        const dob = parseISO(this.dateOfBirth)
        const now = new Date()
        return differenceInYears(now, dob)
    }

    get isCancelled() {
        return !!this.dateCancelled
    }

    is(otherLife?: Life) {
        return this.id === otherLife?.id
    }
}
