import {createSignal} from 'solid-js'
import {TextBox} from '../../components/TextBox/TextBox'
import {PasswordBox} from '../../components/TextBox/PasswordBox'
import {IamError} from '../components/IamError'
import {IamProps} from '../IamModal'
import {isValidEmail, isValidPassword} from '../../to-abstract/validate'
import {useIamService} from '../../controllers/DashboardServiceController'


export function SignIn(props: IamProps) {
    const iamService = useIamService()

    const [email, setEmail] = props.email

    const [password, setPassword] = createSignal('')

    const hasCredentials = () => isValidEmail(email()) && isValidPassword(password())

    const signIn = () => {
        if (hasCredentials()) iamService.signIn(email(), password())
    }

    return <>
        <h3>Sign In</h3>
        <IamError/>
        <TextBox value={email()} onChange={setEmail} onEnter={signIn} placeholder={'email address'}/>
        <PasswordBox value={password()} onChange={setPassword} onEnter={signIn}/>
        <button onClick={signIn} disabled={!hasCredentials()}>Sign in</button>
        <a onClick={iamService.requestPasswordReset}>Forgotten password? &rarr; </a>
    </>
}


