import {render} from 'solid-js/web'

import './global-style/index.css'
import {App} from './App'
import smoothScroll from 'smoothscroll-polyfill'

smoothScroll.polyfill()

render(
    () => <App/>,
    document.getElementById('app-root')
)
