import {MediaType, MediaTypes, QuestionAnswer} from './types'

export class Media {

    readonly extension: string

    static fromType(type: MediaType, uri: string) {
        return new Media(uri, type)
    }

    // should tidy this really naive implementation
    static fromBestGuess(uri: string, thumbnailUrl?: string) {
        const cleanedFileName = uri?.trim()?.toLowerCase() ?? ''
        if (cleanedFileName.endsWith('.mov')) {
            return new Media(uri, MediaTypes['video/quicktime'])
        }
        if (cleanedFileName.endsWith('.mp4')) {
            return new Media(uri, MediaTypes['video/mp4'])
        }
        // ¯\_(ツ)_/¯ go with image. I told you it was naive!
        return new Media(uri, MediaTypes['image/jpeg'], thumbnailUrl)
    }

    private constructor(readonly uri: string, readonly type: MediaType, readonly thumbnailUrl?: string) {
        this.extension = uri.slice(uri.lastIndexOf('.'))
    }

    isVideo() {
        return this.shortType === 'video'
    }

    isImage() {
        return this.shortType === 'image'
    }

    get shortType() {
        return this.type.substring(0, this.type.indexOf('/')) as 'image' | 'video'
    }

    toString() {
        return this.uri
    }
}

// @ts-ignore
// should work out why instanceof Media isn't working?
export const answerIsMedia = (answer: QuestionAnswer) => !!answer?.uri