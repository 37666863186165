
export type PeachyLogoProps = {
    class: string
}


export function PeachyLogo(props) {
    return <svg class={props.class} viewBox="0 0 785 124" xmlns="http://www.w3.org/2000/svg">
        <path shape-rendering="geometricPrecision" d="M73.3128 3.93948H0V120.623H50.2502V93.7973H73.3128C103.313 93.7973 127.501 81.416 127.501 49.525C127.501 16.1331 103.5 3.93948 73.3128 3.93948ZM70.3128 58.5295L50.2502 58.3419V41.4584L70.3128 41.2708C78.3753 41.2708 81.0003 45.3979 81.0003 50.0877C81.0003 54.59 78.3753 58.5295 70.3128 58.5295Z"/>
        <path shape-rendering="geometricPrecision" d="M181.835 86.1059V75.0378H223.647V48.2118H181.835V36.9561H243.522V3.93948H132.522V120.623H225.04L240.576 86.1059H181.835Z"/>
        <path shape-rendering="geometricPrecision" d="M337.415 120.623H386.54L334.227 3.93948H282.852L230.352 120.623H279.665L285.102 105.991H331.977L337.415 120.623ZM296.352 76.1634L308.54 43.8971L320.727 76.1634H296.352Z"/>
        <path shape-rendering="geometricPrecision" d="M440.228 124C479.416 124 500.603 104.49 506.603 78.6021L456.916 70.348C455.228 78.7897 450.165 85.5431 439.29 85.5431C426.165 85.5431 421.103 75.9758 421.103 62.0938C421.103 48.9622 425.79 38.6445 439.29 38.6445C449.603 38.6445 454.29 44.8351 456.353 51.9637L506.791 46.3359C500.978 20.4478 479.603 0 440.228 0C393.165 0 371.978 28.5144 371.978 62.0938C371.978 96.236 393.165 124 440.228 124Z"/>
        <path shape-rendering="geometricPrecision" d="M590.573 3.93948V45.3979H561.323V3.93948H511.072V120.623H561.323V81.0408H590.573V120.623H640.823V3.93948H590.573Z"/>
        <path shape-rendering="geometricPrecision" d="M728.937 3.93948L706.812 46.5235L684.687 3.93948H628.812L680.937 82.7292V120.623H732.5V83.1044L785 3.93948H728.937Z"/>
    </svg>
}
