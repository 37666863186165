import g from '../../../src/global-style/peachy-library.module.css'
import {createContext, createSignal, JSX, ParentProps, useContext} from 'solid-js'
import {Modal} from '@peachy/client-kit'

type ModalProps = ParentProps<{
    trigger?: JSX.Element | string
    triggerEnabled?: () => boolean
    onClose?: () => void
    initOpen?: boolean
}>

const ModalContext = createContext<ModalControlApi>()

type ModalControlApi = {
    open(): void
    close(): void
}

export function useModalControl() {
    return useContext(ModalContext)
}

export function ModalOpener(props: ModalProps) {

    const [isOpen, setIsOpen] = createSignal(props.initOpen)

    const close = () => {
        setIsOpen(false)
        props.onClose?.()
    }

    const open = () => {
        setIsOpen(true)
    }

    const onTrigger = () => {
        (!props.triggerEnabled || props.triggerEnabled()) && open()
    }

    return (
        <ModalContext.Provider value={{open, close}}>
            <span class={g.clickable} onClick={onTrigger}>
                {props.trigger}
            </span>
            <Modal isOpen={isOpen()} onDismiss={close} blurBackground>
                <div>{props.children}</div>
            </Modal>
        </ModalContext.Provider>
    )
}
