import {isArray, isObject, isString, isUndefined, keys} from '@peachy/utility-kit-pure'
import {PageInfo, PageInfoStream} from '../../key-interfaces/IPageIo'
import {Observable, Subscriber} from 'rxjs'
import {isRepoHash, RepoHash} from '../../primatives/hash-primatives'
import {canBePaged, Page, PageValue} from '../../primatives/page-primatives'

export function mapToPageInfoStream(node: any): PageInfoStream {
    return new Observable<PageInfo>(subscriber => {
        try {
            recur(node, subscriber)
            subscriber.complete()
        } catch (e) {
            console.log('mapToPageStream ERROR', e)
            subscriber.error(e)
        }
    })
}


function recur(node: unknown, subscriber: Subscriber<PageInfo>): RepoHash {

    let page: Page = null
    const mapper = (x: any) => canBePaged(x) ? recur(x, subscriber) : x

    if (isRepoHash(node)) {
        return node
    }

    if (isArray(node)) {
        page = node.map(mapper)
    } else if (isObject(node)) {
        page = mapObject(node, mapper) as Page
    }

    if (page) {
        pruneUndefined(page)
    } else if (isString(node)) {
        page = node
    }

    if (page) {
        const pageInfo: PageInfo = {
            repoHash: RepoHash.from(page),
            page,
        }
        subscriber.next(pageInfo)
        return pageInfo.repoHash
    } else {
        return undefined
    }
}



function pruneUndefined(x: unknown): void {
    if (isObject(x)) {
        Object.keys(x).forEach(k => isUndefined(x[k]) && (delete x[k]))
    } else if (isArray(x)) {
        x.forEach((e, i) => isUndefined(e) && (x[i] = null))
    }
}

function mapObject<T extends object>(x: T, mapper: (c: unknown) => PageValue): Page {
    const mapped: any = {}
    keys(x).forEach(k => {
        mapped[k] = mapper(x[k])
    })
    return mapped
}
