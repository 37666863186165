import {AscentiAppointment, AscentiInjury} from '@peachy/ascenti-client'
import {IPeachyClient} from './IPeachyClient'

export class AscentiService {

    constructor(protected readonly peachyClient: IPeachyClient) {}

    async searchAppointments(clinicId: number, therapistId?: number) {
        return this.peachyClient.getAscentiAppointmentAvailability(clinicId, therapistId)
    }

    holdAppointment(ascentiAppointment: AscentiAppointment)  {
        return this.peachyClient.holdAscentiAppointment(
            ascentiAppointment.Clinic.ClinicID,
            ascentiAppointment.Therapist.TherapistID,
            ascentiAppointment.DateTime,
            ascentiAppointment.Length
        )
    }

    bookHeldAppointment(ascentiAppointmentId: number, whoForId: string, acceptTerms: boolean, injury: AscentiInjury) {
        return this.peachyClient.bookHeldAscentiAppointment(ascentiAppointmentId, whoForId, acceptTerms, injury)
    }

    cancelBooking(ascentiAppointmentId: number, cancellationToken: string, caseReference: string, caseId: number) {
        return this.peachyClient.cancelAscentiAppointment(ascentiAppointmentId, cancellationToken, caseReference, caseId)
    }

}
