import {Api, defineApi, getOf} from '@peachy/core-domain-pure'
import {DashboardGroups} from '@peachy/web-dashboard-pure'

class GetMediaUrisRequestParams {
    claimId: string
    sub: string
}

export const ClaimsActivityApiDefinition = defineApi('LambdaClaimsActivityApi')
    .forGroups(DashboardGroups)
    .withActions({
        adminGetMediaUris: getOf(GetMediaUrisRequestParams).fromGroups('AdminUsers').willReturn(Array<string>),
    })

export type ClaimsActivityApi = Api<typeof ClaimsActivityApiDefinition>
