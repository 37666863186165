import {AppointmentTypes, BenefitType, BenefitTypes, ClaimStages, DecisionTypes} from './types'
import {Benefit} from './Benefit'
import {ClaimActivity} from './ClaimActivity'
import {Decision} from './Decision'
import {Policy} from './Policy'
import {Appointment} from './Appointment'
import {has} from 'lodash-es'
import {PlanYear} from './Plan'
import {format} from 'date-fns'

const benefitTypeToText = {
    [BenefitTypes.DENTAL]: 'Dental',
    [BenefitTypes.OPTICAL]: 'Optical',
    [BenefitTypes.THERAPIES]: 'Therapies',
    [BenefitTypes.MENTAL_HEALTH_IN_PATIENT]: 'Mental health (in patient)',
    [BenefitTypes.MENTAL_HEALTH_OUT_PATIENT]: 'Mental health (out patient)',
    [BenefitTypes.CONSULTATIONS_AND_DIAGNOSTICS]: 'Consultations & diagnostics',
    [BenefitTypes.HOSPITAL_CARE]: 'Hospital care'
}
export function prettyPrintBenefit(benefit: Pick<Benefit, 'type'> | BenefitType) {
    const type = has(benefit, 'type') ? (benefit as Benefit).type : benefit as BenefitType
    return benefitTypeToText[type]
}

const decisionToText = {
    [DecisionTypes.APPROVE]: 'Approved',
    [DecisionTypes.DECLINE]: 'Declined',
}
export function prettyPrintDecision(decision?: Pick<Decision, 'type'>) {
    return decision?.type ? decisionToText[decision.type] : 'Submitted'
}

export function decisionToColorKey(decision?: Decision) {
    return decision?.type.toLowerCase() ?? 'pending'
}

export function policyStatusToColorKey(policy: Policy) {
    return policy.status.toLowerCase()
}

const claimStageToText = {
    [ClaimStages.COVER_CHECK]: 'Cover Check',
    [ClaimStages.CLAIM]: 'Claim',
}
export function prettyPrintClaimStage(claim?: Pick<ClaimActivity, 'stage'>) {
    const stage = claim?.stage
    return stage ? claimStageToText[stage] : undefined
}

const appointmentTypeToText = {
    [AppointmentTypes.PHYSIO]: 'Physiotherapy',
    [AppointmentTypes.VIRTUAL_GP]: 'Virtual GP',
}
export function prettyPrintAppointmentType(appointment?: Appointment) {
    return appointment?.type ? appointmentTypeToText[appointment.type] : undefined
}

export function shortYear(maybeLongYear: number) {
    const stringYear = String(maybeLongYear)
    return stringYear.length == 4 ? stringYear.substring(2) : stringYear
}

export function readablePlanYear(planYear: PlanYear) {
    return planYear ? `${format(planYear.start, 'dd MMM yyyy/')}${format(planYear.end, 'yy')}` : undefined
}

export function readablePlanYearShort(planYear: PlanYear) {
    return planYear ? `${format(planYear.start, 'yyyy/')}${format(planYear.end, 'yy')}` : undefined
}

export function obfsucateBankAccountNumber(accountNumber?: string) {
    return accountNumber ? `****${accountNumber.slice(4)}` : undefined
}

export function prettyPrintSortCode(sortCode: string) {
    return sortCode ? `${sortCode.slice(0,2)}-${sortCode.slice(2,4)}-${sortCode.slice(4,6)}` : undefined
}