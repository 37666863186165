import {fromJson, isArray, isObject, isPrimitive, isString} from '@peachy/utility-kit-pure'
import {HASH_LENGTH, isRepoHash, RepoHash, repoHashJsonReviver} from './hash-primatives'


export function canBePaged(x: unknown): x is Page {
    return isArray(x) || isObject(x) || isString(x) && x.length > HASH_LENGTH
}


export function toPage(json: unknown): Page {
    return fromJson<Page>(json, repoHashJsonReviver)
}

export function toPageArray(json: unknown): Page[] {
    return fromJson<Page[]>(json, repoHashJsonReviver)
}


export type PagePrimitive = number | boolean | string
export type PageValue = PagePrimitive | RepoHash

export type PageObject = {[k: string]: PageValue}
export type PageArray = PageValue[]

export type Page = PageObject | PageArray | string


export function isPageValue(x: unknown): x is PageValue {
    return isPrimitive(x) || isRepoHash(x)
}
