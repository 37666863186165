import {BenefitType, isCancelled, MrLife, MrLifeBenefit, MrPolicy} from '@peachy/core-domain-pure'
import {Draft, KeyMapped, keys, mapById, values} from '@peachy/utility-kit-pure'


export function selectPoliciesForQuote(policies: KeyMapped<MrPolicy>, diff: Draft<KeyMapped<MrPolicy>>): KeyMapped<MrPolicy> {
    return mapById(
        keys(diff)
            .map(policyId => policies[policyId])
            .filter(p => !isCancelled(p))
            .map(p => {
                return {
                    ...p,
                    lives: selectLivesForQuote(p.lives, diff[p.id]?.lives)
                }
            })
    )
}


export function selectLivesForQuote(newLifeMap: KeyMapped<MrLife>, diff: Draft<KeyMapped<MrLife>>): KeyMapped<MrLife> {
    return mapById(
        keys(diff)
            .map(lifeId => newLifeMap[lifeId])
            .filter(l => !isCancelled(l))
            .map(l => {
                return {
                    ...l,
                    benefits: selectBenefitsForQuote(l.benefits)
                }
            })
    )
}


export function selectBenefitsForQuote(newBenefits: KeyMapped<MrLifeBenefit, BenefitType>): KeyMapped<MrLifeBenefit> {
    return mapById(values(newBenefits).filter(b => !isCancelled(b)))
}
