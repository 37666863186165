export * from './src/domain/Address'
export * from './src/domain/Appointment'
export * from './src/domain/Benefit'
export * from './src/domain/ClaimActivity'
export * from './src/domain/Decision'
export * from './src/domain/Life'
export * from './src/domain/Pharmacy'
export * from './src/domain/Plan'
export * from './src/domain/Policy'
export * from './src/domain/RegisteredGp'
export * from './src/domain/NhsOrganisation'
export * from './src/domain/NhsOrganisationLookupResult'
export * from './src/domain/MarketplaceTypes'
export * from './src/domain/MemberFunds'
export * from './src/domain/Excess'
export * from './src/domain/types'
export * from './src/domain/utils'

export * from './src/domain/enquiry/Enquiry'
export * from './src/domain/enquiry/Question'
export * from './src/domain/enquiry/QuestionOption'
export * from './src/domain/enquiry/QuestionOptionsDefinition'
export * from './src/domain/enquiry/QuestionValidation'
export * from './src/domain/enquiry/option-definitions'
export * from './src/domain/enquiry/types'

export * from './src/domain/enquiry/answer/BankDetails'
export * from './src/domain/enquiry/answer/Media'
export * from './src/domain/enquiry/answer/types'

export * from './src/domain/assessment/ClaimAssessment'
export * from './src/domain/assessment/ApprovedClaimCosts'
export * from './src/domain/assessment/ClaimActivitySubmissionReason'
export * from './src/domain/assessment/ClaimInvoiceLineItem'
export * from './src/domain/assessment/CoverCheckRequestedTreatment'
export * from './src/domain/assessment/HospitalAdmission'
export * from './src/domain/assessment/PlanYearBenefitAmountTotals'
export * from './src/domain/assessment/PlanYearExcessAmountTotals'

export * from './src/repo/types'
export * from './src/mappings/DomainMappings'
        import * as me from '@peachy/repo-domain'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/comp-kit/repo-domain'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/repo-domain'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    