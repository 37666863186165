import {Flash, Hash, isHardHash,} from './flash-repo-domain'
import {isArray, isObject, mapAsync, mapObjectAsync} from '@peachy/utility-kit-pure'
import {FlashStore, IFlashStore} from './FlashStore'

import {MemFlashDb} from './MemFlashDb'

export class FlashStoreScope extends FlashStore{

    constructor (private baseFlashStore: IFlashStore) {
        super(new MemFlashDb(`${baseFlashStore.getDbName()}-scope`))
    }


    async pushScope(hash: Hash): Promise<Hash> {
        if (await this.baseFlashStore.hasCachedHash(hash)) {
            return hash
        }

        const flash = await super.get(hash)

        const mapper = async (v: unknown) => {
            if (isHardHash(v)) await this.pushScope(v.toString())
        }


        if (isArray(flash)) {
            await mapAsync(flash, mapper)
        } else if (isObject(flash)) {
            await mapObjectAsync(flash, mapper)
        }

        return this.baseFlashStore.put(flash)
    }

    async get(hash: Hash): Promise<Flash> {
        return await super.get(hash) ?? await this.baseFlashStore.get(hash)
    }
}
