import {Component, ParentProps, splitProps} from 'solid-js'
import {isFinite} from 'lodash-es'

type MarginProps = ParentProps<{
    marginTop?: boolean | number
    marginBottom?: boolean | number
    marginLeft?: boolean | number
    marginRight?: boolean | number
    marginHorizontal?: boolean | number
    marginVertical?: boolean | number
    margin?: boolean | number
}>

export type MarginableProps<T> = MarginProps & T

const DEFAULT_MARGIN = 1.5
const undefinedIfFalseOrDefaultMarginIfTrueOrValueIfNumber = overridableEm(DEFAULT_MARGIN)

export function Margin(props: MarginProps) {

    const style = {
        'margin-top': undefinedIfFalseOrDefaultMarginIfTrueOrValueIfNumber(props.marginTop || props.marginVertical),
        'margin-bottom': undefinedIfFalseOrDefaultMarginIfTrueOrValueIfNumber(props.marginBottom || props.marginVertical),
        'margin-left': undefinedIfFalseOrDefaultMarginIfTrueOrValueIfNumber(props.marginLeft || props.marginHorizontal),
        'margin-right': undefinedIfFalseOrDefaultMarginIfTrueOrValueIfNumber(props.marginRight || props.marginHorizontal),
        'margin': undefinedIfFalseOrDefaultMarginIfTrueOrValueIfNumber(props.margin)
    }

    return <div style={style}>{props.children}</div>
}

export function Marginable<ComponentProps>(component: Component<ComponentProps>) {
    return (props: MarginableProps<ComponentProps>) => {
        const [marginProps, componentProps] = splitMarginProps<ComponentProps>(props)
        return <Margin {...marginProps}>
            {component(componentProps)}
        </Margin>
    }
}

function splitMarginProps<OtherProps>(props: MarginProps & OtherProps) {
    return splitProps(props, [
        'marginTop',
        'marginBottom',
        'marginLeft',
        'marginRight',
        'marginHorizontal',
        'marginVertical',
        'margin'
    ])
}

function overridableEm(value: number) {
    return (override: number | boolean) => {
        const resolvedValue = valueOrOverride(value, override)
        return resolvedValue ? resolvedValue + 'em' : undefined
    }
}

function valueOrOverride(value: number, override: number | boolean) {
    if (!override) {
        // override with undefined
        return undefined
    }
    return isFinite(override) ? override : value
}