import {BranchInfo, BranchName, InstallationId, MergeState} from '../primatives/repo-primatives'
import {RepoHash} from '../primatives/hash-primatives'
import {BookmarkedPage, PageInfo, PageReceipt, PageSyncState} from '../key-interfaces/IPageIo'
import {Observable} from 'rxjs'
import {defineActionApi, returnsObservable, returnsPromise} from '@peachy/sockets-pure'
import {Bookmark} from '@peachy/utility-kit-pure'

export type IRepoSyncApi = {
    fetchBookmark(): Promise<Bookmark>

    commitPageInfoStream(pageInfo$: Observable<PageInfo>, skipExistenceCheck?: boolean): Observable<PageReceipt>
    filterForUnknownPageHashes(repoHash$: Observable<RepoHash>): Observable<RepoHash>
    fetchPageInfoStream(repoHash$: Observable<RepoHash>,): Observable<PageInfo>


    fetchPageKeysSince(bookmark?: Bookmark): Observable<BookmarkedPage>
    fetchBranch(branch: BranchName): Promise<BranchInfo>
    commitBranch(commitBranchRequest: CommitBranchRequest): Promise<BranchInfo>
    fetchBranches(): Promise<BranchInfo[]>
    fetchBranchUpdatesSince(request: FetchBranchUpdatesSinceRequest): Observable<BranchInfo>
    fetchPageSyncState(installationId: InstallationId): Promise<PageSyncState>
    commitPageSyncState(request: CommitPageSyncStateRequest): Promise<PageSyncState>
    fetchMergeState(request: FetchMergeStateRequest): Promise<MergeState>
    commitMergeState(request: CommitMergeStateRequest): Promise<MergeState>
}


export const RepoSyncApiDefinition =
    defineActionApi('RepoSyncApi', {
        fetchBookmark: () => returnsPromise<Bookmark>(),
        commitBranch: (request: CommitBranchRequest) => returnsPromise<BranchInfo>(),
        commitMergeState: (request: CommitMergeStateRequest) => returnsPromise<MergeState>(),
        commitPageSyncState: (request: CommitPageSyncStateRequest) => returnsPromise<PageSyncState>(),

        fetchPageInfoStream: (repoHash$: Observable<RepoHash>) => returnsObservable<PageInfo>(),
        filterForUnknownPageHashes: (repoHash$: Observable<RepoHash>) => returnsObservable<RepoHash>(),
        commitPageInfoStream: (pageInfo$: Observable<PageInfo>, skipExistenceCheck?: boolean) =>  returnsObservable<PageReceipt>(),

        fetchBranch: (branch: BranchName) => returnsPromise<BranchInfo>(),
        fetchBranchUpdatesSince: (request: FetchBranchUpdatesSinceRequest) => returnsObservable<BranchInfo>(),
        fetchBranches: () => returnsPromise<BranchInfo[]>(),
        fetchMergeState: (request: FetchMergeStateRequest): Promise<MergeState> => returnsPromise<MergeState>(),
        fetchPageKeysSince: (bookmark?: Bookmark) => returnsObservable<BookmarkedPage>(),
        fetchPageSyncState: (installationId: InstallationId) => returnsPromise<PageSyncState>(),

    } as const satisfies IRepoSyncApi)


export type CommitBranchRequest = {
    branch: BranchName
    branchHeadKey: RepoHash
    commitMessage: string
    expectedHeadKey?: RepoHash
}


export type FetchBranchUpdatesSinceRequest = {
    branch: BranchName,
    rootBookmark?: Bookmark
}


export type CommitPageSyncStateRequest = {
    remoteInstallationId: InstallationId,
    pageSyncState: PageSyncState,
    expectedState?: PageSyncState
}

export type FetchMergeStateRequest = {
    installationId: InstallationId,
    branchName: BranchName
}

export type CommitMergeStateRequest = {
    installationId: InstallationId,
    branchName: BranchName,
    mergeState: MergeState,
    expectedMergeState: MergeState
}
