import {map, of, Subject} from 'rxjs'
import {PNode, PNodeMutability, PNodePath, PNodeProp, PNodeQuery, PNodeTransaction} from './path-builder-domain'
import {Hash} from '../flash-repo-domain'


export type PNodeHandler = {
    setRootHash(newRootHash: Hash): boolean
    hardGet(path: PNodePath): Promise<any>
    softGet(path: PNodePath): Promise<any>
    set(path: PNodePath, value: any): Promise<boolean>
    transact(path: PNodePath, transaction: (h: PNodeHandler) => Promise<void>): Promise<boolean>
    getName(): string
}

type PNodeMap = Map<string, PNodeProxy<any>>

export const SetNodeRootHash = Symbol()

export class PNodeQueryImpl<T> implements PNodeQuery<T> {
    constructor(public readonly queryHash: string) {
    }
    run(t: T): Promise<T> {
        return Promise.resolve(undefined)
    }
    toString(): string {
        return this.queryHash
    }
}


export class PNodeProxy<T> implements ProxyHandler<PNode<T>> {

    // to broadcast changes
    private nodeSubject: Subject<T>

    // cache of node proxies
    private proxyMap: PNodeMap
    private parent: PNodeProxy<any>
    private pathHandler: PNodeHandler
    private path: PNodePath
    private pathString: string
    private proxy: T

    private mutability : PNodeMutability

    private constructor() {
    }

    public static createRootProxy<T>(pathHandler: PNodeHandler): PNode<T> {
        const rootProxy = new PNodeProxy()
        rootProxy.path = []
        rootProxy.proxyMap = new Map() // new proxy map
        rootProxy.proxyMap.set('', rootProxy)
        rootProxy.pathHandler = pathHandler
        rootProxy.proxy = new Proxy(() => {}, rootProxy)
        rootProxy.mutability = 'MUTABLE'
        return rootProxy.proxy as PNode<T>
    }

    private createChildProxy(prop: PNodeProp, mutability: PNodeMutability = this.mutability) {

        const childPropPath = [...this.path, prop].join('.')
        if (this.proxyMap.has(childPropPath)) {
            return this.proxyMap.get(childPropPath).proxy
        }
        const childProxy = new PNodeProxy()
        childProxy.path = [...this.path, prop]
        childProxy.parent = this
        childProxy.proxyMap = this.proxyMap
        childProxy.pathHandler = this.pathHandler
        childProxy.proxy = new Proxy(() => {}, childProxy)
        childProxy.mutability = mutability
        this.proxyMap.set(childProxy.getPathString(), childProxy)
        return childProxy.proxy
    }


    // handles .notation property access
    get(target: PNode<T>, p: string | number | symbol, receiver: any): any {

        let prop: string | number = p.toString()

        if (prop === '$')
            return this.makeSubscribeFunction()

        if (prop === '$$')
            return this.path

        if (prop === '𝐈') {
            return this.pathHandler.softGet(this.path)
        }

        // if (prop === 'λ') {
        //     return this.makeLambdaFunctionSet()
        // }
        //

        if (prop === 'Δ') {
            return this.mutability === 'MUTABLE'
                ? this.makeTransactFunction<T>()
                : undefined
        }

        // if (p === SetNodeRootHash) {
        //     return (newRootHash: Hash) => {
        //         if (this.pathHandler.setRootHash(newRootHash)) {
        //             this.notify()
        //             return true
        //         }
        //     }
        // }

        if (prop === 'then')
            return undefined

        if (prop === 'toJSON') {
            return () => ({warning: this.getPathString()})
        }

        if (!isNaN(+prop)) prop = +prop

        return this.createChildProxy(prop)
    }

    async apply(target: PNode<T>, thisArg: any, args: any[]): Promise<boolean | T> {

        if (this.mutability !== 'MUTABLE') {
            throw 'Cannot mutate path ' + this.path
        }

        // setter
        if (args.length) {

            const newValue = args[0]

            const hasChanged = await this.pathHandler.set(this.path, newValue)

            if (hasChanged) {
                this.notify()
                return true
            } else {
                return false
            }

        }
        // getter
        else {
            return this.pathHandler.hardGet(this.path)
        }
    }

    private makeTransactFunction<T>() {
        return async (transaction: PNodeTransaction<T>) => {
            const hasChanged = await this.pathHandler.transact(
                this.path,
                async (h: PNodeHandler) => {

                    const transactionRootProxy = PNodeProxy.createRootProxy<T>(h)
                    await transaction(transactionRootProxy)
                }
            )
            if (hasChanged) {
                this.notify()
            }
            return hasChanged
        }
    }

    private makeSubscribeFunction() {
        const subject = this.getSubject()
        return (...args: any[]): any => {
            if (args.length) {
                const subscriber = args[0]
                const subscription = subject.subscribe(subscriber)
                of([this.proxy]).pipe(map(i => i[0])).subscribe(subscriber)

                return subscription
            } else {
                return subject
            }
        }
    }
    //
    // private makeLambdaFunctionSet<T>(){
    //     const self = this
    //
    //     return  {
    //         filter(p: Predicate<any>) {
    //             const filterHash = rawHash(p)
    //             console.log({filterHash})
    //
    //             const la: PNodeQuery<T> = {
    //                 async run(t:T): Promise<T> {
    //                     return t
    //                 },
    //                 toString() {
    //                     return filterHash
    //                 }
    //             }
    //             la.toString = () => filterHash
    //             return self.createChildProxy(la as PNodeQuery<T>)
    //         }
    //     }
    // }

    private getSubject() {
        return this.nodeSubject ?? (this.nodeSubject = new Subject<T>())
    }
    private hasSubject(): boolean {
        return !!this.nodeSubject
    }

    private getPathString() {
        return this.pathString ?? (this.pathString = this.path.join('.'))
    }

    private notify() {

        const notifications: (() => void)[] = []
        this.proxyMap.forEach((node) => {
            if (node.hasSubject()) {
                if (areDependent(node.getPathString(), this.getPathString())) {
                    notifications.push(() => {
                        node.getSubject().next(node.proxy)
                    })
                }
            }
        })

        for (const n of notifications) {
            n()
        }
    }
}

function areDependent(pathA: string, pathB: string) {
    return pathA.startsWith(pathB) || pathB.startsWith(pathA)
}

