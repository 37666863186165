import {PeachyFlashRepo, PeachyRepoRootNode} from '../types'
import {RepoInProgress, RepoInProgressTypes} from '@peachy/repo-domain'
import _ from 'lodash-es'

export class InProgressRepository {

    constructor(protected readonly repo: PeachyFlashRepo) { }

    async get<T extends RepoInProgressTypes>(nameOfThingInProgress: T): Promise<RepoInProgress[T]> {
        const root = await this.repo.getContentRoot()
        return await root.inProgress[nameOfThingInProgress]() as RepoInProgress[T]
    }

    async getAllThingsInProgress(context?: PeachyRepoRootNode) {
        const root = context ? context : await this.repo.getContentRoot()
        return root.inProgress()
    }
    
    async deleteAllThingsInProgress(context?: PeachyRepoRootNode) {
        const root = context ? context : await this.repo.getContentRoot()
        return root.inProgress({})
    }

    async save<T extends RepoInProgressTypes>(nameOfThingInProgress: T, thing: RepoInProgress[T], context?: PeachyRepoRootNode): Promise<RepoInProgress[T]> {
        console.log('saving in progress item', nameOfThingInProgress)
        const root = context ? context : await this.repo.getContentRoot()

        // @ts-ignore
        await root.inProgress[nameOfThingInProgress](thing)
        console.log('saved in progress item', nameOfThingInProgress)
        return thing
    }

    async delete(nameOfThingInProgress: RepoInProgressTypes, context?: PeachyRepoRootNode) {
        console.log('deleting in progress item', nameOfThingInProgress)
        const root = context ? context : await this.repo.getContentRoot()
        const allInProgress = await this.getAllThingsInProgress(context)
        const allInProgressWithoutOneToDelete = _.omit(allInProgress, nameOfThingInProgress)
        return root.inProgress(allInProgressWithoutOneToDelete)
    }

    async debug() {
        const allItems = await this.getAllThingsInProgress()
        console.log('\n\n\n\n********************* DEBUG ITEMS IN PROGRESS')
        console.log(JSON.stringify(allItems ?? {}, null, 2))
    }
}


