import {MrLife, MrPolicy, MrSubscription, toClass} from '@peachy/core-domain-pure'
import {displayableId, values} from '@peachy/utility-kit-pure'
import {Component, createMemo} from 'solid-js'
import {useAccountSubscription} from '../SubscriptionProvider'
import {getMemberId} from '../Members'

export const CancelModalContactDetails: Component= () => {
    const accountSubscription = useAccountSubscription()
    const account = accountSubscription.account
    const subscription = accountSubscription.subscription

    const details = createMemo(() => {
        if(!getMemberId() && account.type === 'COMPANY') {
            const sub = toClass(subscription, MrSubscription)

            return {
                name: sub.contactName,
                address: account.company.address.display,
                ref: sub.referenceNumber(),
                email: sub.contactEmail
            }
        }

        const policy = toClass(values(subscription.policies).find(p => p.lives[getMemberId()]) ?? subscription.policies[0], MrPolicy)

        const life = toClass(getMemberId() ? policy.lives[getMemberId()] : policy.getPrimaryLife(), MrLife)

        return {
            name: life.fullName(),
            address: life.address.display,
            ref: displayableId(policy.id),
            email: life.email
        }
    })


    return (
        <div>
            <p>Name: {details().name}</p>
            <p>Address: {details().address}</p>
            <p>Ref: {details().ref}</p>
            <p>Email: {details().email}</p>
        </div>
    )
}
