import {PeachyFlashRepo} from '../types'
import {
    RepoClaimActivity,
    RepoClaimAssessment,
    RepoMediaUploadState,
    UNFINISHED_BUT_RESUMABLE_UPLOAD_STATES
} from '@peachy/repo-domain'
import {PeachyArrayRepository} from '@peachy/flash-repo-peachy-client'

export class ClaimActivityRepository extends PeachyArrayRepository<RepoClaimActivity> {

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.claimActivities)
    }

    async listSubmitted() {
        return this.filter(it => !!it.submissionId)
    }

    async listSubmittedWithUnfinishedButResumableMediaUploads() {
        const submitted = await this.listSubmitted()
        return submitted.filter(claimActivity => {
            const mediaUploadStates = Object.values(claimActivity.media ?? {}).flatMap(media => media.uploadState)
            return mediaUploadStates.some(isConsideredUnfinishedAndResumable)
        })
    }

    async updateMediaUploadState(claimActivityId: string, mediaFileName: string, uploadState: RepoMediaUploadState) {
        this.logger.debug('setting upload state of', mediaFileName, 'to', uploadState, 'on claim activity', claimActivityId)
        const root = await this.repo.getContentRoot()
        await root.Δ(async rootTx => {
            const currentMedia = (await this.get(claimActivityId, rootTx)).media ?? {}
            const mediaToUpdate = currentMedia[mediaFileName] ?? {}
            mediaToUpdate.uploadState = uploadState
            const updatedMedia = {[mediaFileName]: mediaToUpdate}
            await this.patch({id: claimActivityId, media: {...currentMedia, ...updatedMedia}}, rootTx)
        })
    }

    async getExistingOrNewBlankAssessmentFor(claimActivityId: string): Promise<RepoClaimAssessment> {
        const claimActivity = await this.get(claimActivityId)
        return claimActivity.assessment ?? {
            hospitalAdmissions: [],
            invoiceLineItems: [],
            submissionReasons: [],
            linkedClaimActivityIds: [],
            requestedTreatments: []
        }
    }
}

function isConsideredUnfinishedAndResumable(mediaUploadState: RepoMediaUploadState) {
    return UNFINISHED_BUT_RESUMABLE_UPLOAD_STATES.includes(mediaUploadState)
}
