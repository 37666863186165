import {PeachyFlashRepo} from '../types'
import {PeachyMapRepository} from './abstract/PeachyMapRepository'
import {RepoLife} from '@peachy/repo-domain'

export class ManagedLifeRepository extends PeachyMapRepository<RepoLife> {

    constructor(repo: PeachyFlashRepo) {
        super(repo, root => root.managedLives)
    }

    async getPrimaryLife() {
        return this.find(it => it.isPrimary)
    }

    async getDependents() {
        return this.filter(it => !it.isPrimary)
    }
}