import {createResource, createSignal, Show} from 'solid-js'

import {
    Flash,
    FlashRepo,
    FlashValue,
    getPropType,
    HardHash,
    isEmptyHash,
    isHardHash,
    Tag
} from '@peachy/flash-repo-pure'
import styles from './RepoView.module.css'

import {asDoc, last} from '@peachy/utility-kit-pure'
import {format} from 'date-fns'
import {createToggleSignal, Modal, NULL, TreeNode, TreeResolver} from '@peachy/client-kit'
import {RepoNodeEditor, RepoNodeEditorProps} from '../RepoNodeEditor/RepoNodeEditor'
import {RepoStatus} from '../RepoStatus/RepoStatus'
import {useRepoContext} from '../../RepoController'


export function RepoView() {
    const repoContext = useRepoContext()

    const repo = repoContext.repo

    const [rootHash, trigger] = createResource(() => repo.getContentRootHash().then(h => new HardHash(h)))

    repoContext.root.$(async o => {
        trigger.refetch()
    })

    const [showEditor, toggleShowEditor] = createToggleSignal(false)

    const [editorProps, setEditorProps] = createSignal<RepoNodeEditorProps>()

    const treeResolver: TreeResolver<FlashValue, Flash> = {

        hasChildren(value: unknown) {
            return isHardHash(value) && !isEmptyHash(value.toString())
        },

        async resolveChildren(value: FlashValue) {
            return isHardHash(value) ? repo.flashStore.get(value.toString()) : null
        },

        displayValue(value: FlashValue, node: Flash, path:Tag[], showChildren = false) {
            return <Value showChildren={showChildren} value={value} node={node} tag={last(path)}/>
        },

        async onAltClick(value: FlashValue, node: Flash, path: Tag[], element: HTMLElement) {
            await onCopy(value, false, repo, element)
        },
        async onShiftClick(value: FlashValue, node: Flash, path:Tag[], element: HTMLElement) {
            await onCopy(value, true, repo, element)
        },
        async onMetaClick(value: FlashValue, node: Flash, path:Tag[], element: HTMLElement) {
            setEditorProps({
                path,
                value,
                treeResolver: this
            })
            toggleShowEditor()
        },
    }

    return (
        <div class={styles.RepoView}>
            <header>
                <h2>Root</h2>
                <RepoStatus/>
            </header>
            <main>
                <Show when={rootHash.latest}>
                    <TreeNode
                        node={rootHash()}
                        treeResolver={treeResolver}
                        showChildren
                    />
                </Show>
            </main>
            <Modal isOpen={showEditor()} onDismiss={toggleShowEditor}>
                <RepoNodeEditor path={editorProps().path} value={editorProps().value} treeResolver={editorProps().treeResolver} onUpdate={toggleShowEditor}/>
            </Modal>
        </div>
    )
}

async function onCopy(value: unknown, asJson: boolean, repo: FlashRepo, element: HTMLElement) {
    element.setAttribute('data-copy-status', asJson ? 'copy-json':'copy-value')

    if (isHardHash(value) && asJson) {
        const expandedValue = await repo.flashStore.build(value)
        await navigator.clipboard.writeText(asDoc(expandedValue))
    } else {
        await navigator.clipboard.writeText(`${value}`)
    }

    requestAnimationFrame(() => {
        element.removeAttribute('data-copy-status')
    })
}



type ValueProps = {
    showChildren: boolean
    value: unknown,
    node: unknown,
    tag:Tag,
}


export function Value(props: ValueProps) {

    let wrapperElement: HTMLElement

    const valueElement = isHardHash(props.value)
        ? isEmptyHash(props.value.toString())
            ? <span>{`{${NULL}}`}</span>
            : <><i>{props.showChildren ? <>&darr;</> : <>&rarr;</>}</i><pre>{displayValue(props.value)}</pre></>
        : <>{displayValue(props.value)}</>

    return (
        <span ref={wrapperElement}>
            {valueElement}
        </span>
    )
}


const displayValue = (value: unknown) => {
    switch (getPropType(value)) {
        case 'null': return NULL
        case 'string': return `'${value}'`
        case 'boolean':
        case 'number': return value.toString()
        case 'Date': return format(value as Date, 'dd/MM/yyyy')
        // case 'array': return NULL
        // case 'object': return NULL
        case 'hash': return `${value.toString().slice(0, 7)}...`
        default: return 'NO DISPLAY VALUE'
    }
}