import {Route} from '@solidjs/router'
import {Home} from '../../pages/Home/Home'
import {UserAdminActions} from '@peachy/web-dashboard-pure'
import {Scratch} from '../../scratch/Scratch'
import {lazy} from 'solid-js'
import {CustomerDashboard} from '../../pages/Customer/CustomerDashboard'
import {ViewClaim} from '../../pages/Customer/Claim/ViewClaim'
import {FlashRepoPanel} from '../../pages/Admin/flash-repo/FlashRepoPanel/FlashRepoPanel'

const ClaimBdx = lazy(() => import('../../pages/bdx/ClaimBdx'))

const NewCustomerPassword = lazy(() => import('../../pages/Admin/password/NewCustomerPassword'))
const NewDashUserPassword = lazy(() => import('../../pages/Admin/password/NewDashUserPassword'))


const CancelSubscription = lazy(() => import('../../pages/Admin/subscription/CancelSubscription'))
const CancelPolicy = lazy(() => import('../../pages/Admin/subscription/CancelPolicy'));

const CustomerAppContextAwareRoutes = lazy(() => import('../../pages/Customer/CustomerAppContextAwareRoutes'))

export function MainPane() {
    return (
        <>
            {/*<IamRoute path={'/premium-bdx'} groups={[AdminUserGroup, InsurerUserGroup]} component={PremiumBdx}/>*/}
            <Route path={'/claim-bdx'} component={ClaimBdx}/>
            <Route path={`/${UserAdminActions.newCustomerPassword}`} component={NewCustomerPassword}/>
            <Route path={`/${UserAdminActions.newDashUserPassword}`} component={NewDashUserPassword}/>

            {/*<IamRoute path={`/payments`} groups={AdminUserGroup} component={PaymentsPanel}/>*/}


            <Route path={`/account/:accountId/subscription/:subscriptionId`} component={CancelSubscription}/>
            <Route path={`/account/:accountId/subscription/:subscriptionId/member/:memberId`} component={CancelPolicy}/>
            <Route path={`/scratch`} component={Scratch}/>

            <Route path="/customer/:customerId/*" component={CustomerAppContextAwareRoutes}>
                <Route path="/" component={CustomerDashboard} />
                <Route path="/claim/:claimId" component={ViewClaim} />
                <Route path="/repo" component={FlashRepoPanel} />
            </Route>
            <Route path={'*'} component={Home}/>
        </>
    )
}
