import {JSX, Show} from 'solid-js'
import s from './Stat.module.css'
import {classList} from '@peachy/utility-kit-pure'
import {Card} from '../../Card/Card'
import {Marginable} from '../Margin/Margin'

type StatProps = {
    title?: string | JSX.Element
    value?: string | JSX.Element
    valueColor?: string
    figure?: string | JSX.Element
    description?: string | JSX.Element
}

export const Stat = Marginable((props: StatProps) => {

    const textColor = () => s[props.valueColor || 'accent'] ?? ''

    return (
        <Card>
            <div class={s.stat}>
                <div class={s.statTitle}>{props.title ?? ''}</div>
                <div class={classList(s.statValue, textColor())}>{props.value ?? ''}</div>
                <div class={s.statFigure}>{props.figure ?? ''}</div>
                <div class={s.statDesc}>
                    <Show when={props.description} fallback={<>&nbsp;</>}>
                        {props.description}
                    </Show>
                </div>
            </div>
        </Card>
    )
})
