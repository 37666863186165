import styles from './Dashboard.module.css'
import {Header} from '../Header/Header'
import {MainPane} from '../MainPane/MainPane'
import {IamModal} from '../../Iam/IamModal'
import {createContext, createSignal, Show, useContext} from 'solid-js'
import {useIamService} from '../../controllers/DashboardServiceController'
import {Route, Router} from '@solidjs/router'
import {Main} from '../Main'


const NavContext = createContext<NavState>()
export const useNav = () => useContext(NavContext)

class NavState {
    private shownSignal = createSignal(true)
    readonly shown = this.shownSignal[0]
    private setShown = this.shownSignal[1]

    toggle() {
        this.setShown(prev => !prev)
    }

    hide() {
        this.setShown(false)
    }

}

export function Dashboard() {

    return (
        <div class={styles.Dashboard}>
            <NavContext.Provider value={new NavState()}>
                <DashboardContent/>
            </NavContext.Provider>
        </div>
    )
}


function DashboardContent() {
    const iamService = useIamService()
    return <>
        <Header/>
        <Show when={iamService?.isInState('SignedIn')}>
            <Router>
                <Route path={'/'} component={Main}>
                    <MainPane/>
                </Route>
            </Router>
        </Show>
        <IamModal/>
    </>
}
