import {syncPages} from '../page/syncPages'
import {mergeState} from '../merge/merge'
import {RepoSyncAgent} from './RepoSyncAgent'
import {BranchInfo, BranchName, InstallationId, MergeBias, MergeState} from '../../primatives/repo-primatives'
import {IRepoSyncApi} from '../../sync-api-definition/sync-api-definition'
import {RepoHash} from '../../primatives/hash-primatives'
import {asDoc} from '@peachy/utility-kit-pure'

export async function syncRepo(
    localRepo: RepoSyncAgent,
    remoteRepo: IRepoSyncApi,
    remoteInstallationId: InstallationId,
    branchName: BranchName,
    bias: MergeBias
): Promise<RepoHash> {


    const localPreMergeState = await localRepo.fetchMergeState({
        installationId: remoteInstallationId,
        branchName
    })

    const remotePreMergeState = await remoteRepo.fetchMergeState({
        installationId: localRepo.repoContext.installationId,
        branchName
    })

    const localBranchInfo = await localRepo.fetchBranch(branchName)
    const remoteBranchInfo = await remoteRepo.fetchBranch(branchName)

    await syncPages(localRepo, remoteRepo, remoteInstallationId)

    const mergedState = await mergeState(
        {
            localHead: localBranchInfo.branchHead,
            commonHead: localPreMergeState.commonHead,
            remoteHead: remoteBranchInfo.branchHead,
            mergeStatus: localPreMergeState.mergeStatus
        },
        localRepo.repoContext.pageStore,
        bias
    )
    await localRepo.commitMergeState({
        installationId: remoteInstallationId,
        branchName,
        mergeState: mergedState,
        expectedMergeState: localPreMergeState
    })

    const commitMessage = syncCommitMessage(
        mergedState,
        localBranchInfo,
        remoteBranchInfo,
    )

    await localRepo.commitBranch({
        branch: branchName,
        branchHeadKey: mergedState.localHead,
        expectedHeadKey: localBranchInfo.branchHead,
        commitMessage
    })

    await remoteRepo.commitMergeState({
        installationId: localRepo.repoContext.installationId,
        branchName,
        mergeState: {
            localHead: mergedState.remoteHead,
            commonHead: mergedState.commonHead,
            remoteHead: mergedState.localHead,
            mergeStatus: mergedState.mergeStatus
        },
        expectedMergeState: remotePreMergeState
    })

    if (mergedState.localHead === mergedState.remoteHead) {
        await remoteRepo.commitBranch({
            branch: branchName,
            branchHeadKey: mergedState.remoteHead,
            expectedHeadKey: remoteBranchInfo.branchHead,
            commitMessage
        })
    }
    return mergedState.localHead
}


function syncCommitMessage(mergeState: MergeState, leftBranchInfo: BranchInfo, rightBranchInfo: BranchInfo) {
    return [mergeState.mergeStatus, asDoc(leftBranchInfo), asDoc(rightBranchInfo),].join('\n\n')
}
