import {
    HasLifecycleStatus,
    isCancelled,
    MrLife,
    MrLifeBenefit,
    MrPolicy,
    MrSubscription
} from '@peachy/core-domain-pure'

import {mapById, values} from '@peachy/utility-kit-pure'

export function cancelSubscription(
    subscription: MrSubscription,
    effectiveDate: number,
    reason: string
): MrSubscription {

    const cancelledSubscription = {...subscription}
    ensureCancellationStatus(cancelledSubscription, effectiveDate, reason)

    const cancelledPolicies = values(cancelledSubscription.policies)
        .filter(p => !isCancelled(p))
        .map(p => cancelPolicy(p, cancelledSubscription.endDate, reason))

    cancelledSubscription.policies = {
        ...cancelledSubscription.policies,
        ...mapById(cancelledPolicies)
    }
    return cancelledSubscription
}

export function cancelPolicy(
    policy: MrPolicy,
    effectiveDate: number,
    reason: string
): MrPolicy {

    const cancelledPolicy = {...policy}
    ensureCancellationStatus(cancelledPolicy, effectiveDate, reason)

    const cancelledLives = values(cancelledPolicy.lives)
        .filter(l => !isCancelled(l))
        .map(l => cancelLife(l, cancelledPolicy.endDate, reason))

    cancelledPolicy.lives = {
        ...cancelledPolicy.lives,
        ...mapById(cancelledLives)
    }

    return cancelledPolicy
}

export function cancelLife(
    life: MrLife,
    effectiveDate: number,
    reason: string
): MrLife {

    const cancelledLife = {...life}
    ensureCancellationStatus(cancelledLife, effectiveDate, reason)

    const cancelledBenefits = values(cancelledLife.benefits)
        .filter(b => !isCancelled(b))
        .map(b => cancelLifeBenefit(b, cancelledLife.endDate, reason))

    cancelledLife.benefits = {
        ...cancelledLife.benefits,
        ...mapById(cancelledBenefits)
    }
    return cancelledLife
}

export function cancelLifeBenefit(
    benefit: MrLifeBenefit,
    effectiveDate: number,
    reason: string
) {
    const cancelledBenefit = {...benefit}
    ensureCancellationStatus(cancelledBenefit, effectiveDate, reason)
    return cancelledBenefit
}

export function ensureCancellationStatus(
    item: HasLifecycleStatus,
    effectiveDate: number,
    reason: string
) {
    item.status = 'CANCELLED'
    item.endDate = item.endDate ?? effectiveDate
    item.cancellationReason = item.cancellationReason ?? reason
}
