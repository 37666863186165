import {createUserAdminService, UserAdminClient} from './user-admin/UserAdminClient'
import {createIamService, IamService} from './iam/IamService'

import {createIamApi} from './iam/IamApi'
import {makeApiGatewayClient} from '@peachy/core-domain-client'
import {Auth} from '@aws-amplify/auth'
import {UserAdminApiGatewayDefinition} from '@peachy/web-dashboard-pure'

import {SubscriptionApiGateway, SubscriptionApiGatewayDefinition} from '@peachy/subscription-pure'
import {useApiInfra} from '../controllers/InfraController'


export type DashboardServices = {
    iamService: IamService
    userAdminService: UserAdminClient
    subscriptionService: SubscriptionApiGateway
}

export async function createDashboardServices(): Promise<DashboardServices> {

    const api = useApiInfra()

    return {
        iamService: createIamService(createIamApi()),
        userAdminService: createUserAdminService(await makeApiGatewayClient(UserAdminApiGatewayDefinition, api, Auth)),
        subscriptionService: await makeApiGatewayClient(SubscriptionApiGatewayDefinition, api, Auth)
    }
}
