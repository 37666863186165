import {Draft, isNullish, isNumber} from '@peachy/utility-kit-pure'
import {defaultEqualityFunction, difference, idBasedIdentityFunction} from '@peachy/memorepo-pure'
import {TypeAtPath, valueAtPath} from './paths'


export class AlterationDiff<const L, const R = L> {

    static async between<L, R = L>(left: L, right: R) {

        const oldDiff = await difference(left, right, {
            identityFunction: idBasedIdentityFunction, equalityFunction: (a, b) => {
                if (isNullish(a) && isNullish(b)) {
                    return true
                }
                if (isNumber(a) && isNumber(b)) {
                    if (Math.abs(a - b) < 0.000001) {
                        return true
                    }
                }
                return defaultEqualityFunction(a, b)
            }
        })

        return new AlterationDiff(oldDiff?.left as Draft<L>, oldDiff?.right as Draft<R>)
    }

    private constructor(
        public readonly left: Draft<L>,
        public readonly right: Draft<R>
    ) {

    }

    isAdded<const P extends any[]>(path: P) {
        const l = valueAtPath(this.left, path)
        const r = valueAtPath(this.right, path)
        return isNullish(l) && !isNullish(r)
    }

    isAltered<const P extends any[]>(path: P) {
        const l = valueAtPath(this.left, path)
        const r = valueAtPath(this.right, path)
        return !isNullish(l) && !isNullish(r)
    }
    isRemoved<const P extends any[]>(path: P) {
        const l = valueAtPath(this.left, path)
        const r = valueAtPath(this.right, path)
        return !isNullish(l) && isNullish(r)
    }

    alteredTo<const P extends any[]>(path: P) {
        const l = valueAtPath(this.left, path)
        const r = valueAtPath(this.right, path)
        return !isNullish(l) && !isNullish(r) ? r : null
    }

    alteredFrom<const P extends any[]>(path: P) {
        const l = valueAtPath(this.left, path)
        const r = valueAtPath(this.right, path)
        return !isNullish(l) && !isNullish(r) ? l: null
    }

    subDiff<const P extends any[]>(path: P): AlterationDiff<TypeAtPath<L, P>, TypeAtPath<R, P>> {
        const l = valueAtPath(this.left, path)
        const r = valueAtPath(this.right, path)
        return new AlterationDiff(l as Draft<TypeAtPath<L, P>>, r as Draft<TypeAtPath<R, P>>)
    }
}
