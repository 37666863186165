import {Component} from 'solid-js'
import styles from './CancelButtons.module.css'

export const CancelButtons: Component<{
    enabled: boolean,
    onCancel: () => void,
    onDismiss: () => void
}> = (props) => {
    return (
        <div class={styles.container}>
            {/* TODO validation on what enables the button */}
            <button disabled={!props.enabled} onClick={props.onCancel}>Yes</button>
            <button onClick={props.onDismiss}>No</button>
        </div>
    )
}
