import {createToggleSignal, PopupListSelector} from '@peachy/client-kit'
import {Accessor, Component} from 'solid-js'
import styles from './CancelReason.module.css'

const CANCEL_REASONS = [
    'Product unsuitable / misunderstood',
    'Product too expensive',
    'Alternative product purchased',
    'Cover overlapped with pre-existing cover',
    'Product cancelled within cooling off period',
    'Product cancelled by underwriter',
    'Poor service/complaint',
    'Cover no longer required',
    'Other (administrative reason)',
    'Not known',
]


export type CancelReasonProps = {
    selectedReason: Accessor<string>
    onSelect: (reason: string) => void
}


export const CancelReason:Component<CancelReasonProps> = (props) => {

    const [reasonSelector, toggleReasonSelector] = createToggleSignal(false)

    const onSelect = (selectedReason: string) => {
        toggleReasonSelector()
        props.onSelect(selectedReason)
    }

    let spanRef: HTMLSpanElement

    return (<>
        <p>For <span ref={spanRef} onClick={toggleReasonSelector}>[{props.selectedReason()}] <i class={'fa-solid fa-caret-down'} /></span></p>
        <PopupListSelector
            list={CANCEL_REASONS}
            enabled={reasonSelector()}
            locatorElement={spanRef}
            onSelect={onSelect}
            onDismiss={toggleReasonSelector}
            appearance={{
                list: styles.list,
                listItem: styles.listItem,
                selectedItem: styles.selectedItem
            }}
        />
    </>)
}
