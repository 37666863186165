import {KeyMap, keysOf} from './type-kit'
import {defineApi, defineApiGateway, getOf, postOf} from '@peachy/core-domain-pure'
import {ApiGatewayClient} from '@peachy/core-domain-client'


export const UserAdminApiName = 'UserAdminApi'

export const AdminUserGroup = 'AdminUsers'
export const InsurerUserGroup = 'InsurerUsers'

export const DashboardGroups = [AdminUserGroup, InsurerUserGroup] as const


export type UserAdminApi = {
    newDashUserPassword: (email: string, password?: string) => Promise<void>
    newCustomerPassword: (email: string, password?: string) => Promise<void>
}

export type UserAdminAction = keyof UserAdminApi
export const UserAdminActions = keysOf<UserAdminApi>()

export type UserAdminRoutes = KeyMap<UserAdminApi, string>

export class GetThirdPartyUriRequestParams {
    sub: string
    endpoint: string
    modelPairs?: string
}

export class GetThirdPartyUriResponse {
    uri: string
}

export class NewPasswordRequest {
    email: string
    password?: string
}


export const UserAdminApiGatewayDefinition = defineApiGateway(
    'UserAdminApiGateway'
).withRoutes({
    resetDashUserPassword: {
        method: 'POST',
        path: '/resetDashUserPassword',
        isPublic: false,
        requestType: NewPasswordRequest,
    },
    resetMemberPassword: {
        method: 'POST',
        path: '/resetMemberPassword',
        isPublic: false,
        requestType: NewPasswordRequest,
    },
    getThirdPartyUri: {
        method: 'GET',
        path: `/thirdPartyUri?sub={sub}&endpoint={endpoint}`,
        isPublic: false,
        responseType: GetThirdPartyUriResponse
    },
})
export type UserAdminApiGateway = ApiGatewayClient<typeof UserAdminApiGatewayDefinition>


export const UserAdminApiDefinitionDeprecated = defineApi(UserAdminApiName)
    .forGroups(DashboardGroups)
    .withActions({
        newDashUserPassword: postOf(NewPasswordRequest).fromGroups(AdminUserGroup).willReturn(Object), //todo how to state it returns void?
        newCustomerPassword: postOf(NewPasswordRequest).fromGroups(AdminUserGroup).willReturn(Object), //todo how to state it returns void?
        getThirdPartyUri: getOf(GetThirdPartyUriRequestParams).fromGroups(AdminUserGroup).willReturn(GetThirdPartyUriResponse)
    })
