import {Flex} from '../../../../components/deprecated/Flex/Flex'
import {isUndefined} from 'lodash-es'
import {TextBox, TextBoxProps} from '@peachy/client-kit'
import {stringToCurrencyNumber} from '@peachy/utility-kit-pure'

type InputCurrencyProps = Omit<TextBoxProps, 'value' | 'onChange'> & {
    value?: number
    onChange?: (value: number | undefined) => void
    max?: number
}

export function InputCurrency(props: InputCurrencyProps) {

    const onChange = (stringValue: string) => {
        const value = getConstrainedNumber(stringValue)
        props.onChange?.(value)
    }

    const onKeyUp = (event: KeyboardEvent) => {
        props?.onKeyUp?.(event)
        if (event.key === 'Enter') {
            onChange(event.target.value)
        }
    }

    const getConstrainedNumber = (value?: string) => {
        const currencyValue = stringToCurrencyNumber(value)
        if (currencyValue) {
            const constrainedMax = !isUndefined(props.max) ? Math.min(currencyValue, props.max) : currencyValue
            const constrainedMaxAndMin = Math.max(constrainedMax, 0)
            return constrainedMaxAndMin
        }
    }

    return (
        <Flex row alignItemsCenter>
            <label>£&nbsp;</label>
            <TextBox type="number"
                   disabled={props.disabled}
                   min={0}
                   max={props.max}
                   placeholder={props.placeholder}
                   value={String(props.value)}
                   onKeyUp={onKeyUp}
                   selectAllOnFocus
                   onKeyDown={props.onKeyDown}
                   realOnChange={onChange}
                   onBlur={props.onBlur}
                   class={props.class}
                   ref={props.ref}
                   readonly={props.readonly}
                   />
        </Flex>
    )
}
