// noinspection JSNonASCIINames,NonAsciiCharacters
// noinspection NonAsciiCharacters

import {Observable, Observer, Subscription} from 'rxjs'


export type PNodeMutability = 'IMMUTABLE' | 'SHALLOW' | 'MUTABLE'
export type PNodeProp = (string | number)
export type PNodePath = PNodeProp[]


export type PNode<T, M extends PNodeMutability = 'MUTABLE'> =
    PNodeIdentifiable &
    PNodeGettable<T> &
    PNodeSubscribable<T, M> &

    (T extends any[]
        ? PNodeIndexable<T, M>
        : T extends object
            ? PNodeKeyable<T, M>
            : {}
        ) &

    (M extends 'MUTABLE'
        ? (
            PNodeSettable<T> &
            PNodeTransactable<T>
            )
        : {}
        )

type PNodeKeyable<T extends object, M extends PNodeMutability> =
    T extends any[] ? {} : {
        [k in keyof T]: PNode<T[k], M extends 'SHALLOW' ? 'MUTABLE' : M>
    }

type PNodeIndexable<T extends any[], M extends PNodeMutability> =
    PNodeQueryable<T, M> &
    {
        [i in number]: PNode<T[i], M extends 'SHALLOW' ? 'MUTABLE' : M>
    }


type PNodeGettable<T> = {
    (): Promise<T>
}

type PNodeSettable<T> = {
    (newValue: T): Promise<boolean>
}

type PNodeSubscribable<T, M extends PNodeMutability> = {
    $: PNodeSubscribeFunction<T, M>
    $$: PNodePath
}

type PNodeIdentifiable = {
    𝐈: unknown
}

type PNodeSubscribeFunction<T, M extends PNodeMutability> = {
    (): Observable<PNode<T, M>>
} & ObserverSubscribeFunction<PNode<T, M>>


type PNodeTransactable<T> = {
    Δ: PNodeTransactFunction<T>
}

type PNodeTransactFunction<T> = {
    (Δt: PNodeTransaction<T>): Promise<void>
}

export type PNodeTransaction<T> = (node: PNode<T>) => Promise<void>



export type PNodeQueryable<T, M extends PNodeMutability> =
    T extends (infer E)[]
        ? {
            λ: {
                filter: (p: Predicate<E extends object ? PNode<E, 'IMMUTABLE'> : E>) => PNode<T, M extends 'MUTABLE' ? 'SHALLOW' : M>
                find: (p: Predicate<E extends object ? PNode<E, 'IMMUTABLE'> : E>) => PNode<E, M extends 'SHALLOW' ? 'MUTABLE' : M>
                any: (p: Predicate<E extends object ? PNode<E, 'IMMUTABLE'> : E>) => PNode<boolean, 'IMMUTABLE'>
                every: (p: Predicate<E extends object ? PNode<E, 'IMMUTABLE'> : E>) => PNode<boolean, 'IMMUTABLE'>
            }
        }
        : void


type ObserverSubscribeFunction<T> = {
    (observer?: Partial<Observer<T>>): Subscription
    (onNext: (value: T) => void): Subscription
}



export type Predicate<T> = (x: T) => Promise<boolean>

export type PNodeQuery<T> = {
    run(t: T): Promise<T>
    toString(): string
    readonly queryHash: string
}


// -------------------------------------

