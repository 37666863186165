import {PeachyFlashRepo} from '../types'
import {RepoPreferences} from '@peachy/repo-domain'

export class PreferencesRepository {

    constructor(protected readonly repo: PeachyFlashRepo) { }

    async getPreferences() {
        const root = await this.repo.getContentRoot()
        return root.settings.preferences()
    }

    async updatePreferences(preferences: RepoPreferences) {
        const root = await this.repo.getContentRoot()
        await root.settings.preferences(preferences)
    }
}
