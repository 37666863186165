import {enumerate, isArray, isBoolean, isDateObject, isNullish, isNumber, isString} from '@peachy/utility-kit-pure'
import hash from 'object-hash'

export const HashMark = Symbol.for('FlashHashMark')


// todo - bake known hashes into a runtime check of local hashing algorithm!

export const NULL_HASH = '109085beaaa80ac89858b283a64f7c75d7e5bb12'
export const HASH_LENGTH = 40

export const EmptyObject = {} as const
export const EmptyObjectHash = rawHash(EmptyObject)

export const EmptyArray = [] as const
export const EmptyArrayHash = rawHash(EmptyArray)

export const NullHash = rawHash(null)

export type Primitive = number | boolean | string


export type Hash = string
export type BranchId = string

export class HardHash {
    constructor(private hash: Hash) {}
    toString(): string {return this.hash}
}

export type Tag = number | string

export type Value = any

export type Flashlet = {
    hash: Hash
    tag?: Tag
    type: FlashPropType
    value: Value
}

export type Flashable = object | any[] | string


export type FlashProp = string | number
export type FlashValue = Primitive | HardHash

export type FlashPath = FlashProp[]

export type FlashObject = {
    [k: string]: FlashValue
}

export type FlashArray = FlashValue[] | []
export type Flash = FlashObject | FlashArray | string | Date

export type FlashNode = FlashObject | FlashArray

export type Bookmark = number

export function rawHash(x: any): Hash {
    return hash(x, {respectType: false})
}

export const FlashPropTypeList = [
    'number',
    'boolean',
    'string',
    'array',
    'object',
    'hash',
    'null',
    'Date'
] as const




export const FlashPropTypes = enumerate(FlashPropTypeList)

export type FlashPropType = keyof typeof FlashPropTypes


export function isPrimitive(x: any): x is Primitive {
    return (
        (typeof x === 'string' && x.length < HASH_LENGTH) ||
        typeof x === 'number' ||
        typeof x === 'boolean'
    )
}


const hashMatch = /^[0-9a-fA-F]{40}$/


export function isSoftHash(s: any): s is Hash {
    return isString(s) && hashMatch.test(s)
}


export function isHardHash(x: any): x is HardHash {
    return x instanceof HardHash
}

export function isEmptyHash(hash: Hash) {
    return hash === EmptyObjectHash || hash === EmptyArrayHash
}


export function isFlashable(x: any): x is Flashable {
    return !isNullish(x) && !isPrimitive(x) && !(x instanceof HardHash)
}


export function getPropType(value: unknown): FlashPropType {
    if (isNullish(value)) return 'null'
    if (isString(value)) return 'string'
    if (isBoolean(value)) return 'boolean'
    if (isNumber(value)) return 'number'
    if (isDateObject(value)) return 'Date'
    if (isArray(value)) return 'array'
    if (isHardHash(value)) return 'hash'
    return 'object'
}