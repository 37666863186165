import {DomainMappings, Life, RegisteredGp, RepoBankDetails, RepoLife} from '@peachy/repo-domain'
import {ManagedLifeRepository} from '@peachy/flash-repo-peachy-client'
import {RepoEntityPatch} from '@peachy/flash-repo-pure'
import {sortBy} from 'lodash-es'

export type OnboardingData = Partial<Pick<RepoLife, 'registeredGp' | 'gender' | 'phoneNumber'>>

export class LifeService {

    constructor(protected readonly managedLifeRepository: ManagedLifeRepository) {
    }

    async getPrimaryLife() {
        const repoLife = await this.getRepoPrimaryLife()
        return DomainMappings.fromRepo.toLife(repoLife)
    }

    async getRepoPrimaryLife() {
        return this.managedLifeRepository.getPrimaryLife()
    }

    async getDependents() {
        const repoLives = await this.managedLifeRepository.getDependents()
        return repoLives.map(it => DomainMappings.fromRepo.toLife(it))
    }

    async getLife(lifeId: string) {
        //should think about if this needs to handle non managed lives (spouses) too?
        const repoLife = await this.managedLifeRepository.get(lifeId)
        return DomainMappings.fromRepo.toLife(repoLife)
    }

    async getLifeBySub(sub: string) {
        const repoLife = await this.managedLifeRepository.find({awsSub: sub})
        return DomainMappings.fromRepo.toLife(repoLife)
    }

    async getAllLives() {
        //should think about if this needs to handle non managed lives (spouses) too?
        const repoLives = await this.managedLifeRepository.list()
        return sortBy(repoLives.map(it => DomainMappings.fromRepo.toLife(it)), it => it.isPrimary ? 0 : 1)
    }

    async updateVgpUserIdOf(life: Life, vgpUserId: string) {
        const updatedRepoLife = await this.managedLifeRepository.patch({id: life.id, vgpUserId})
        return DomainMappings.fromRepo.toLife(updatedRepoLife)
    }

    async markVgpTermsAsAccepted(life: Life, termsAcceptedIds: string[]) {
        const updatedRepoLife = await this.managedLifeRepository.patch({id: life.id, vgpAcceptedTerms: termsAcceptedIds})
        return DomainMappings.fromRepo.toLife(updatedRepoLife)
    }

    async updateRegisteredGpOf(life: Life, registeredGp: RegisteredGp) {
        const updatedRepoLife = await this.managedLifeRepository.patch({id: life.id, registeredGp})
        return DomainMappings.fromRepo.toLife(updatedRepoLife)
    }

    async updateOnboardedFor(lifeId: string, onboardedValue: boolean, onboardingData: OnboardingData = {}) {
        console.log('update OB', lifeId, onboardedValue, onboardingData)
        const {registeredGp, gender, phoneNumber} = onboardingData ?? {}
        const patch: RepoEntityPatch<RepoLife> = {
            id: lifeId,
            onboarded: onboardedValue
        }
        registeredGp && (patch.registeredGp = registeredGp)
        gender && (patch.gender = gender)
        phoneNumber && (patch.phoneNumber = phoneNumber)

        const updatedRepoLife = await this.managedLifeRepository.patch(patch)
        return DomainMappings.fromRepo.toLife(updatedRepoLife)
    }

    async deleteBankDetailsOfAppOwner() {
        const appOwner = await this.managedLifeRepository.getPrimaryLife()
        await this.managedLifeRepository.patch({id: appOwner.id, bankAccount: undefined})
    }

    async updateBankDetailsOfAppOwner(bankAccount: RepoBankDetails) {
        const appOwner = await this.managedLifeRepository.getPrimaryLife()
        await this.managedLifeRepository.patch({id: appOwner.id, bankAccount: {sortCode: bankAccount.sortCode, accountNumber: bankAccount.accountNumber}})
    }

}