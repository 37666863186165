import {
    Enquiry,
    Question,
    QuestionAnswer,
    QuestionModal,
    QuestionOption,
    QuestionOptionsDefinition,
    QuestionPresentations,
    QuestionTypes,
    QuestionValidation
} from '@peachy/repo-domain'
import {QuestionId, QuestionInitialiser, QuestionRequiredPredicate} from '../types'

type BasicQuestionProps = { id: string, text: string, helpText?: string, helpHeading?: string, tags?: string[], modals?: QuestionModal[], textVariablesLookup?: string }
type EmbeddedOptions = { optionsId?: never, embeddedOptions: QuestionOption[] }
type LookupOptions = { optionsId: string, embeddedOptions?: never }
type EmbeddedOrLookupOptions = EmbeddedOptions | LookupOptions

export function singleAnswerNumberQuestion(basicProps: BasicQuestionProps) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.NUMERIC,
        presentation: QuestionPresentations.TEXT,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

export function singleAnswerFreetextQuestion(basicProps: BasicQuestionProps) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.FREETEXT,
        presentation: QuestionPresentations.TEXT,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

export function singleAnswerOptionLookupQuestion({optionsId, ...basicProps}: BasicQuestionProps & { optionsId: string }) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.OPTION,
        optionsDefinition: new QuestionOptionsDefinition({optionsId, acceptNonOptionAnswers: false}),
        presentation: QuestionPresentations.TEXT,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

export function fullDatePickerQuestion({
                                           minAccepted,
                                           maxAccepted,
                                           ...basicProps
                                       }: BasicQuestionProps & { minAccepted: Date, maxAccepted: Date }) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.DATE_DAY_MONTH_YEAR,
        presentation: QuestionPresentations.PICKER,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
            minAccepted,
            maxAccepted
        }),
    })
}

export function singleAnswerAppointmentPickerQuestion({
                                                          embeddedOptions,
                                                          optionsId,
                                                          ...basicProps
                                                      }: BasicQuestionProps & EmbeddedOrLookupOptions) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.OPTION,
        presentation: QuestionPresentations.APPOINTMENT_PICKER,
        optionsDefinition: new QuestionOptionsDefinition({embeddedOptions, optionsId}),
        validation: new QuestionValidation({
            acceptMultipleAnswers: false
        }),
    })
}

export function singleAnswerCheckboxQuestion({
                                                 embeddedOptions,
                                                 optionsId,
                                                 ...basicProps
                                             }: BasicQuestionProps & EmbeddedOrLookupOptions) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.OPTION,
        presentation: QuestionPresentations.CHECKBOX,
        optionsDefinition: new QuestionOptionsDefinition({embeddedOptions, optionsId}),
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

export function buttonQuestion({embeddedOptions, optionsId, ...basicProps}: BasicQuestionProps & EmbeddedOrLookupOptions) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.OPTION,
        presentation: QuestionPresentations.BUTTON,
        optionsDefinition: new QuestionOptionsDefinition({embeddedOptions, optionsId}),
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        })
    })
}

export function singleAnswerBankDetailsQuestion(basicProps: BasicQuestionProps) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.BANK_DETAILS,
        presentation: QuestionPresentations.TEXT,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

export function recordVideoQuestion(basicProps: BasicQuestionProps) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.VIDEO,
        presentation: QuestionPresentations.CAMERA,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

export function captureImagesQuestion(basicProps: BasicQuestionProps) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.IMAGE,
        presentation: QuestionPresentations.CAMERA,
        validation: new QuestionValidation({
            acceptMultipleAnswers: true,
        }),
    })
}

export function signatureQuestion(basicProps: BasicQuestionProps) {
    return new Question({
        ...basicProps,
        type: QuestionTypes.SIGNATURE,
        presentation: QuestionPresentations.DRAW,
        validation: new QuestionValidation({
            acceptMultipleAnswers: false,
        }),
    })
}

type QuestionInitialiserOrId = QuestionInitialiser | QuestionId
type WhenPredicates = {
    answersOf?: [QuestionInitialiserOrId, QuestionAnswer][],
    anyAnswersOf?: [QuestionInitialiserOrId, QuestionAnswer][],
    noAnswersOf?: [QuestionInitialiserOrId, QuestionAnswer][],
    rawPredicate?: QuestionRequiredPredicate
}

export function when({answersOf, noAnswersOf, rawPredicate, anyAnswersOf}: WhenPredicates) {

    return async (enquiry: Enquiry, question: Question) => {
        let requiredAnswersAsExpected = true
        let requiredAnyAnswersAsExpected = true
        let notRequiredAnswersAsExpected = true
        let rawPredicateAsExpected = true

        if (answersOf) {
            requiredAnswersAsExpected = answersOf.every(
                ([questionDefinition, expectedAnswer]) => {
                    const question = enquiry.getQuestion(idFrom(questionDefinition))!
                    return question?.required && question.hasAnswer(expectedAnswer)
                }
            )
        }

        if (anyAnswersOf) {
            requiredAnyAnswersAsExpected = anyAnswersOf.some(
                ([questionDefinition, expectedAnswer]) => {
                    const question = enquiry.getQuestion(idFrom(questionDefinition))!
                    return question?.required && question.hasAnswer(expectedAnswer)
                }
            )
        }

        if (noAnswersOf) {
            notRequiredAnswersAsExpected = noAnswersOf.every(
                ([questionDefinition, notExpectedAnswer]) => {
                    const question = enquiry.getQuestionEvenIfNotRequired(idFrom(questionDefinition))!
                    return !question.required || !question.hasAnswer(notExpectedAnswer)
                }
            )
        }

        if (rawPredicate) {
            rawPredicateAsExpected = await rawPredicate(enquiry, question)
        }

        return requiredAnswersAsExpected && notRequiredAnswersAsExpected && rawPredicateAsExpected && requiredAnyAnswersAsExpected
    }
}

const idFrom = (initialiserOrId: QuestionInitialiserOrId) => {
    return ((initialiserOrId as QuestionInitialiser).id ?? initialiserOrId) as QuestionId
}

export const requiredWhenMoreThanOneOption = {
    required: when({
        rawPredicate: async (enquiry, question) => question.numberOfEmbeddedOptions > 1
    })
}
