import {MultiMap} from 'mnemonist'
import {Alteration} from './alteration-types'


export function mapAlterationsById(alterations: Alteration[]): AlterationMap {
    const map = new MultiMap<string, Alteration>
    for (const alteration of alterations) {
        map.set(alteration.id, alteration)
    }
    return map
}

export function mapNestedAlterationsById(alterations: Alteration[]): AlterationMap {
    const map = new MultiMap<string, Alteration>
    for (const alteration of alterations) {
        map.set(alteration.id, alteration)
        for (const ancestor of alteration.path) {
            map.set(ancestor, alteration)
        }
    }
    return map
}


export type AlterationMap = MultiMap<string, Alteration>
