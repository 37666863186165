import {Observable} from 'rxjs'
import {RepoHash} from '../primatives/hash-primatives'
import {BranchInfo, BranchName, InstallationId, MergeState} from '../primatives/repo-primatives'
import {Bookmark, BookmarkOrTimestamp} from '@peachy/utility-kit-pure'


export interface IBranchIo {
    fetchBranches(): Promise<BranchInfo[]>
    fetchBranch(branch: BranchName): Promise<BranchInfo>

    createBranch(branch: BranchName, commitMessage: string, fromBranch?: BranchName): Promise<BranchInfo>
    deleteBranch(branch: BranchName): Promise<BranchInfo>
    branchExists(branch: BranchName): Promise<boolean>

    fetchBranchHeadAtTimestamp(branch: BranchName, timestamp: number): Promise<BranchInfo>

    fetchBranchUpdatesSince(branch: BranchName, bookmark?: Bookmark): Observable<BranchInfo>

    fetchBranchUpdateSlice(
        branch: BranchName,
        since?: BookmarkOrTimestamp,
        until?: BookmarkOrTimestamp
    ): Observable<BranchInfo>

    commitBranch(
        branch: BranchName,
        branchHeadKey: RepoHash,
        commitMessage: string,
        expectedHeadKey?: RepoHash
    ): Promise<BranchInfo>

    fetchMergeState(remoteInstallationId: InstallationId, branchName: BranchName): Promise<MergeState>
    fetchMergeStatesSince(remoteInstallationId: InstallationId, branchName: BranchName, sinceBookmark: Bookmark): Observable<MergeState>
    commitMergeState(remoteInstallationId: InstallationId, branchName: BranchName, mergeState: MergeState, expectedMergeState?: MergeState): Promise<MergeState>
}
