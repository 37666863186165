import {REPO_HASH_NULL, RepoHash} from './hash-primatives'
import {MemoRepo} from '../implementations/repo/MemoRepo'
import {PageIoMemory} from '../implementations/page/PageIoMemory'
import {BranchIoMemory} from '../implementations/branch/BranchIoMemory'
import {PageStore} from '../implementations/page/PageStore'
import {Bookmark} from '@peachy/utility-kit-pure'

export type BranchName = string

export type RepoId = string // security boundary, e.g. cognitoId
export type InstallationId = string // id of an actual database, e.g. 'server-golden-source' 'ios-client-XXX'

export type MergeBias = 'LEFT' | 'RIGHT'

export type BranchInfo = {
    bookmark: Bookmark
    name: BranchName
    branchHead: RepoHash
    commitMessage: string
}

export type MergeStatus =
    | 'NONE'
    | 'MERGED'
    | 'PULLED'
    | 'PUSHED'

export type MergeState = {
    localHead: RepoHash
    commonHead: RepoHash
    remoteHead: RepoHash
    mergeStatus: MergeStatus
}


export function createUnmergedState(): MergeState {
    return {
        localHead: REPO_HASH_NULL,
        commonHead: REPO_HASH_NULL,
        remoteHead: REPO_HASH_NULL,
        mergeStatus: 'NONE'
    }
}


export function createRepoMemory<T extends object>(
    repoId: RepoId,
    installationId: InstallationId,
    pageCacheSize = 10000,

): MemoRepo<T> {
    const pageIo = new PageIoMemory(installationId)
    const branchIo = new BranchIoMemory()

    const pageStore = new PageStore(pageIo, pageCacheSize)
    return new MemoRepo<T>({
        repoId,
        installationId,
        pageIo,
        branchIo,
        pageStore
    })
}
