import {createEffect, createSignal} from 'solid-js'
import {createUserAdminMachine, UserAdminStates} from './UserAdminMachine'

import {EventTrigger} from '../../to-abstract/solid-state'
import {NewPasswordRequest, UserAdminApiGateway} from '@peachy/web-dashboard-pure'


export type UserAdminClient = UserAdminApiGateway & {
    isInState(...s: UserAdminStates[]) : boolean
    isBusy(): boolean
    error(): string
}


export function createUserAdminService(userAdminApi: UserAdminApiGateway): UserAdminClient {

    const userAdminMachine = createUserAdminMachine()

    const [error, setError] = createSignal('')

    createEffect(() => {
        userAdminMachine.currentState()
        setError('')
    })


    function triggerEvent<R>(trigger: EventTrigger, action: () => Promise<R>) {
        return new Promise<R>((resolve, reject) => {
            const didFire = trigger(async () => {
                try {
                    const result = await action()
                    userAdminMachine.success()
                    resolve(result)

                } catch (e) {
                    userAdminMachine.failure()
                    console.error('Action error', e)
                    setError(e.message)
                    reject(e)
                }
            })
            if (!didFire) reject('Invalid state transition')
        })
    }

    return {

        async resetDashUserPassword({email, password}: NewPasswordRequest) {
            return triggerEvent(userAdminMachine.requestNewDashUserPassword, () => userAdminApi.resetDashUserPassword({
                email,
                password
            }))
        },

        async resetMemberPassword({email, password}: NewPasswordRequest) {
            return triggerEvent(userAdminMachine.requestNewCustomerPassword, () => userAdminApi.resetMemberPassword({
                email,
                password
            }))
        },

        isInState(...s): boolean {
            return userAdminMachine.isInState(...s)
        },

        isBusy(): boolean {
            return userAdminMachine.hasTag('busy')
        },
        error(): string {
            return error()
        }
    }
}
