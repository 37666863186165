import {createSignal} from 'solid-js'
import {Tabs} from '../../../../components/Tabs/Tabs'
import tabStyles from '../../../../components/Tabs/PeachyTabs.module.css'
import {RepoView} from './RepoView/RepoView'
import {RepoController} from '../RepoController'


type FlashRepoTabs =
    | 'Customer Repo'
    // | 'Two'

export function FlashRepoPanel() {

    const currentTabSignal = createSignal<FlashRepoTabs>('Customer Repo')
    return (
        <div class={tabStyles.Tabs}>
            <RepoController>
                <Tabs currentTab={currentTabSignal}>{() => {
                    return {
                        'Customer Repo': RepoView,
                    }
                }}</Tabs>
            </RepoController>
        </div>
    )
}
