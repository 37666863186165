import {defineActionApi, returnsObservable, returnsPromise} from '@peachy/sockets-pure'
import {Observable} from 'rxjs'
import {Bookmark, Flashlet} from '@peachy/flash-repo-pure'

export const SyncClientApiDef = defineActionApi('SyncClientApi', {
    jeff: () => returnsPromise()
})

export const SyncServerApiDef = defineActionApi('SyncServerApi', {
    sendFlashlets: (f$: Observable<Flashlet>) => returnsPromise<Bookmark>(),
    fetchFlashletsSince: (bookmark: Bookmark) => returnsObservable<Flashlet>()
})
